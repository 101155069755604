/*alerts*/

.popsecondary {
  color: #628dea;
}

.popinfo {
  color: #88c8f7;
}

.popsuccess {
  color: #bfff80;
}

.popdanger {
  color: #ea6262;
}

.popwarning {
  color: #f7db6e;
}

/*-----Alerts-----*/

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;

  &:last-child,
  p {
    margin-bottom: 0;
  }
}

.alert-link {
  font-weight: 500;

  &:hover,
  a:hover {
    text-decoration: underline;
  }
}

.alert-dismissible {
  padding-right: 3.90625rem;
}

hr.message-inner-separator {
  clear: both;
  margin-top: 10px;
  margin-bottom: 13px;
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0));
  background-color: inherit;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-default {
  color: $color;
  background-color: rgba(36, 46, 76, 0.2);
  border-color: rgba(36, 46, 76, 0.2);

  hr {
    border-top-color: $color;
  }

  .alert-link {
    color: $color;
  }
}

.alert-secondary {
  color: $secondary;
  background-color: rgba(156, 82, 253, 0.3);
  border-color: rgba(156, 82, 253, 0.3);

  hr {
    border-top-color: $secondary;
  }

  .alert-link {
    color: $secondary;
  }
}

.alert-success {
  color: $success;
  background-color: rgba(86, 208, 148, 0.3);
  border-color: rgba(86, 208, 148, 0.3);

  hr {
    border-top-color: #c5e7a4;
  }

  .alert-link {
    color: #172e00;
  }
}

.alert-info {
  color: $info;
  background-color: rgba(47, 137, 245, 0.3);
  border-color: rgba(47, 137, 245, 0.3);

  hr {
    border-top-color: #b3dcf9;
  }

  .alert-link {
    color: #0f233a;
  }
}

.alert-warning {
  color: #ffa70b;
  background-color: rgba(255, 167, 11, 0.3);
  border-color: rgba(255, 167, 11, 0.3);

  hr {
    border-top-color: #fae8a4;
  }

  .alert-link {
    color: #4d3f05;
  }
}

.alert-danger {
  color: $danger;
  background-color: rgba(251, 84, 84, 0.3);
  border-color: rgba(251, 84, 84, 0.3);

  hr {
    border-top-color: #ecacab;
  }

  .alert-link {
    color: #3f0a09;
  }
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.alert {
  font-size: 0.9375rem;
}

.alert-avatar {
  padding-left: 3.75rem;

  .avatar {
    position: absolute;
    top: 0.5rem;
    left: 0.75rem;
  }
}

.alert .btn-close {
  right: 31px !important;
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  background-image: none;
}

.close {
  font-size: 1.3rem;
  transition: 0.3s color;
}

.alert .btn-close {
  width: 0em;
  height: 0em;
  margin-top: -7px;
  position: absolute;
  top: 19px;
}
.alert .btn-close {
  float: right;
  font-size: 1.40625rem;
  font-weight: 200;
  line-height: 1.3;
  color: inherit;
  text-shadow: none;
  opacity: 1;
  transition: 0.3s color;
}
.alert.alert-success hr {
  border-top-color: $success;
}
hr {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.alert.alert-info hr {
  border-top-color: $info;
}
.alert.alert-warning hr {
  border-top-color: $warning;
}
.alert.alert-danger hr {
  border-top-color: $danger;
}
