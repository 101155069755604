/*------ Headers -------*/

.page-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin: 1.5rem 0rem;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  position: relative;
  min-height: 50px;
}

.page-title {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  margin-bottom: 0.3rem;
}

.page-subtitle {
  font-size: 0.8125rem;
  color: #c0a0f1;
  margin-left: 2rem;
  position: relative;

  a {
    color: inherit;
  }
}

.page-options {
  margin-left: auto;
}

.page-header .breadcrumb {
  font-size: 13px;
  padding-left: 0 !important;
}

.aside {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 22rem;
  background: $white;
  border-left: 1px solid $border;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 100;
  visibility: hidden;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
}

@media (min-width: 1600px) {
  body.aside-opened .aside {
    visibility: visible;
  }
}

.aside-footer {
  padding: 1rem 1.5rem;
  border-top: 1px solid $border;
}

.aside-header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid $border;
}

.header {
  background: $white;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid rgba(227, 231, 241, 0.8);
}

body.fixed-header .header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
}

@media print {
  .header {
    display: none;
  }
}

.header {
  .dropdown-menu {
    margin-top: 0;
  }

  .mega-menu {
    width: 350px;
  }

  .nav-tabs {
    margin: 0;
  }
}

.nav-unread {
  &.badge {
    position: absolute;
    top: 2px;
    right: 3px;
    display: block !important;
    padding: 3px 5px !important;
    font-size: 10px !important;
    border-radius: 50px !important;
    line-height: 1.1;
    box-shadow: 0 0 0 rgb(255, 71, 61, 0.9);
    animation: pulse 2s infinite;
  }
}

.nav-link.icon {
  margin: 5px;
  padding: 5px;
  text-align: center;
  height: 2.5rem;
  font-size: 22px;
  position: relative;

  i {
    color: #7e89a9 !important;
  }

  svg {
    fill: #7e89a9;
  }
}

.header-right-icons {
  .dropdown-menu a {
    padding: 1rem;
  }
}

.header-brand {
  color: inherit;
  font-size: 1.25rem;
  white-space: nowrap;
  font-weight: 600;
  padding: 0;
  transition: 0.3s opacity;
  line-height: 2.6rem;
}

.app-header .header-brand {
  color: inherit;
  margin-right: 0;
  font-size: 1.25rem;
  white-space: nowrap;
  font-weight: 600;
  padding: 0;
  transition: 0.3s opacity;
  line-height: 2.9rem;
  min-width: 200px;
  text-align: center;

  .header-brand-img {
    margin-right: 0;
  }
}

.header-brand:hover {
  color: inherit;
  text-decoration: none;
}

.header-brand-img {
  height: 2.26rem;
  line-height: 2rem;
  vertical-align: middle;
  margin-right: 0;
  width: auto;
  margin: 0 auto;
}

@media (max-width: 991px) {
  .header .form-inline {
    margin-right: -2px;
  }
  .header {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
}

.header-1 {
  .navbar .nav-link.nav-link-lg i {
    font-size: 1.1rem;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    color: $color;
    line-height: 32px;
    margin: 0 auto;
    text-align: center;
  }

  .nav-link .badge {
    position: absolute;
    top: 2px;
    right: 6px;
    left: 18px;
    width: 1rem;
    height: 1rem;
    font-size: 11px;
    text-align: center !important;
    padding: 0.25em 0.4em;
    line-height: 1;
  }

  .badge {
    border-radius: 50%;
  }

  .form-inline .btn {
    border-radius: 0;
    padding: 5px 15px 6px 15px;
    border: 2px solid transparent;
    box-shadow: none;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    background: transparent;
    color: $color;
    margin-left: -50px;
    font-size: 15px;
    position: absolute;
    margin-top: 0px;
  }

  .form-control.header-search {
    color: $color;
  }

  .form-inline .form-control::-webkit-input-placeholder {
    color: #8499c4;
  }

  .animated-arrow span {
    display: none;
  }
}

.animated-arrow span {
  &:before,
  &:after {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .header-1 .animated-arrow span {
    cursor: pointer;
    height: 2px;
    width: 17px;
    background: $color;
    position: absolute;
    display: block;
    content: '';
  }

  .animated-arrow span {
    &:before,
    &:after {
      cursor: pointer;
      height: 2px;
      width: 17px;
      background: $color;
      position: absolute;
      display: block;
      content: '';
    }
  }

  .header-1 .animated-arrow span {
    cursor: pointer;
    height: 2px;
    width: 17px;
    background: $color;
    position: absolute;
    display: block;
    content: '';
  }

  .animated-arrow span {
    &:before,
    &:after {
      cursor: pointer;
      height: 2px;
      width: 17px;
      background: $color;
      position: absolute;
      display: block;
      content: '';
    }
  }

  .header-1 {
    .animated-arrow {
      cursor: pointer;
      margin: 10px 2px 0 0px;
      position: initial;
      padding: 10px;
    }
  }

  .header-2 .animated-arrow {
    cursor: pointer;
    margin: 0px 2px 0 0px;
    position: initial;
    padding: 10px;
  }

  .header-1 .form-inline .form-control,
  .header-2 .form-inline .form-control {
    width: 100px !important;
  }

  .header-1 .header-search,
  .header-2 .header-search {
    min-width: auto !important;
  }

  .header-1 {
    .navbar .nav-link.nav-link-lg i {
      font-size: 1.1rem;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 50%;
      color: $color;
      line-height: 39px;
      margin: 0 auto;
      text-align: center;
      padding: 0px 42px 0px 10px;
    }

    .dropdown-menu {
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.05);
    }

    .nav-link .badge {
      position: absolute;
      top: 4px;
      width: 1rem;
      height: 1rem;
      font-size: 11px;
      text-align: center !important;
      padding: 0.25em 0.4em;
      line-height: 1;
    }
  }
}

.navbar-nav {
  flex-direction: initial !important;
}

.header-1 {
  .nav-link.icon i {
    color: $color !important;
  }

  .dropdown-menu {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.05);
  }
}

.header-2 {
  .dropdown-menu {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.05);
  }

  .nav-link {
    .badge {
      position: absolute;
      top: 5px;
      right: 9px;
      width: 1rem;
      height: 1rem;
      font-size: 11px;
      text-align: center !important;
      padding: 0.25em 0.4em;
      line-height: 1;
      border-radius: 50%;
    }

    i {
      display: block;
      text-align: center;
      font-size: 20px;
      color: $color;
      margin-top: 5px;
    }
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.9rem;
    padding-left: 0.9rem;
  }

  .form-inline {
    .btn {
      border-radius: 0;
      padding: 5px 15px 6px 15px;
      border: 2px solid transparent;
      box-shadow: none;
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;
      background: transparent;
      color: $color;
      margin-left: -45px;
      font-size: 15px;
      position: absolute;
      margin-top: 4px;
    }

    .form-control::-webkit-input-placeholder {
      color: #8499c4;
    }
  }

  .form-control.header-search {
    background: rgba(225, 225, 225, 0.4);
    border: 1px solid rgba(225, 225, 225, 0.1);
    color: $white;
    padding: 0.375rem 0.75rem;
  }
}

@media only screen and (max-width: 991px) {
  .header-1 .animated-arrow span {
    cursor: pointer;
    height: 2px;
    width: 17px;
    background: $color;
    color: $color;
    position: absolute;
    display: block;
    content: '';

    &:before,
    &:after {
      cursor: pointer;
      height: 2px;
      width: 17px;
      background: $color;
      color: $color;
      position: absolute;
      display: block;
      content: '';
    }
  }
}

/*--header-2--*/

@media only screen and (max-width: 1279px) and (min-width: 1025px) {
  .horizontal-main2 .horizontalMenu > .horizontalMenu-list > li > a {
    display: block;
    padding: 1rem 0.7rem 1rem;
    text-decoration: none;
    position: relative;
    margin: 0px;
    color: $color;
  }
}

@media (max-width: 1024px) and (min-width: 992px) {
  .horizontal-main2 .horizontalMenu > .horizontalMenu-list > li > a {
    padding: 1rem 0.8rem 1rem !important;
    font-size: 14px;
  }
}

@media only screen and (max-width: 991px) {
  .header-2 {
    .animated-arrow {
      cursor: pointer;
      margin: 0 2px 0 0px;
      position: initial;
      padding: 10px;

      span {
        cursor: pointer;
        height: 2px;
        width: 17px;
        background: $color;
        color: $color;
        position: absolute;
        display: block;
        content: '';

        &:before,
        &:after {
          cursor: pointer;
          height: 2px;
          width: 17px;
          background: $color;
          color: $color;
          position: absolute;
          display: block;
          content: '';
        }
      }
    }

    .nav-link .badge {
      position: absolute;
      top: -1px;
      right: 6px;
      width: 1rem;
      height: 1rem;
      font-size: 11px;
      text-align: center !important;
      padding: 0.25em 0.4em;
      line-height: 1;
      border-radius: 50%;
    }

    .navbar-nav .dropdown-menu {
      position: absolute;
      float: right;
    }

    .dropdown-menu.dropdown-menu-end {
      position: absolute;
      transform: translate3d(-98px, 19px, -16px);
      top: 24px;
      left: 0px;
      will-change: transform;
    }
  }
}

.horizontal-main2 {
  .horizontalMenu > .horizontalMenu-list > li > a.active {
    color: $color;
  }

  &.horizontal-main {
    width: 100%;
    position: relative;
    border-top: 0;
  }
}

@media only screen and (max-width: 460px) {
  .header-2 .navbar-nav .envelope {
    display: none !important;
  }
}

/*--header-3--*/

.header-3 {
  .nav-link {
    .badge {
      position: absolute;
      top: 3px;
      left: 18px;
      right: 10px;
      width: 1rem;
      height: 1rem;
      font-size: 11px;
      text-align: center !important;
      padding: 0.25em 0.4em;
      line-height: 1;
      border-radius: 50%;
    }

    i {
      display: block;
      text-align: center;
      font-size: 20px;
      position: relative;
      color: $color;
    }
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.9rem;
    padding-left: 0.9rem;
  }

  .form-inline {
    .btn {
      border-radius: 0;
      padding: 5px 15px 6px 15px;
      border: 2px solid transparent;
      box-shadow: none;
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;
      background: transparent;
      color: $color;
      margin-left: -50px;
      font-size: 15px;
      margin-top: 3px;
    }

    .form-control::-webkit-input-placeholder {
      color: #8499c4;
    }
  }

  .form-control.header-search {
    background: rgba(225, 225, 225, 0.4);
    border: 1px solid rgba(225, 225, 225, 0.1);
    color: $white;
    padding: 0.375rem 0.75rem;
    margin-top: 4px;
  }
}

@media only screen and (max-width: 1279px) and (min-width: 992px) {
  #orders {
    position: relative;
    bottom: -6px;
    height: 102px !important;
    width: 100% !important;
    left: -6px;
  }
}

@media only screen and (max-width: 991px) {
  .header-3 {
    .animated-arrow {
      cursor: pointer;
      margin: 10px 2px 0 0px;
      position: initial;
      padding: 10px;

      span {
        cursor: pointer;
        height: 2px;
        width: 17px;
        background: $color;
        color: $color;
        position: absolute;
        display: block;
        content: '';

        &:before,
        &:after {
          cursor: pointer;
          height: 2px;
          width: 17px;
          background: $color;
          color: $color;
          position: absolute;
          display: block;
          content: '';
        }
      }
    }

    .nav-link .badge {
      position: absolute;
      top: 3px;
      left: 22px;
      right: 6px;
      width: 1rem;
      height: 1rem;
      font-size: 11px;
      text-align: center !important;
      padding: 0.25em 0.4em;
      line-height: 1;
      border-radius: 50%;
    }

    .navbar-nav .dropdown-menu {
      position: absolute;
      float: right;
    }

    .dropdown-menu.dropdown-menu-end {
      position: absolute;
      transform: translate3d(-98px, 19px, -16px);
      top: 24px;
      left: 0px;
      will-change: transform;
    }
  }
}

@media only screen and (max-width: 460px) {
  .header-3 .navbar-nav .envelope {
    display: none !important;
  }
}

.header-style .header-brand {
  margin-left: 0 !important;
}

@media screen and (max-width: 998px) and (min-width: 768px) {
  .note-popover .popover-content,
  .card-header.note-toolbar {
    padding: 14px 0 5px 5px !important;
    margin: 0;
    background: #f4f6fd;
  }
}

@media (max-width: 495px) {
  .nested-media .media {
    display: block;
  }

  .Relatedpost1.nested-media .media {
    display: flex;
  }
}

.default-header .form-inline .form-control,
.header2 .form-inline .form-control,
.header3 .form-inline .form-control {
  background: #f4f6fd !important;
  box-shadow: none;
}

.header-1 .nav-link .text-dark,
.header-2 .nav-link .text-dark,
.header-3 .nav-link .text-dark {
  color: $color !important;
}

.header-search-icon .dropdown-menu {
  box-shadow: 0 10px 15px rgba(201, 206, 226, 0.4);
  border: 1px solid $border !important;
}

.header-search {
  min-width: 18rem !important;

  .search-element {
    position: relative;
    margin-left: 21px;
  }

  .btn {
    position: absolute;
    right: 0;
    top: 0px;
    color: #8499c4;
  }
}

.header-brand .header-brand-img.header-dark {
  display: none;
}

.hor-content {
  min-height: calc(100vh - 56px) !important;
}

@media (max-width: 992px) {
  .hor-content .container,
  .hor-header .container {
    max-width: none;
  }
}

@media (max-width: 991px) {
  .sidebar-mini.sidenav-toggled {
    .side-header {
      display: none;
    }
  }
  .header.hor-header {
    position: fixed;
    border-bottom: 1px solid $border;
    width: 100%;
    z-index: 999;
    top: 0;
  }
  .header.hor-header {
    .header-brand-img {
      &.light-logo {
        display: none;
      }

      &.desktop-logo {
        display: none;
        margin: 0 auto;
      }

      &.light-logo1 {
        display: block;
      }
    }

    .header-brand1 {
      width: 100%;
      margin: 0 auto;
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      margin-top: 0 !important;
    }
  }
}

.header .notifications .dropdown-menu {
  min-width: 18rem;
}

.header .dropdown.message .dropdown-menu {
  min-width: 19rem;
}

.header .message-menu-scroll {
  height: 290px;
}

.app-header,
.hor-header {
  .responsive-navbar {
    .notifications-menu,
    .message-menu {
      .dropdown-item h6,
      .dropdown-item span,
      .dropdown-item .notification-label {
        white-space: normal !important;
      }
    }
  }

  .profile-1 {
    .dropdown-item {
      white-space: normal;
    }
  }
}

.app-header .profile-1 .dropdown-item,
.hor-header .profile-1 .dropdown-item {
  white-space: normal;
}

.header {
  .header-search.dropdown-menu {
    margin-top: -1px;
  }
}
