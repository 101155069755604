.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;

  > {
    .container,
    .container-fluid {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
  }
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.359375rem;
  padding-bottom: 0.359375rem;
  margin-right: 1rem;
  font-size: 1.125rem;
  line-height: inherit;
  white-space: nowrap;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  .nav-link {
    padding-right: 35px;
    padding-left: 0;
    padding-bottom: 10px;
  }

  .dropdown-menu {
    position: static;
    float: none;
  }
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.25rem;
  font-size: 1.125rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: '';
  background: no-repeat center center;
  background-size: 100% 100%;
  fill: #677ca7;
}

.responsive-navbar.navbar .nav-link.icon svg {
  fill: #7e89a9;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > {
    .container,
    .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;

    .navbar-nav {
      -ms-flex-direction: row;
      flex-direction: row;

      .dropdown-menu {
        position: absolute;
      }

      .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }

    > {
      .container,
      .container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
      }
    }

    .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }

    .navbar-toggler {
      display: none;
    }
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > {
    .container,
    .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;

    .navbar-nav {
      -ms-flex-direction: row;
      flex-direction: row;

      .dropdown-menu {
        position: absolute;
      }

      .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }

    > {
      .container,
      .container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
      }
    }

    .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }

    .navbar-toggler {
      display: none;
    }
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > {
    .container,
    .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;

    .navbar-nav {
      -ms-flex-direction: row;
      flex-direction: row;

      .dropdown-menu {
        position: absolute;
      }

      .nav-link {
        padding-right: 1rem;
        padding-left: 1rem;
        padding-bottom: 3px;
      }
    }

    > {
      .container,
      .container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
      }
    }

    .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }

    .navbar-toggler {
      display: none;
    }
  }
}

@media (max-width: 1279.98px) {
  .navbar-expand-xl > {
    .container,
    .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@media (min-width: 1280px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;

    .navbar-nav {
      -ms-flex-direction: row;
      flex-direction: row;

      .dropdown-menu {
        position: absolute;
      }

      .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }

    > {
      .container,
      .container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
      }
    }

    .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }

    .navbar-toggler {
      display: none;
    }
  }
}

.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;

  > {
    .container,
    .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;

    .dropdown-menu {
      position: absolute;
    }

    .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }

  > {
    .container,
    .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }
  }

  .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-toggler {
    display: none;
  }
}

.navbar-light {
  .navbar-brand {
    color: $black-9;

    &:hover,
    &:focus {
      color: $black-9;
    }
  }

  .navbar-nav .nav-link {
    color: $color;

    &:hover,
    &:focus {
      color: $black-7;
    }

    &.disabled {
      color: #c2cada;
    }
  }

  .navbar-toggler {
    color: $black-5;
    border-color: $black-1;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='$black-5' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }

  .navbar-text {
    color: $black-5;

    a {
      color: $black-9;

      &:hover,
      &:focus {
        color: $black-9;
      }
    }
  }
}

.navbar-dark {
  .navbar-brand {
    color: $white;

    &:hover,
    &:focus {
      color: $white;
    }
  }

  .navbar-nav {
    .nav-link {
      color: $white-5;

      &:hover,
      &:focus {
        color: rgba(255, 255, 255, 0.75);
      }

      &.disabled {
        color: rgba(255, 255, 255, 0.25);
      }
    }

    .show > .nav-link,
    .active > .nav-link {
      color: $white;
    }

    .nav-link {
      &.show,
      &.active {
        color: $white;
      }
    }
  }

  .navbar-toggler {
    color: $white-5;
    border-color: $white-1;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='$white-5' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }

  .navbar-text {
    color: $white-5;

    a {
      color: $white;

      &:hover,
      &:focus {
        color: $white;
      }
    }
  }
}
