.custom-switch {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  padding-left: 0;
}

.custom-switch-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-switch-indicator {
  display: inline-block;
  height: 1.25rem;
  width: 2.25rem;
  background: #ebedfc;
  border-radius: 50px;
  position: relative;
  vertical-align: bottom;
  border: 1px solid rgba(227, 231, 241, 0.8);
  transition:
    0.3s border-color,
    0.3s background-color;

  &:before {
    content: '';
    position: absolute;
    height: calc(1.25rem - 4px);
    width: calc(1.25rem - 4px);
    top: 1px;
    left: 1px;
    background: $white;
    border-radius: 50%;
    transition: all 0.3s ease;
    box-shadow: 0 1px 2px 0 $black-4;
  }
}

.custom-switch-input {
  &:checked ~ .custom-switch-indicator:before {
    left: calc(1rem + 1px);
  }

  &:focus ~ .custom-switch-indicator {
    box-shadow: none;
    border-color: rgba(227, 231, 241, 0.8);
  }
}

.custom-switch-description {
  margin-left: 0.5rem;
  color: #8499c4;
  transition: 0.3s color;
}

.custom-switch-input:checked ~ .custom-switch-description {
  color: $color;
}
