.footer {
  background: $white;
  border-top: 1px solid rgba(227, 231, 241, 0.8);
  font-size: 0.875rem;
  padding: 1.25rem 1.25rem 1.25rem 1.25rem;

  .container {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .social {
    ul li {
      float: left;
      padding: 7px;
      width: 2rem;
      height: 2rem;
      background: #eeeef6;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.5rem;
      a {
        color: #5371af;
      }
    }

    margin: 0 auto;
    width: auto;
  }
}

@media print {
  .footer {
    display: none;
  }
}

.footer-1.footer {
  padding: 1.25rem 1.25rem 1.25rem 1.25rem !important;
  background: $white;
}

#footer,
.fc-toolbar .ui-button,
.fileinput .thumbnail {
  text-align: center;
}

.top-footer {
  width: 100%;
  line-height: 1.25rem;
  opacity: 1;
  transform: translate(0px, 0px);
  font-size: 14px;
  flex: 0 0 auto;

  h6 {
    font-size: 14px;
    margin-bottom: 1rem;
    font-weight: 500;
  }

  p {
    color: #8890a1;
  }

  a {
    line-height: 2;
    color: #8890a1;
    line-height: 2;

    address {
      color: #8499c4;
      margin-bottom: 0;
      line-height: 2;
    }
  }

  img {
    width: 80px;
    border: 1px solid #e6eaea;
    padding: 2px;
    height: 80px;
    border-radius: 6px;

    &:hover {
      color: #8e9090;
      opacity: 0.6;
    }
  }

  .list-unstyled li {
    border-bottom: 0;
  }
}

.footer-payments {
  padding-left: 0;
  list-style: none;
  margin: 0;
  line-height: 1.25rem;

  li {
    &:not(:last-child) {
      margin-right: 1.5rem;
    }

    display: inline-block;
  }

  a {
    color: #a7a8c7;
    text-decoration: none;
    font-size: 2rem;

    &:hover {
      opacity: 0.9;
    }
  }
}

.main-footer {
  padding: 20px 30px 20px 280px;
  font-size: 14px;
  font-weight: 400;
  background-color: $white;
  border-top: 1px solid #e6eaea;
}

.footer-social-list {
  li {
    &:not(:last-child) {
      margin-right: 1.5rem;
    }

    display: inline-block;
  }

  a {
    text-decoration: none;
    font-size: 1rem;
  }

  padding-left: 0;
  list-style: none;
  margin: 0;
  line-height: 1.25rem;
}

@media (max-width: 992px) {
  .footer-social-list {
    text-align: center;
    margin-top: 1rem;
  }

  .footer1 {
    text-align: center;
  }
}

.footer.hor-footer {
  padding: 1.25rem 1.25rem 1.25rem 0px;
}
