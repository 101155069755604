/*-----Pricing tables-----*/

.pricing-divider {
  border-radius: 5px;
}

.pricing {
  color: $white;
  border-radius: 5px;
}

.pricing {
  .card-category {
    background: $white-3;
    padding: 10px 0;
    color: $white;
  }

  .list-unstyled li {
    border-bottom: 1px solid $white-2;
    padding: 7px 0;
  }
}
.princing-item {
  &:hover {
    .deco-layer--1 {
      -webkit-transform: translate3d(15px, 0, 0);
      transform: translate3d(15px, 0, 0);
    }

    .deco-layer--2 {
      -webkit-transform: translate3d(-15px, 0, 0);
      transform: translate3d(-15px, 0, 0);
    }
  }

  border-radius: 5px;
  box-shadow: (-6px) 5px 8px 0 rgba(7, 16, 31, 0.1);
}

.table > {
  tbody > tr > th {
    font-weight: 500;
  }

  tfoot > tr > {
    td,
    th {
      font-weight: 500;
    }
  }

  thead > tr > {
    td,
    th {
      font-weight: 500;
    }
  }
}

.col-sm-4 .pricing-table .table td {
  padding: 0.75rem;
  vertical-align: top;
  text-align: left;
}

/*------ Pricing Styles ---------*/

.panel.price {
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;

  > .panel-heading {
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
  }

  h3 {
    margin-bottom: 0;
    padding: 20px 0;
  }
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid $white-2;
  background: $white;
}

.panel.price {
  > .panel-heading {
    color: $white;
    border-radius: 5px 5px 0 0;
  }

  .list-group-item {
    &:last-child {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    &:first-child {
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
    }
  }

  margin-bottom: 1.5rem;
  box-shadow: $shadow;
  border-radius: 5px;
}

.price {
  .panel-footer {
    border-bottom: 0px;
    background-color: $white;
    border-left: 0;
    border-right: 0;
    padding: 15px;
  }

  &.panel-color > .panel-body {
    background-color: $white;
  }
}

.panel-body {
  padding: 15px;

  .lead {
    strong {
      font-size: 40px;
      margin-bottom: 0;
    }

    font-size: 20px;
    margin-bottom: 0;
    padding: 10px 0;
  }
}

.panel-footer {
  padding: 10px 15px;
  background-color: $white;
  border-top: 1px solid rgba(227, 231, 241, 0.8);
  border-left: 1px solid rgba(227, 231, 241, 0.8);
  border-right: 1px solid rgba(227, 231, 241, 0.8);
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel.price .btn {
  box-shadow: 0 -1px 0px rgba(50, 50, 50, 0.2) inset;
  border: 0px;
}
.price .list-group-item {
  margin-bottom: -1px !important;
}
.price .list-group-flush > .list-group-item {
  border-width: 1px 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 1px;
}
/*end of pricing*/
