.row-cards > {
  .col,
  [class*='col-'] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.row-deck > {
  .col,
  [class*='col-'] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .col .card,
  [class*='col-'] .card {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
}

.col-login {
  max-width: 24rem;
}

.gutters-0 {
  margin-right: 0;
  margin-left: 0;

  > {
    .col,
    [class*='col-'] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .card {
    margin-bottom: 0;
  }
}

.gutters-xs {
  margin-right: -0.25rem;
  margin-left: -0.25rem;

  > {
    .col,
    [class*='col-'] {
      padding-right: 0.25rem;
      padding-left: 0.25rem;
    }
  }

  .card {
    margin-bottom: 0.5rem;
  }
}

.gutters-sm {
  margin-right: -0.5rem;
  margin-left: -0.5rem;

  > {
    .col,
    [class*='col-'] {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }

  .card {
    margin-bottom: 1rem;
  }
}

.gutters-lg {
  margin-right: -1rem;
  margin-left: -1rem;

  > {
    .col,
    [class*='col-'] {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }

  .card {
    margin-bottom: 2rem;
  }
}

.gutters-xl {
  margin-right: -1.5rem;
  margin-left: -1.5rem;

  > {
    .col,
    [class*='col-'] {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
  }

  .card {
    margin-bottom: 3rem;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > {
    .col,
    [class*='col-'] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
