/*------- Forms -------*/

.form-control {
  display: block !important;
  width: 100%;
  padding: 0.375rem 0.75rem;
  height: 38px;
  font-size: 0.875rem;
  line-height: 1.6;
  color: $color !important;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid rgba(227, 231, 241, 0.8);
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  border-radius: 5px;

  &::-webkit-file-upload-button {
    background-color: $primary;
    color: $white;
  }
  &:hover:not(disabled):not([readonly])::-webkit-file-upload-button {
    background-color: $primary !important;
  }
  &::placeholder {
    color: #adb3bd;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control {
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  &:focus {
    color: $color;
    background-color: $white;
    outline: 0;
    box-shadow: none;
    border-color: rgba(227, 231, 241, 0.8) !important;
  }

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder {
    color: #8499c4;
    opacity: 1;
  }

  color: #99c1b2;
  opacity: 1;

  &:disabled,
  &[readonly] {
    background-color: #edeff9;
    opacity: 1;
  }
}

select.form-control {
  &:not([size]):not([multiple]) {
    height: 2.375rem;
  }

  &:focus::-ms-value {
    color: $color;
    background-color: $white;
    border: 1px solid #d5e4f7;
    border-radius: 5px;
    box-shadow: none;
  }
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.125rem;
  line-height: 1.44444444;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.14285714;
}

.input-group-sm > {
  .form-control-plaintext.form-control,
  .input-group-text > .form-control-plaintext.input-group-text,
  .input-group-text > .form-control-plaintext.input-group-text,
  .input-group-text > .form-control-plaintext.btn,
  .input-group-text > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0;
  }
}

.input-group-lg > {
  .form-control-plaintext.form-control,
  .input-group-text > .form-control-plaintext.input-group-text,
  .input-group-text > .form-control-plaintext.input-group-text,
  .input-group-text > .form-control-plaintext.btn,
  .input-group-text > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0;
  }
}

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.14285714;
}

.input-group-sm > {
  .form-control,
  .input-group-text > .input-group-text,
  .input-group-text > .input-group-text,
  .input-group-text > .btn,
  .input-group-text > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.14285714;
  }
}

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

.input-group-sm > {
  select.form-control:not([size]):not([multiple]),
  .input-group-text > select.input-group-text:not([size]):not([multiple]),
  .input-group-text > select.input-group-text:not([size]):not([multiple]),
  .input-group-text > select.btn:not([size]):not([multiple]),
  .input-group-text > select.btn:not([size]):not([multiple]) {
    height: calc(1.8125rem + 2px);
  }
}

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.44444444;
  border-radius: 3px;
}

.input-group-lg > {
  .form-control,
  .input-group-text > .input-group-text,
  .input-group-text > .input-group-text,
  .input-group-text > .btn,
  .input-group-text > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.125rem;
    line-height: 1.44444444;
    border-radius: 3px;
  }
}

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.6875rem + 2px);
}

.input-group-lg > {
  select.form-control:not([size]):not([multiple]),
  .input-group-text > select.input-group-text:not([size]):not([multiple]),
  .input-group-text > select.input-group-text:not([size]):not([multiple]),
  .input-group-text > select.btn:not([size]):not([multiple]),
  .input-group-text > select.btn:not([size]):not([multiple]) {
    height: calc(2.6875rem + 2px);
  }
}

.form-group {
  margin-bottom: 1rem;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;

  > {
    .col,
    [class*='col-'] {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-left: -1.25rem;

  &:disabled ~ .form-check-label {
    color: #505662;
  }
}

.form-check-label {
  margin-bottom: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 87.5%;
  color: $success;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: $white;
  background-color: rgba(94, 186, 0, 0.8);
  border-radius: 0.2rem;
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: $success;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: $success;
  box-shadow: none;
}

.was-validated .form-control:valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.form-control.is-valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.was-validated .form-select:valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.form-select.is-valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: $success;
}

.was-validated .form-check-input:valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.form-check-input.is-valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: $success;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #9eff3b;
}

.was-validated .custom-control-input:valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.custom-control-input.is-valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #78ed00;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow:
    0 0 0 1px #f4f6fd,
    0 0 0 2px rgba(94, 186, 0, 0.25);
}

.was-validated .form-control-input:valid ~ .form-control-label,
.form-control-input.is-valid ~ .form-control-label {
  border-color: $success;
}

.was-validated .form-control-input:valid ~ .form-control-label::before,
.form-control-input.is-valid ~ .form-control-label::before {
  border-color: inherit;
}

.was-validated .form-control-input:valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.was-validated .form-control-input:valid:focus ~ .form-control-label,
.form-control-input.is-valid:focus ~ .form-control-label {
  box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 87.5%;
  color: $danger;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: $white;
  background-color: rgba(205, 32, 31, 0.8);
  border-radius: 0.2rem;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: $danger;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: $danger;
  box-shadow: none;
}

.was-validated .form-control:invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.form-control.is-invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.was-validated .form-select:invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.form-select.is-invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: $danger;
}

.was-validated .form-check-input:invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.form-check-input.is-invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: $danger;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #ec8080;
}

.was-validated .custom-control-input:invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.custom-control-input.is-invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e23e3d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow:
    0 0 0 1px #f4f6fd,
    0 0 0 2px rgba(205, 32, 31, 0.25);
}

.was-validated .form-control-input:invalid ~ .form-control-label,
.form-control-input.is-invalid ~ .form-control-label {
  border-color: $danger;
}

.was-validated .form-control-input:invalid ~ .form-control-label::before,
.form-control-input.is-invalid ~ .form-control-label::before {
  border-color: inherit;
}

.was-validated .form-control-input:invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.was-validated .form-control-input:invalid:focus ~ .form-control-label,
.form-control-input.is-invalid:focus ~ .form-control-label {
  box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.25);
}

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}

.header .form-inline {
  margin-right: 7px;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline {
    label {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-bottom: 0;
    }

    .form-control {
      display: inline-block;
      width: auto;
    }

    .input-group,
    .form-select {
      width: auto;
    }

    .form-check {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }

    .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0;
    }

    .custom-control {
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
    }

    .custom-control-label {
      margin-bottom: 0;
    }
  }
}

.form-content {
  .form-group {
    display: flex;
  }
}

.form-select:focus {
  border-color: rgba(227, 231, 241, 0.8);
}

.form-check-input:focus {
  box-shadow: none;
  border-color: rgba(227, 231, 241, 0.8);
}

.form-check-input {
  border: 1px solid rgba(227, 231, 241, 0.8);
}

.form-floating > .form-control {
  padding: 0.45rem 0.75rem;
}

.form-floating > .form-control {
  height: calc(2.8rem + 2px);
}

.form-floating > label {
  padding: 13px 12px 0px 12px;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  font-size: 13px;
}

.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.45em;
}

.form-floating > .form-control,
.form-floating > .form-select {
  line-height: 2;
}

.form-check-input:checked {
  background-color: $primary;
  border-color: $primary;
}

.ms-choice > span.placeholder {
  color: transparent !important;
}

input[type='radio'],
input[type='checkbox'] {
  margin-right: 7px;
}

.select2-sm {
  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    top: 39%;
  }
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  border-color: #198754;
  box-shadow: none;
}
.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
  box-shadow: none;
}

.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
  box-shadow: none;
}

.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
  border-color: $danger;
}

.form-control::-webkit-input-placeholder {
  color: #adbdd7;
}
.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px) !important;
}
.input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 17px;
  line-height: 1.3333333;
}
.input-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.5;
}
.select-lg {
  height: 46px !important;
  padding: 10px 16px;
  font-size: 15px;
  line-height: 1.3333333;
}
.select-sm {
  height: calc(1.5em + 0.5rem + 2px) !important;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
