.form-range {
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  min-height: 2.375rem;
  overflow: hidden;
  padding: 0;
  border: 0;

  &:focus {
    box-shadow: none;
    outline: none;

    &::-webkit-slider-thumb,
    &::-moz-range-thumb,
    &::-ms-thumb {
      border-color: #467fcf;
      background-color: #467fcf;
    }
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-webkit-slider-runnable-track {
    background: #467fcf;
    content: '';
    height: 2px;
    pointer-events: none;
  }

  &::-webkit-slider-thumb {
    width: 14px;
    height: 14px;
    -webkit-appearance: none;
    appearance: none;
    background: $white;
    border-radius: 50px;
    box-shadow:
      1px 0 0 -6px rgba(0, 50, 126, 0.12),
      6px 0 0 -6px rgba(0, 50, 126, 0.12),
      7px 0 0 -6px rgba(0, 50, 126, 0.12),
      8px 0 0 -6px rgba(0, 50, 126, 0.12),
      9px 0 0 -6px rgba(0, 50, 126, 0.12),
      10px 0 0 -6px rgba(0, 50, 126, 0.12),
      11px 0 0 -6px rgba(0, 50, 126, 0.12),
      12px 0 0 -6px rgba(0, 50, 126, 0.12),
      13px 0 0 -6px rgba(0, 50, 126, 0.12),
      14px 0 0 -6px rgba(0, 50, 126, 0.12),
      15px 0 0 -6px rgba(0, 50, 126, 0.12),
      16px 0 0 -6px rgba(0, 50, 126, 0.12),
      17px 0 0 -6px rgba(0, 50, 126, 0.12),
      18px 0 0 -6px rgba(0, 50, 126, 0.12),
      19px 0 0 -6px rgba(0, 50, 126, 0.12),
      20px 0 0 -6px rgba(0, 50, 126, 0.12),
      21px 0 0 -6px rgba(0, 50, 126, 0.12),
      22px 0 0 -6px rgba(0, 50, 126, 0.12),
      23px 0 0 -6px rgba(0, 50, 126, 0.12),
      24px 0 0 -6px rgba(0, 50, 126, 0.12),
      25px 0 0 -6px rgba(0, 50, 126, 0.12),
      26px 0 0 -6px rgba(0, 50, 126, 0.12),
      27px 0 0 -6px rgba(0, 50, 126, 0.12),
      28px 0 0 -6px rgba(0, 50, 126, 0.12),
      29px 0 0 -6px rgba(0, 50, 126, 0.12),
      30px 0 0 -6px rgba(0, 50, 126, 0.12),
      31px 0 0 -6px rgba(0, 50, 126, 0.12),
      32px 0 0 -6px rgba(0, 50, 126, 0.12),
      33px 0 0 -6px rgba(0, 50, 126, 0.12),
      34px 0 0 -6px rgba(0, 50, 126, 0.12),
      35px 0 0 -6px rgba(0, 50, 126, 0.12),
      36px 0 0 -6px rgba(0, 50, 126, 0.12),
      37px 0 0 -6px rgba(0, 50, 126, 0.12),
      38px 0 0 -6px rgba(0, 50, 126, 0.12),
      39px 0 0 -6px rgba(0, 50, 126, 0.12),
      40px 0 0 -6px rgba(0, 50, 126, 0.12),
      41px 0 0 -6px rgba(0, 50, 126, 0.12),
      42px 0 0 -6px rgba(0, 50, 126, 0.12),
      43px 0 0 -6px rgba(0, 50, 126, 0.12),
      44px 0 0 -6px rgba(0, 50, 126, 0.12),
      45px 0 0 -6px rgba(0, 50, 126, 0.12),
      46px 0 0 -6px rgba(0, 50, 126, 0.12),
      47px 0 0 -6px rgba(0, 50, 126, 0.12),
      48px 0 0 -6px rgba(0, 50, 126, 0.12),
      49px 0 0 -6px rgba(0, 50, 126, 0.12),
      50px 0 0 -6px rgba(0, 50, 126, 0.12),
      51px 0 0 -6px rgba(0, 50, 126, 0.12),
      52px 0 0 -6px rgba(0, 50, 126, 0.12),
      53px 0 0 -6px rgba(0, 50, 126, 0.12),
      54px 0 0 -6px rgba(0, 50, 126, 0.12),
      55px 0 0 -6px rgba(0, 50, 126, 0.12),
      56px 0 0 -6px rgba(0, 50, 126, 0.12),
      57px 0 0 -6px rgba(0, 50, 126, 0.12),
      58px 0 0 -6px rgba(0, 50, 126, 0.12),
      59px 0 0 -6px rgba(0, 50, 126, 0.12),
      60px 0 0 -6px rgba(0, 50, 126, 0.12),
      61px 0 0 -6px rgba(0, 50, 126, 0.12),
      62px 0 0 -6px rgba(0, 50, 126, 0.12),
      63px 0 0 -6px rgba(0, 50, 126, 0.12),
      64px 0 0 -6px rgba(0, 50, 126, 0.12),
      65px 0 0 -6px rgba(0, 50, 126, 0.12),
      66px 0 0 -6px rgba(0, 50, 126, 0.12),
      67px 0 0 -6px rgba(0, 50, 126, 0.12),
      68px 0 0 -6px rgba(0, 50, 126, 0.12),
      69px 0 0 -6px rgba(0, 50, 126, 0.12),
      70px 0 0 -6px rgba(0, 50, 126, 0.12),
      71px 0 0 -6px rgba(0, 50, 126, 0.12),
      72px 0 0 -6px rgba(0, 50, 126, 0.12),
      73px 0 0 -6px rgba(0, 50, 126, 0.12),
      74px 0 0 -6px rgba(0, 50, 126, 0.12),
      75px 0 0 -6px rgba(0, 50, 126, 0.12),
      76px 0 0 -6px rgba(0, 50, 126, 0.12),
      77px 0 0 -6px rgba(0, 50, 126, 0.12),
      78px 0 0 -6px rgba(0, 50, 126, 0.12),
      79px 0 0 -6px rgba(0, 50, 126, 0.12),
      80px 0 0 -6px rgba(0, 50, 126, 0.12),
      81px 0 0 -6px rgba(0, 50, 126, 0.12),
      82px 0 0 -6px rgba(0, 50, 126, 0.12),
      83px 0 0 -6px rgba(0, 50, 126, 0.12),
      84px 0 0 -6px rgba(0, 50, 126, 0.12),
      85px 0 0 -6px rgba(0, 50, 126, 0.12),
      86px 0 0 -6px rgba(0, 50, 126, 0.12),
      87px 0 0 -6px rgba(0, 50, 126, 0.12),
      88px 0 0 -6px rgba(0, 50, 126, 0.12),
      89px 0 0 -6px rgba(0, 50, 126, 0.12),
      90px 0 0 -6px rgba(0, 50, 126, 0.12),
      91px 0 0 -6px rgba(0, 50, 126, 0.12),
      92px 0 0 -6px rgba(0, 50, 126, 0.12),
      93px 0 0 -6px rgba(0, 50, 126, 0.12),
      94px 0 0 -6px rgba(0, 50, 126, 0.12),
      95px 0 0 -6px rgba(0, 50, 126, 0.12),
      96px 0 0 -6px rgba(0, 50, 126, 0.12),
      97px 0 0 -6px rgba(0, 50, 126, 0.12),
      98px 0 0 -6px rgba(0, 50, 126, 0.12),
      99px 0 0 -6px rgba(0, 50, 126, 0.12),
      100px 0 0 -6px rgba(0, 50, 126, 0.12),
      101px 0 0 -6px rgba(0, 50, 126, 0.12),
      102px 0 0 -6px rgba(0, 50, 126, 0.12),
      103px 0 0 -6px rgba(0, 50, 126, 0.12),
      104px 0 0 -6px rgba(0, 50, 126, 0.12),
      105px 0 0 -6px rgba(0, 50, 126, 0.12),
      106px 0 0 -6px rgba(0, 50, 126, 0.12),
      107px 0 0 -6px rgba(0, 50, 126, 0.12),
      108px 0 0 -6px rgba(0, 50, 126, 0.12),
      109px 0 0 -6px rgba(0, 50, 126, 0.12),
      110px 0 0 -6px rgba(0, 50, 126, 0.12),
      111px 0 0 -6px rgba(0, 50, 126, 0.12),
      112px 0 0 -6px rgba(0, 50, 126, 0.12),
      113px 0 0 -6px rgba(0, 50, 126, 0.12),
      114px 0 0 -6px rgba(0, 50, 126, 0.12),
      115px 0 0 -6px rgba(0, 50, 126, 0.12),
      116px 0 0 -6px rgba(0, 50, 126, 0.12),
      117px 0 0 -6px rgba(0, 50, 126, 0.12),
      118px 0 0 -6px rgba(0, 50, 126, 0.12),
      119px 0 0 -6px rgba(0, 50, 126, 0.12),
      120px 0 0 -6px rgba(0, 50, 126, 0.12),
      121px 0 0 -6px rgba(0, 50, 126, 0.12),
      122px 0 0 -6px rgba(0, 50, 126, 0.12),
      123px 0 0 -6px rgba(0, 50, 126, 0.12),
      124px 0 0 -6px rgba(0, 50, 126, 0.12),
      125px 0 0 -6px rgba(0, 50, 126, 0.12),
      126px 0 0 -6px rgba(0, 50, 126, 0.12),
      127px 0 0 -6px rgba(0, 50, 126, 0.12),
      128px 0 0 -6px rgba(0, 50, 126, 0.12),
      129px 0 0 -6px rgba(0, 50, 126, 0.12),
      130px 0 0 -6px rgba(0, 50, 126, 0.12),
      131px 0 0 -6px rgba(0, 50, 126, 0.12),
      132px 0 0 -6px rgba(0, 50, 126, 0.12),
      133px 0 0 -6px rgba(0, 50, 126, 0.12),
      134px 0 0 -6px rgba(0, 50, 126, 0.12),
      135px 0 0 -6px rgba(0, 50, 126, 0.12),
      136px 0 0 -6px rgba(0, 50, 126, 0.12),
      137px 0 0 -6px rgba(0, 50, 126, 0.12),
      138px 0 0 -6px rgba(0, 50, 126, 0.12),
      139px 0 0 -6px rgba(0, 50, 126, 0.12),
      140px 0 0 -6px rgba(0, 50, 126, 0.12),
      141px 0 0 -6px rgba(0, 50, 126, 0.12),
      142px 0 0 -6px rgba(0, 50, 126, 0.12),
      143px 0 0 -6px rgba(0, 50, 126, 0.12),
      144px 0 0 -6px rgba(0, 50, 126, 0.12),
      145px 0 0 -6px rgba(0, 50, 126, 0.12),
      146px 0 0 -6px rgba(0, 50, 126, 0.12),
      147px 0 0 -6px rgba(0, 50, 126, 0.12),
      148px 0 0 -6px rgba(0, 50, 126, 0.12),
      149px 0 0 -6px rgba(0, 50, 126, 0.12),
      150px 0 0 -6px rgba(0, 50, 126, 0.12),
      151px 0 0 -6px rgba(0, 50, 126, 0.12),
      152px 0 0 -6px rgba(0, 50, 126, 0.12),
      153px 0 0 -6px rgba(0, 50, 126, 0.12),
      154px 0 0 -6px rgba(0, 50, 126, 0.12),
      155px 0 0 -6px rgba(0, 50, 126, 0.12),
      156px 0 0 -6px rgba(0, 50, 126, 0.12),
      157px 0 0 -6px rgba(0, 50, 126, 0.12),
      158px 0 0 -6px rgba(0, 50, 126, 0.12),
      159px 0 0 -6px rgba(0, 50, 126, 0.12),
      160px 0 0 -6px rgba(0, 50, 126, 0.12),
      161px 0 0 -6px rgba(0, 50, 126, 0.12),
      162px 0 0 -6px rgba(0, 50, 126, 0.12),
      163px 0 0 -6px rgba(0, 50, 126, 0.12),
      164px 0 0 -6px rgba(0, 50, 126, 0.12),
      165px 0 0 -6px rgba(0, 50, 126, 0.12),
      166px 0 0 -6px rgba(0, 50, 126, 0.12),
      167px 0 0 -6px rgba(0, 50, 126, 0.12),
      168px 0 0 -6px rgba(0, 50, 126, 0.12),
      169px 0 0 -6px rgba(0, 50, 126, 0.12),
      170px 0 0 -6px rgba(0, 50, 126, 0.12),
      171px 0 0 -6px rgba(0, 50, 126, 0.12),
      172px 0 0 -6px rgba(0, 50, 126, 0.12),
      173px 0 0 -6px rgba(0, 50, 126, 0.12),
      174px 0 0 -6px rgba(0, 50, 126, 0.12),
      175px 0 0 -6px rgba(0, 50, 126, 0.12),
      176px 0 0 -6px rgba(0, 50, 126, 0.12),
      177px 0 0 -6px rgba(0, 50, 126, 0.12),
      178px 0 0 -6px rgba(0, 50, 126, 0.12),
      179px 0 0 -6px rgba(0, 50, 126, 0.12),
      180px 0 0 -6px rgba(0, 50, 126, 0.12),
      181px 0 0 -6px rgba(0, 50, 126, 0.12),
      182px 0 0 -6px rgba(0, 50, 126, 0.12),
      183px 0 0 -6px rgba(0, 50, 126, 0.12),
      184px 0 0 -6px rgba(0, 50, 126, 0.12),
      185px 0 0 -6px rgba(0, 50, 126, 0.12),
      186px 0 0 -6px rgba(0, 50, 126, 0.12),
      187px 0 0 -6px rgba(0, 50, 126, 0.12),
      188px 0 0 -6px rgba(0, 50, 126, 0.12),
      189px 0 0 -6px rgba(0, 50, 126, 0.12),
      190px 0 0 -6px rgba(0, 50, 126, 0.12),
      191px 0 0 -6px rgba(0, 50, 126, 0.12),
      192px 0 0 -6px rgba(0, 50, 126, 0.12),
      193px 0 0 -6px rgba(0, 50, 126, 0.12),
      194px 0 0 -6px rgba(0, 50, 126, 0.12),
      195px 0 0 -6px rgba(0, 50, 126, 0.12),
      196px 0 0 -6px rgba(0, 50, 126, 0.12),
      197px 0 0 -6px rgba(0, 50, 126, 0.12),
      198px 0 0 -6px rgba(0, 50, 126, 0.12),
      199px 0 0 -6px rgba(0, 50, 126, 0.12),
      200px 0 0 -6px rgba(0, 50, 126, 0.12),
      201px 0 0 -6px rgba(0, 50, 126, 0.12),
      202px 0 0 -6px rgba(0, 50, 126, 0.12),
      203px 0 0 -6px rgba(0, 50, 126, 0.12),
      204px 0 0 -6px rgba(0, 50, 126, 0.12),
      205px 0 0 -6px rgba(0, 50, 126, 0.12),
      206px 0 0 -6px rgba(0, 50, 126, 0.12),
      207px 0 0 -6px rgba(0, 50, 126, 0.12),
      208px 0 0 -6px rgba(0, 50, 126, 0.12),
      209px 0 0 -6px rgba(0, 50, 126, 0.12),
      210px 0 0 -6px rgba(0, 50, 126, 0.12),
      211px 0 0 -6px rgba(0, 50, 126, 0.12),
      212px 0 0 -6px rgba(0, 50, 126, 0.12),
      213px 0 0 -6px rgba(0, 50, 126, 0.12),
      214px 0 0 -6px rgba(0, 50, 126, 0.12),
      215px 0 0 -6px rgba(0, 50, 126, 0.12),
      216px 0 0 -6px rgba(0, 50, 126, 0.12),
      217px 0 0 -6px rgba(0, 50, 126, 0.12),
      218px 0 0 -6px rgba(0, 50, 126, 0.12),
      219px 0 0 -6px rgba(0, 50, 126, 0.12),
      220px 0 0 -6px rgba(0, 50, 126, 0.12),
      221px 0 0 -6px rgba(0, 50, 126, 0.12),
      222px 0 0 -6px rgba(0, 50, 126, 0.12),
      223px 0 0 -6px rgba(0, 50, 126, 0.12),
      224px 0 0 -6px rgba(0, 50, 126, 0.12),
      225px 0 0 -6px rgba(0, 50, 126, 0.12),
      226px 0 0 -6px rgba(0, 50, 126, 0.12),
      227px 0 0 -6px rgba(0, 50, 126, 0.12),
      228px 0 0 -6px rgba(0, 50, 126, 0.12),
      229px 0 0 -6px rgba(0, 50, 126, 0.12),
      230px 0 0 -6px rgba(0, 50, 126, 0.12),
      231px 0 0 -6px rgba(0, 50, 126, 0.12),
      232px 0 0 -6px rgba(0, 50, 126, 0.12),
      233px 0 0 -6px rgba(0, 50, 126, 0.12),
      234px 0 0 -6px rgba(0, 50, 126, 0.12),
      235px 0 0 -6px rgba(0, 50, 126, 0.12),
      236px 0 0 -6px rgba(0, 50, 126, 0.12),
      237px 0 0 -6px rgba(0, 50, 126, 0.12),
      238px 0 0 -6px rgba(0, 50, 126, 0.12),
      239px 0 0 -6px rgba(0, 50, 126, 0.12),
      240px 0 0 -6px rgba(0, 50, 126, 0.12);
    margin-top: -6px;
    border: 1px solid rgba(0, 30, 75, 0.12);
    transition:
      0.3s border-color,
      0.3s background-color;
  }

  &::-moz-range-track {
    width: 240px;
    height: 2px;
    background: rgba(0, 50, 126, 0.12);
  }

  &::-moz-range-thumb {
    width: 14px;
    height: 14px;
    background: $white;
    border-radius: 50px;
    border: 1px solid rgba(0, 30, 75, 0.12);
    position: relative;
    transition:
      0.3s border-color,
      0.3s background-color;
  }

  &::-moz-range-progress {
    height: 2px;
    background: #467fcf;
    border: 0;
    margin-top: 0;
  }

  &::-ms-track {
    background: transparent;
    border: 0;
    border-color: transparent;
    border-radius: 0;
    border-width: 0;
    color: transparent;
    height: 2px;
    margin-top: 10px;
    width: 240px;
  }

  &::-ms-thumb {
    width: 240px;
    height: 2px;
    background: $white;
    border-radius: 50px;
    border: 1px solid rgba(0, 30, 75, 0.12);
    transition:
      0.3s border-color,
      0.3s background-color;
  }

  &::-ms-fill-lower {
    background: #467fcf;
    border-radius: 0;
  }

  &::-ms-fill-upper {
    background: rgba(0, 50, 126, 0.12);
    border-radius: 0;
  }

  &::-ms-tooltip {
    display: none;
  }
}
