@import '~@ng-select/ng-select/themes/default.theme.css';
@import '../node_modules/ngx-toastr/toastr.css';
@import '~@angular/cdk/overlay-prebuilt.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

@import '../src/assets/scss/variables';
@import './assets/scss/style.scss';

@import '../node_modules/@aws-amplify/ui-angular/theme.css';

/**
* ------ General Styles -------
*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 200;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-move {
  cursor: move !important;
}

/**
* ------ AWS Amplify Theming -------
*/
amplify-authenticator > div {
  padding-top: 100px !important;
}

.dark-mode {
  .accordion-item {
    color: unset;
  }
}

/* ng select style */
ng-select {
  padding: unset !important;

  .ng-select-container {
    border: unset !important;
    background: unset !important;
  }

  .ng-placeholder {
    color: red; /* Replace 'red' with your desired color */
  }

  &.ng-select-disabled {
    background: #f5f5f5;
  }
}

/**
* ----- Bootstrap Tweaks ------
*/

.card {
  border-radius: 20px;
}

.btn {
  transition: all 0.3s ease-in-out;
  border-radius: 20px;
}

.btn-primary {
  background-color: $primary !important;

  &:hover {
    background-color: $secondary;
  }
}

.btn-outline-primary {
  border-color: $primary !important;
  color: $primary;

  &:hover {
    background-color: $secondary;
    border-color: $secondary !important;
  }
}

.form-group {
  margin-bottom: 0.5rem;
}

.form-label {
  font-weight: 200;
  margin-bottom: 0.2rem;
  margin-top: 0px;
  font-size: 95%;
}

.table > :not(caption) > * > * {
  // Needed for dark theme. If not set, the color will be black in dark theme.
  color: unset;
}

.tooltip-inner {
  font-size: 14px;
}

.table th {
  font-weight: 400;
  color: #828caa;
}

/**
* ----- Bootstrap Tweaks END ------
*/

/**
* ----- Utility classes ------
*/

.no-break-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-auto {
  overflow: auto !important;
}

.nowrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.gray {
  color: gray !important;
}

.label {
  font-weight: 200;
  border-bottom: 1px solid #ffcc33;
}

.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container {
  padding-bottom: 0;
  padding-top: 0;
}

.ng-select .ng-select-container .ng-value-container {
  display: flex;
  flex: 1;
  align-items: stretch;
  padding: 0.4375em;
  border-top: 0 solid transparent;
}

.ng-dropdown-panel.ng-select-bottom {
  min-width: 5rem;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  position: relative;
}

.ng-select .ng-select-container {
  cursor: default;
  display: flex;
  /* outline: none; */
  /* overflow: hidden; */
  position: relative;
  width: 100%;
}

.ng-select.ng-select-multiple .ng-select-container.ng-has-value {
  align-items: center;
}

.ng-select .ng-select-container {
  color: #333;
  /* background-color: #fff; */
  border-radius: 4px;
  border: 1px solid #ccc;
  min-height: 38px;
  align-items: center;
}

.ng-select .ng-has-value .ng-placeholder {
  position: absolute;
}

.valign-middle {
  vertical-align: middle !important;
}

.ks-modal-gallery-backdrop {
  background: #000 !important;
  opacity: 0.85 !important;
}

.ks-modal-gallery-panel {
  z-index: 90000 !important;
}

.app-content {
  padding-top: 60px;
}

.accordion-button::after {
  display: none;
}

.offcanvas.show {
  visibility: visible;
}

.horizontal {
  app-sidebar {
    .ng-scrollbar-wrapper,
    .ng-scroll-viewport-wrapper,
    .ng-scroll-layer,
    .ng-scroll-viewport {
      position: initial;
    }

    .ng-scroll-viewport {
      contain: unset;
    }

    .ng-scroll-viewport-wrapper {
      overflow: auto;
    }

    [_nghost-guy-c128]
      > .ng-scrollbar-wrapper[verticalUsed='true'][_ngcontent-guy-c128]
      > .ng-scroll-viewport-wrapper[_ngcontent-guy-c128]
      > .ng-scroll-viewport[_ngcontent-guy-c128],
    [_nghost-guy-c128]
      > .ng-scrollbar-wrapper[verticalUsed='true'][_ngcontent-guy-c128]
      > .ng-scroll-viewport-wrapper[_ngcontent-guy-c128]
      > *[_ngcontent-guy-c128]
      > *[_ngcontent-guy-c128]
      > .ng-scroll-viewport {
      overflow: auto;
    }
  }
}

.search-result[_ngcontent-tgv-c91] {
  z-index: 9999;
}

.ngx-colors-overlay {
  z-index: 11111111111 !important;
}

.arrow-image,
.right-arrow-image,
.left-arrow-image,
.empty-arrow-image {
  width: 30px;
  height: 30px;
  background-color: var(--bs-gray-500);
  background-size: 30px;
}

.mat-mdc-raised-button:not(:disabled) {
  color: $white !important;
}

.rtl {
  .ngx-colors-overlay {
    .opened {
      left: 0;
    }
  }

  .NgxEditor__Dropdown .NgxEditor__Dropdown--Text:after {
    margin-right: 24px;
    margin-left: inherit;
  }

  .modal-header .close {
    margin: -1rem auto -1rem -1rem;
  }

  .ng-scrollbar-wrapper[deactivated='false'][dir='ltr'] > scrollbar-y.scrollbar-control {
    right: initial;
    left: 0;
  }

  .ng-scroll-content {
    direction: rtl;
    text-align: right;
  }
}

.tooltip.show {
  z-index: 3 !important;
}

.apexcharts-toolbar {
  z-index: 7 !important;
}

.dark-mode {
  .btn {
    color: #fff !important;
  }

  .card {
    color: #fff;
  }

  .wrap-login100 {
    /* width: 960px; */
    background: var(--bs-body-color) !important;
    overflow: hidden;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 137px 130px 137px 95px;
    box-shadow: -6px 5px 8px 0 rgb(209 218 249 / 10%);
    border-radius: 5px;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked,
  .mat-calendar-body-cell-content,
  .mat-date-range-input-separator,
  .mat-calendar-table-header,
  .mat-calendar-body-label {
    color: $text-color;
  }

  .mat-calendar-arrow {
    fill: $text-color;
  }

  .ng-option.ng-option-child.ng-star-inserted,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: $dark-theme !important;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked,
  .ng-option-selected.ng-optgroup.ng-star-inserted.ng-option-marked,
  .mat-calendar-table-header-divider::after {
    background-color: $white-1 !important;
  }

  .mat-mdc-button:not(:disabled),
  .mat-datepicker-toggle,
  .mat-datepicker-content .mat-calendar-next-button,
  .mat-datepicker-content .mat-calendar-previous-button {
    color: $white-8;
  }

  .ng-dropdown-panel {
    background-color: $dark-theme !important;
    border-color: $border-dark;
  }

  .mat-datepicker-content {
    background-color: $dark-theme !important;
    color: $text-color;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
    color: $white-8;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    color: $text-color;
    background-color: $primary !important;
  }

  .ngb-dp-header,
  .cal-month-view,
  .cal-month-view .cal-cell.cal-has-events.cal-open {
    background-color: $dark-body !important;
  }

  .apexcharts-datalabels-group text {
    fill: $text-color;
  }

  table {
    border-color: $border-dark !important;
  }

  .NgxEditor__MenuBar,
  .NgxEditor,
  .NgxEditor__Dropdown .NgxEditor__Dropdown--Selected,
  .NgxEditor__Dropdown .NgxEditor__Dropdown--Open {
    background-color: $dark-body;
    color: $text-color;
  }

  .NgxEditor__Dropdown .NgxEditor__Dropdown--DropdownMenu,
  .NgxEditor__Dropdown .NgxEditor__Dropdown--Item:hover {
    background-color: $dark-body;
  }

  .NgxEditor__Seperator {
    border-left-color: $border-dark;
  }

  .NgxEditor__MenuItem:hover {
    background-color: $dark-theme;
  }

  a.bg-light:hover,
  a.bg-light:focus {
    background-color: $dark-body !important;
  }

  .angular-editor .angular-editor-wrapper .angular-editor-textarea {
    border-color: $white-2 !important;
  }

  .angular-editor-toolbar {
    background-color: var(--dark-body) !important;
    border-color: $white-2 !important;
  }

  input {
    border-color: $white-2 !important;
  }

  .angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button {
    background-color: var(--dark-body) !important;
    border-color: $white-2 !important;

    i {
      color: var(--text-color);
    }
  }

  ngx-dropzone {
    background-color: var(--dark-body);
    border-color: $white-2;
    color: var(--text-color);
  }

  .ae-font .ae-picker-label {
    background-color: var(--dark-body) !important;
    border-color: $white-2 !important;
    color: var(--text-color);

    &::before {
      background: linear-gradient(to right, var(--dark-body), var(--dark-body) 100%);
    }
  }

  .input-group-text {
    color: var(--text-color);
    background-color: rgba(0, 0, 0, 0.2);
  }

  .ng-select .ng-select-container {
    background-color: rgba(0, 0, 0, 0.2);
    color: var(--text-color) !important;
    border-color: #cccccc24;
  }

  .ng-select .ng-clear-wrapper {
    color: #ccc;
  }

  .opened {
    background: var(--bs-body-color) !important;
  }

  .ng-select .ng-select-container .ng-value-container .ng-input > input {
    color: var(--text-color);
  }

  .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: var(--bs-light);
  }

  .ng-select .ng-arrow-wrapper {
    filter: invert(1);
  }

  //apex charts menu dropdown
  #countries .apexcharts-menu {
    background-color: var(--dark-theme);
    border-color: rgba(255, 255, 255, 0.1);
  }

  .apexcharts-menu {
    background-color: var(--dark-theme);
    border-color: rgba(255, 255, 255, 0.1);
  }

  .list-group-transparent .list-group-item.active {
    background-color: var(--dark-body);
    color: var(--text-color);
  }

  .table-inbox tr.unread td {
    background: var(--dark-body);
  }

  .ngx-slider .ngx-slider-bubble {
    color: #e0e3e9;
  }

  .ngx-slider .ngx-slider-bubble.ngx-slider-limit {
    color: white;
  }

  pre {
    margin-right: 18px;
    color: var(--text-color);
  }

  .expanel {
    background-color: rgb(170 176 197 / 60%) !important;
    border-color: rgba(255, 255, 255, 0.1) !important;
    box-shadow: 0 1px 1px rgb(0 0 0 / 15%) !important;
  }

  .dark-mode .panel-group .panel {
    border-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .accordion-button {
    color: var(--text-color);
    color: #f5f6ff;
  }

  .accordion-item {
    border-color: rgb(255 255 255 / 30%);
  }

  // .ng-dropdown-panel .ng-dropdown-panel-items .ng-option{
  //     background-color: var(--dark-body);
  //     color: var(--text-color);
  // }

  .mat-stepper-horizontal,
  .mat-stepper-vertical {
    background-color: var(--dark-theme);
  }

  // .mat-mdc-button:not(:disabled) {
  //     color: #f9e6e6;
  // }

  .mat-step-header .mat-step-label.mat-step-label-active {
    color: rgb(255 255 255 / 87%);
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: var(--dark-color) !important;
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: var(--text-color);
  }

  .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: var(--text-color);
  }

  .dark-mode .carousel-control-next {
    color: var(--text-color);
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    height: 10.5rem;
    width: 2.5rem;
  }

  //                            #carousel-container .current-figure #current-image{
  //      background-color: var(--dark-body);
  //  }
}

#carousel-container > .current-figure > #current-image {
  width: auto !important;
  margin: 0 auto;
}

.rtl {
  .mat-calendar-previous-button,
  .mat-calendar-next-button {
    transform: rotate(0deg) !important;
  }

  .setup2 {
    margin-left: 20px !important;
    margin-right: inherit !important;
  }

  .clear-all {
    left: 30px !important;
    right: inherit !important;
  }

  .selected-list .c-list .c-token {
    float: right !important;
  }

  .iti--allow-dropdown input,
  .iti--allow-dropdown input[type='text'],
  .iti--allow-dropdown input[type='tel'],
  .iti--separate-dial-code input,
  .iti--separate-dial-code input[type='text'],
  .iti--separate-dial-code input[type='tel'] {
    // padding-right: 39px;
    padding-left: 0px;
    margin-left: 0;
  }

  .iti--allow-dropdown .iti__flag-container,
  .iti--separate-dial-code .iti__flag-container {
    right: 0;
    left: auto;
  }

  .breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before {
    transform: rotate(180deg);
  }

  .alert-dismissible .btn-close {
    left: 0;
    right: inherit;
  }

  .notifier__notification-button-icon {
    margin-left: 0.5rem !important;
    margin-right: inherit !important;
  }

  .notifier__notification--material .notifier__notification-button {
    margin-left: -10px;
    margin-right: 10px;
  }

  .ngb-dp-navigation-chevron {
    transform: rotate(45deg);
  }

  .apexcharts-canvas {
    .apexcharts-text {
      text-anchor: start;
    }
  }

  .apexcharts-tooltip-marker {
    margin-left: 10px;
    margin-right: inherit;
  }

  .ct-label.ct-horizontal.ct-end {
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
    text-anchor: end;
  }

  .ngb-dp-arrow.right .ngb-dp-navigation-chevron {
    transform: rotate(-135deg);
  }

  .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel {
    margin-left: 13%;
    margin-right: auto;
  }

  .selected-list .c-angle-down,
  .selected-list .c-angle-up {
    left: 10px;
    right: inherit;
  }

  .ng-select .ng-select-container .ng-value-container {
    padding-right: 10px;
    padding-left: inherit;
  }

  .order-list .list {
    padding-left: initial !important;
  }

  .dropdown-toggle::after {
    margin-right: 0.5rem !important;
    margin-left: inherit !important;
  }

  .toast-header svg {
    margin-left: 5px;
  }

  .toast-header .btn-close {
    margin-left: -0.375rem;
    margin-right: 0.75rem;
  }

  .accordion-toggle {
    i {
      display: inline-block;
    }
  }

  .fe-arrow-right:before {
    content: '\e90f';
  }

  .panel-heading1 {
    i {
      display: inline-block;
    }
  }
}

.error-image {
  overflow-y: hidden;
}

.ng-select.ng-select-opened {
  z-index: 3 !important;
}

.modal-backdrop.fade.show,
.modal-open {
  z-index: 1000000 !important;
}

// .modal-backdrop{
//     z-index : 1000000000 !important;
// }
// .modal-backdrop.show {
//     opacity: .5;
// }

.cal-week-view .cal-event,
.cal-week-view .cal-all-day-events .cal-event {
  background-color: rgb(255, 0, 0);
  color: rgb(255 255 255);
}

input {
  outline: none;
  border: 1px solid rgb(218, 218, 218);
}

// .ql-toolbar.ql-snow {
//     border: 1px solid rgba(56, 57, 58, 0.8);
//     box-sizing: border-box;
//     padding: 8px;
//     border-top-left-radius: 5px;
//     border-top-right-radius: 5px;
// }

// body.rtl {
//     text-align: right;
//     direction: rtl;
// }
// .ql-toolbar.ql-snow {
//     border: 1px solid rgba(253, 252, 252, 0.2);
// }

.ng-select .ng-select-container:after {
  display: none;
}

@media (max-width: 991px) {
  .bs-popover-start.show,
  .bs-popover-auto[data-popper-placement^='left'],
  .bs-popover-end.show,
  .bs-popover-auto[data-popper-placement^='right'] {
    transform: translate3d(-19px, 46px, 0px) !important;
  }
}

@media (max-width: 576px) {
  .canvasd {
    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .course-progress {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      overflow: visible;
    }
  }
}

.main-contact-item.selected {
  .main-contact-body {
    h6,
    span {
      color: #fff;
    }
  }
}

.horizontal {
  .hor-content {
    @media (max-width: 991px) {
      margin-top: 74px !important;
    }
  }
}

.dark-mode {
  .ng-tns-c132-1 {
    color: white !important;
  }
}

.buynow .money-bag,
.free-img {
  margin: 0 auto;
  display: block;
  margin-top: -20px;
}

// @media (max-width: 992px) {
//     .buynow.modal-dialog {
//         max-width: 1000px !important;
//     }
// }
@media (min-width: 992px) and (min-width: 576px) {
  .modal-dialog {
    max-width: 800px;
    margin: 1.75rem auto;
  }
}

.buynow .modal-content {
  background-color: transparent;
  border: 0px solid;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 16px;
  outline: 0;
  box-shadow: 0 5px 20px rgb(0 0 0 / 8%);
}

.buynow .card {
  background-color: transparent;
}

.buynow .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.45rem 0.8rem;
  clear: both;
  font-weight: 400;
  color: #576074;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 15px;
}

.buynow .dropdown-item:hover {
  background-color: #f5f4ff !important;
}

.buynow .btn {
  padding: 0.375rem 0.75rem;
}

.buynow .dropdown-menu {
  border-radius: 5px !important;
  background-color: #ffffff;
  border: 1px solid #ffffff8a;
  box-shadow: 0 0px 90px rgb(155 153 153 / 48%) !important;
}

.buynow .card-block {
  border: 1px solid rgb(231 229 245 / 11%) !important;
  box-shadow: 0 0.15rem 1.75rem 0 rgb(255 255 255 / 6%);
  top: 0;
  bottom: 0;
  background: #6a1cbb69;
  left: 0;
  border-radius: 7px;
  width: 100%;
  height: 100%;
  position: absolute;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
}

@media (max-width: 480px) {
  .buynow .card .btn {
    display: initial !important;
  }
}

.buynow.modal .btn-close:focus {
  box-shadow: none;
}

.buynow .buynow-bg {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #000000a8;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  outline: 0;
  background-size: cover !important;
}

.buynow .modal-dialog .image-top {
  margin-top: -10px !important;
}

.buynow .modal-dialog .card-block {
  background: #6a1cbbbf;
}

.buynow .regular-license {
  z-index: 9;
}

.border-0 {
  border: 0px !important;
}

.modal {
  z-index: 1000000000 !important;
}

.modal .modal-header {
  padding: 10px 20px;
}

@media (max-width: 480px) {
  .buynow .modal-dialog .modal-body {
    margin: 15px;
  }
}

.btn-close {
  position: absolute;
  float: right;
  margin-right: auto;
  // right: 33px;
  //  top: 33px;
  padding: 6px;
  background-image: none;
  border: 1px solid #ffffff47;
  border-radius: 5px;
  line-height: 1;
}

.btn-close span {
  color: #fff;
  font-size: 24px;
  padding: 9px 0px;
  line-height: 0.6;
  opacity: 0.5;
}

.lh-xs {
  line-height: 0.75 !important;
  margin-top: 6px;
}

.buynow .license-view {
  text-align: center;
  text-decoration: underline;
}

.buynow .license-view a {
  padding: 10px;
  border-radius: 5px;
}

.buynow .license-view a:hover {
  padding: 10px;
  background-color: rgb(255 255 255 / 12%);
  border-radius: 5px;
}

.bg-image-style {
  // background-image: url(./assets/switcher/img/bg3.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  inset-block-start: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  position: relative;
  border-radius: 0px;
}

.bg-image-style::before {
  content: '';
  background-color: #ffffffa6;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0;
  position: absolute;
}

.buynow .modal-backdrop {
  background-color: #000000b8;
}

.buynow .card-body.imag-list {
  overflow: visible;
}

.buynow .imag-list {
  position: relative;
  content: '';
  left: 0;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
  border-radius: 7px;
  overflow: hidden;
  z-index: 1;
}

.buynow .imag-list::before {
  content: '';
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 7px;
  position: absolute;
  background: #6a1cbb82;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.buynow .buynow-bg {
  z-index: 0;
  border: 0px #000 solid !important;

  .btn-close {
    right: 30px;
    background: none;
  }
}

.buynow .buynow-bg::before {
  content: '';
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 1rem;
  position: absolute;
  background: #000000b3;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.buynow .Licenses-img {
  border: 1px solid #ffffff2e;
  border-radius: 7px;
}

.modal.buynow .modal-body .card {
  background: transparent;
}

// .tag {
//         // position: absolute;
//         float: right;
//         margin-right: auto;
//         right: 33px;
//         top: 33px;
//         padding: 6px;
//         background-image: none;
//         border: 1px solid #ffffff47;
//         border-radius: 5px;
//         line-height: 1;
//     }

.demo_changer {
  z-index: 9999999999 !important;
}

.tx-12 {
  font-size: 12px !important;
}

.fw-semibold {
  font-weight: 500;
  font-size: 14px;
}

.rtl {
  #tidio-chat-iframe {
    inset: auto auto 0px 0px !important;
  }
}

.form-control {
  border-color: $contrast;
}

.form-control::placeholder {
  color: $contrast;
}

.form-control:focus {
  border-color: $color !important;
}

.form-check-input[type='radio'] {
  border-color: $contrast;
}

.card {
  border-color: $contrast;
}

[data-amplify-authenticator] {
  --amplify-components-authenticator-router-box-shadow: 0 0 0px var(--amplify-colors-overlay-10);
  --amplify-components-authenticator-router-border-width: 0;
  --amplify-components-authenticator-form-padding: var(--amplify-space-medium) var(--amplify-space-xl);
  --amplify-components-button-primary-background-color: var(--primary);
  --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 1px var(--primary);
  --amplify-components-tabs-item-active-border-color: var(--primary);
  --amplify-components-tabs-item-color: var(--primary);
  --amplify-components-tabs-item-active-color: var(--primary);
  --amplify-components-button-link-color: var(--primary);
  --amplify-components-button-link-hover-color: var(--primary-bg-hover);
  --amplify-components-button-primary-hover-background-color: var(--primary-bg-hover);
}

.header-top-custom {
  padding: var(--amplify-space-large);
  text-align: center;
}

div[role='tablist'] {
  display: none;
}

body {
  background: linear-gradient(rgba(255, 255, 255, 2), rgba(255, 255, 255, 0.2)), url(assets/img/pp-background.png);
}

body.dark-mode {
  background: url(assets/img/pp-background.png) !important;
}

.amplify-heading {
  padding: var(--amplify-space-xl) 0 0 0;
  font-size: 1.5rem;
}

.breadcrumb-item a {
  color: $text-color;
}

.breadcrumb-item.active {
  color: $text-color;
}

.dark-mode .breadcrumb-item + .breadcrumb-item::before {
  color: $text-color;
}

.page-header {
  color: $text-color;
}
