/* ###### Popover  ###### */

.popover {
  position: absolute;
  font-family: 'Poppins', sans-serif;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
  max-width: 276px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.84862rem;
  word-wrap: break-word;
  background-color: $white;
  background-clip: padding-box;
  border-radius: 5px;
  padding: 0;
  border: 1px solid rgba(227, 231, 241, 0.8);

  &.show {
    box-shadow: 0 1px 3px rgba(7, 16, 31, 0.1);
  }

  .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
      border-color: transparent;
      border-style: solid;
    }
  }
}

.popover-header {
  font-weight: 400;
  letter-spacing: 0.5px;
  padding: 12px 15px;
  margin-bottom: 0;
  font-size: 0.9375rem;
  background-color: $white;
  border-bottom: 1px solid $border;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  &::before {
    display: none !important;
  }
  &:empty {
    display: none;
  }
}

.popover-body {
  padding: 15px;
  color: #8f9cc0;
  border-radius: 0 0 5px 5px;
  p:last-child {
    margin-bottom: 0;
  }
}

.popover-head-primary {
  .popover-header {
    color: $white;
    background: $primary;
  }

  &.bs-popover-top .popover-arrow,
  &.bs-popover-auto[data-popper-placement^='top'] .popover-arrow {
    bottom: -7px;
  }

  &.bs-popover-start .popover-arrow,
  &.bs-popover-auto[data-popper-placement^='left'] .popover-arrow {
    right: -7px;
  }

  &.bs-popover-end .popover-arrow,
  &.bs-popover-auto[data-popper-placement^='right'] .popover-arrow {
    left: -7px;
  }
}

.popover-head-secondary {
  .popover-header {
    color: $white;
    background-color: $secondary;
  }

  &.bs-popover-top .popover-arrow,
  &.bs-popover-auto[data-popper-placement^='top'] .popover-arrow {
    bottom: -7px;
  }

  &.bs-popover-start .popover-arrow,
  &.bs-popover-auto[data-popper-placement^='left'] .popover-arrow {
    right: -7px;
  }

  &.bs-popover-end .popover-arrow,
  &.bs-popover-auto[data-popper-placement^='right'] .popover-arrow {
    left: -7px;
  }

  &.bs-popover-bottom .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='bottom'] .popover-arrow::after {
    border-bottom-color: $secondary;
  }
}

.popover-head-success {
  .popover-header {
    color: $white;
    background-color: $success;
  }

  &.bs-popover-top .popover-arrow,
  &.bs-popover-auto[data-popper-placement^='top'] .popover-arrow {
    bottom: -7px;
  }

  &.bs-popover-start .popover-arrow,
  &.bs-popover-auto[data-popper-placement^='left'] .popover-arrow {
    right: -7px;
  }

  &.bs-popover-end .popover-arrow,
  &.bs-popover-auto[data-popper-placement^='right'] .popover-arrow {
    left: -7px;
  }

  &.bs-popover-bottom .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='bottom'] .popover-arrow::after {
    border-bottom-color: $success;
  }
}

.popover-head-info {
  .popover-header {
    color: $white;
    background-color: $info;
  }

  &.bs-popover-top .popover-arrow,
  &.bs-popover-auto[data-popper-placement^='top'] .popover-arrow {
    bottom: -7px;
  }

  &.bs-popover-start .popover-arrow,
  &.bs-popover-auto[data-popper-placement^='left'] .popover-arrow {
    right: -7px;
  }

  &.bs-popover-end .popover-arrow,
  &.bs-popover-auto[data-popper-placement^='right'] .popover-arrow {
    left: -7px;
  }

  &.bs-popover-bottom .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='bottom'] .popover-arrow::after {
    border-bottom-color: $info;
  }
}

.popover-head-primary,
.popover-head-secondary,
.popover-head-success,
.popover-head-info {
  .popover-header {
    border: 0;
  }
}

.popover-head-primary .popover-body,
.popover-head-secondary .popover-body,
.popover-head-success .popover-body,
.popover-head-info .popover-body {
  border-top-width: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.popover-head-primary {
  &.bs-popover-bottom .popover-arrow::before,
  &.bs-popover-auto[data-popper-placement^='bottom'] .popover-arrow::before {
    display: none;
  }
}

.popover-head-secondary {
  &.bs-popover-bottom .popover-arrow::before,
  &.bs-popover-auto[data-popper-placement^='bottom'] .popover-arrow::before {
    display: none;
  }
}

.popover-head-success {
  &.bs-popover-bottom .popover-arrow::before,
  &.bs-popover-auto[data-popper-placement^='bottom'] .popover-arrow::before {
    display: none;
  }
}

.popover-head-info {
  &.bs-popover-bottom .popover-arrow::before,
  &.bs-popover-auto[data-popper-placement^='bottom'] .popover-arrow::before {
    display: none;
  }
}

.popover-primary {
  border-width: 0;
  padding: 15px;
  background-color: $primary;
  .popover-header {
    background-color: transparent;
    border-bottom-width: 0;
    padding: 0 0 15px;
    color: $white;
  }

  .popover-body {
    padding: 0;
    color: rgba(255, 255, 255, 0.75);
  }

  .popover-arrow::before {
    display: none;
  }
  &.bs-popover-top .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='top'] .popover-arrow::after {
    border-top-color: $primary;
  }

  &.bs-popover-bottom .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='bottom'] .popover-arrow::after {
    border-bottom-color: $primary;
  }

  &.bs-popover-start .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='left'] .popover-arrow::after {
    border-left-color: $primary;
  }

  &.bs-popover-end .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='right'] .popover-arrow::after {
    border-right-color: $primary;
  }
}

.popover-secondary {
  background-color: $secondary;
  border-width: 0;
  padding: 15px;

  .popover-header {
    background-color: transparent;
    border-bottom-width: 0;
    padding: 0 0 15px;
    color: $white;
  }

  .popover-body {
    padding: 0;
    color: rgba(255, 255, 255, 0.75);
  }

  .popover-arrow::before {
    display: none;
  }

  &.bs-popover-top .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='top'] .popover-arrow::after {
    border-top-color: $secondary;
  }

  &.bs-popover-bottom .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='bottom'] .popover-arrow::after {
    border-bottom-color: $secondary;
  }

  &.bs-popover-start .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='left'] .popover-arrow::after {
    border-left-color: $secondary;
  }

  &.bs-popover-end .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='right'] .popover-arrow::after {
    border-right-color: $secondary;
  }
}

.popover-success {
  background-color: $success;
  border-width: 0;
  padding: 15px;

  .popover-header {
    background-color: transparent;
    border-bottom-width: 0;
    padding: 0 0 15px;
    color: $white;
  }

  .popover-body {
    padding: 0;
    color: rgba(255, 255, 255, 0.75);
  }

  .popover-arrow::before {
    display: none;
  }

  &.bs-popover-top .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='top'] .popover-arrow::after {
    border-top-color: $success;
  }

  &.bs-popover-bottom .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='bottom'] .popover-arrow::after {
    border-bottom-color: $success;
  }

  &.bs-popover-start .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='left'] .popover-arrow::after {
    border-left-color: $success;
  }

  &.bs-popover-end .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='right'] .popover-arrow::after {
    border-right-color: $success;
  }
}

.popover-info {
  background-color: $info;
  border-width: 0;
  padding: 15px;

  .popover-header {
    background-color: transparent;
    border-bottom-width: 0;
    padding: 0 0 15px;
    color: $white;
  }

  .popover-body {
    padding: 0;
    color: rgba(255, 255, 255, 0.75);
  }

  .popover-arrow::before {
    display: none;
  }

  &.bs-popover-top .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='top'] .popover-arrow::after {
    border-top-color: $info;
  }

  &.bs-popover-bottom .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='bottom'] .popover-arrow::after {
    border-bottom-color: $info;
  }

  &.bs-popover-start .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='left'] .popover-arrow::after {
    border-left-color: $info;
  }

  &.bs-popover-end .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='right'] .popover-arrow::after {
    border-right-color: $info;
  }
}

.popover-static-demo {
  background-color: #f4f6fd;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
  border-radius: 6px;

  .popover {
    z-index: 0;
    opacity: 1;
    position: relative;
    display: inline-block;
    margin: 0 10px;
  }

  .bs-popover-top .popover-arrow,
  .bs-popover-auto[data-popper-placement^='top'] .popover-arrow,
  .bs-popover-bottom .popover-arrow,
  .bs-popover-auto[data-popper-placement^='bottom'] .popover-arrow {
    left: 50%;
    margin-left: -5px;
  }

  .bs-popover-start,
  .bs-popover-auto[data-popper-placement^='left'],
  .bs-popover-end,
  .bs-popover-auto[data-popper-placement^='right'] {
    margin-top: 5px;
  }

  .bs-popover-start .popover-arrow,
  .bs-popover-auto[data-popper-placement^='left'] .popover-arrow,
  .bs-popover-end .popover-arrow,
  .bs-popover-auto[data-popper-placement^='right'] .popover-arrow {
    top: 50%;
    margin-top: -5px;
  }
}

.bs-popover-top,
.bs-popover-auto[data-popper-placement^='top'] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .popover-arrow,
.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: $black-1;
}

.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: $white;
}

.bs-popover-end,
.bs-popover-auto[data-popper-placement^='right'] {
  margin-left: 0.5rem;
}

.bs-popover-end > .popover-arrow,
.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: $black-1;
}

.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: $white;
}

.bs-popover-bottom,
.bs-popover-auto[data-popper-placement^='bottom'] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: $black-1;
}

.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: $white;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^='bottom'] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: '';
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-start,
.bs-popover-auto[data-popper-placement^='left'] {
  margin-right: 0.5rem;
}

.bs-popover-start > .popover-arrow,
.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: $black-1;
}

.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: $white;
}

/* ###### Popover  ###### */
