/*------ Modal -------*/

.modal-open {
  overflow: inherit;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: none;
  overflow: hidden;
  outline: 0;
  padding: 0 !important;
  margin: 0 !important;
}

.modal-backdrop.fade.show,
.modal-open {
  padding-right: 0 !important;
}

.modal.show {
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 4px !important;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal {
  &.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition:
      transform 0.3s ease-out,
      -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%);
  }

  &.show .modal-dialog {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: $white;
  background-clip: padding-box;
  border: 0;
  border-radius: 3px;
  outline: 0;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: $black;
  width: auto;
  height: auto;

  &.fade {
    opacity: 0;
  }

  &.show {
    opacity: 0.5;
  }
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid rgba(227, 231, 241, 0.8);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
  }
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;

  p:last-child {
    margin-bottom: 0;
  }
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-top: 1px solid rgba(227, 231, 241, 0.8);

  > {
    :not(:first-child) {
      margin-left: 0.25rem;
    }

    :not(:last-child) {
      margin-right: 0.25rem;
    }
  }
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px !important;
  } /*------ Modal -------*/

  .modal-open {
    overflow: inherit;
  }

  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
    padding: 0 !important;
    margin: 0 !important;
  }

  .modal-backdrop.fade.show,
  .modal-open {
    padding-right: 0 !important;
  }

  .modal.show {
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 4px !important;
  }

  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
  }

  .modal {
    &.fade .modal-dialog {
      transition: -webkit-transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
      transition:
        transform 0.3s ease-out,
        -webkit-transform 0.3s ease-out;
      -webkit-transform: translate(0, -25%);
      transform: translate(0, -25%);
    }

    &.show .modal-dialog {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }

  @media screen and (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
      transition: none;
    }
  }

  .modal-dialog-centered {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
  }

  .modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: $white;
    background-clip: padding-box;
    border: 0;
    border-radius: 3px;
    outline: 0;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: $black;
    width: auto;
    height: auto;

    &.fade {
      opacity: 0;
    }

    &.show {
      opacity: 0.5;
    }
  }

  .modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid rgba(227, 231, 241, 0.8);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    .close {
      padding: 1rem;
      margin: -1rem -1rem -1rem auto;
    }
  }

  .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
  }

  .modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .modal-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    border-top: 1px solid rgba(227, 231, 241, 0.8);

    > {
      :not(:first-child) {
        margin-left: 0.25rem;
      }

      :not(:last-child) {
        margin-right: 0.25rem;
      }
    }
  }

  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto;
    }

    .modal-dialog-centered {
      min-height: calc(100% - (1.75rem * 2));
    }

    .modal-sm {
      max-width: 300px;
    }
  }

  @media (min-width: 992px) {
    .modal-lg {
      max-width: 800px !important;
    }
  }
  .modal-open {
    overflow: auto !important;
  }
  .modal-header .btn-close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
    background-image: none !important;
    font-size: 25px;
  }

  body.modal-open {
    .ui-datepicker {
      z-index: 9999 !important;
      box-shadow: 0px 8px 14.72px 1.28px $black-1;
    }
  }
}
.modal-open {
  overflow: auto !important;
}

body.modal-open {
  .ui-datepicker {
    z-index: 9999 !important;
    box-shadow: 0px 8px 14.72px 1.28px $black-1;
  }
}
.btn-close {
  box-sizing: content-box;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
  padding: 5px;
  line-height: 1;
  font-size: 24px;
  background-image: none;
  color: $black;
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px !important;
  }
}
