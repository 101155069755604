/*----- Custom control -----*/

.custom-control {
  position: relative;
  display: block;
  min-height: 1.2rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;

  &:checked ~ .custom-control-label::before {
    color: $white;
  }

  &:focus ~ .custom-control-label::before {
    box-shadow: none;
  }

  &:active ~ .custom-control-label::before {
    color: $white;
    background-color: rgba(218, 201, 232, 0.5);
  }

  &:disabled ~ .custom-control-label {
    color: #8499c4;

    &::before {
      background-color: #e6e9f7;
    }
  }
}

.custom-control-label {
  margin-bottom: 0;

  &::before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: '';
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: rgba(215, 227, 243, 0.5);
  }

  &::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: '';
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
}

.custom-checkbox {
  .custom-control-label::before {
    border-radius: 3px;
  }

  .custom-control-input {
    &:checked ~ .custom-control-label::after {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    }

    &:indeterminate ~ .custom-control-label::after {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
    }

    &:disabled {
      &:checked ~ .custom-control-label::before {
        opacity: 0.8;
      }

      &:indeterminate ~ .custom-control-label::before {
        background-color: rgba(212, 182, 228, 0.5);
      }
    }
  }
}

.custom-radio {
  .custom-control-label::before {
    border-radius: 50%;
  }

  .custom-control-input {
    &:checked ~ .custom-control-label::after {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
    }

    &:disabled:checked ~ .custom-control-label::before {
      opacity: 0.8;
    }
  }
}

.form-select {
  display: inline-block;
  width: 100%;
  height: 2.375rem;
  padding: 0.5rem 1.75rem 0.5rem 0.75rem;
  line-height: 1.5;
  color: #6c757d;
  vertical-align: middle;
  font-size: 14px;
  background-size: 8px 10px;
  border: 1px solid rgba(227, 231, 241, 0.8);
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    outline: 0;
    box-shadow: none;

    &::-ms-value {
      color: #8499c4;
      background-color: $white;
      box-shadow: none;
    }
  }

  &[multiple],
  &[size]:not([size='1']) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
    box-shadow: none;
  }

  &:disabled {
    color: #8499c4;
    background-color: #e9ecef;
  }

  &::-ms-expand {
    opacity: 0;
  }
}

.form-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 75%;
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}

.form-select-lg {
  height: calc(2.6875rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 125%;
}

.form-control {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 2.375rem;
  margin-bottom: 0;
}

.form-control-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 2.375rem;
  margin: 0;
  opacity: 0;

  &:focus ~ .form-control-label {
    box-shadow: none;
    border-color: #d5e4f7;
  }

  &:lang(en) ~ .form-control-label::after {
    content: 'Browse';
  }
}

.form-control-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 38px;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #8499c4;
  background-color: $white;
  border: 1px solid rgba(227, 231, 241, 0.8);
  border-radius: 5px;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(2.375rem - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: $white;
    content: 'Browse';
    border-left: 1px solid rgba(227, 231, 241, 0.8);
    border-radius: 0 3px 3px 0;
  }
}

.form-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    outline: none;
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    border: 0;
    border-radius: 1rem;
    -webkit-appearance: none;
    appearance: none;

    &:focus {
      outline: none;
      box-shadow:
        0 0 0 1px #f4f6fd,
        0 0 0 2px rgba(98, 58, 162, 0.25);
    }

    &:active {
      background-color: #d4e1f4;
    }
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: rgba(215, 227, 243, 0.5);
    border-color: transparent;
    border-radius: 1rem;
  }

  &::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    border: 0;
    border-radius: 1rem;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      outline: none;
      box-shadow:
        0 0 0 1px #f4f6fd,
        0 0 0 2px rgba(98, 58, 162, 0.25);
    }

    &:active {
      background-color: #d4e1f4;
    }
  }

  &::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: rgba(215, 227, 243, 0.5);
    border-color: transparent;
    border-radius: 1rem;
  }

  &::-ms-thumb {
    width: 1rem;
    height: 1rem;
    border: 0;
    border-radius: 1rem;
    appearance: none;

    &:focus {
      outline: none;
      box-shadow:
        0 0 0 1px #f4f6fd,
        0 0 0 2px rgba(98, 58, 162, 0.25);
    }

    &:active {
      background-color: #d4e1f4;
    }
  }

  &::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
  }

  &::-ms-fill-lower {
    background-color: rgba(215, 227, 243, 0.5);
    border-radius: 1rem;
  }

  &::-ms-fill-upper {
    margin-right: 15px;
    background-color: rgba(215, 227, 243, 0.5);
    border-radius: 1rem;
  }
}

.custom-control {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-controls-stacked .custom-control {
  margin-bottom: 0.25rem;
}

.custom-control-label {
  vertical-align: middle;
  position: unset;

  &:before {
    border: 1px solid rgba(197, 207, 220, 0.9);
    background-color: #f4f6fd;
    background-size: 0.5rem;
  }
}

// form element sizes
.custom-control-label-md::before,
.custom-control-label-md::after {
  top: -0.1rem !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.custom-control-label-md {
  padding-left: 9px;
  padding-top: 6px;
  line-height: 1;
}
.custom-controls-stacked .custom-control-md {
  margin-bottom: 0.25rem;
}
.custom-control-md {
  min-height: 2rem !important;
}

.custom-control-label-lg::before,
.custom-control-label-lg::after {
  top: -0.25rem !important;
  width: 2rem !important;
  height: 2rem !important;
}
.custom-control-label-lg {
  padding-left: 15px;
  padding-top: 10px;
  line-height: 1;
}
.custom-controls-stacked .custom-control-lg {
  margin-bottom: 0.25rem;
}
.custom-control-lg {
  min-height: 2.5rem;
}

.custom-switch-indicator-lg {
  height: 1.5rem !important;
  width: 3rem !important;
}
.custom-switch-input:checked ~ .custom-switch-indicator-lg:before {
  left: calc(1.46rem + 1px) !important;
}
.custom-switch-indicator-lg:before {
  content: '';
  position: absolute;
  height: calc(1.5rem - 4px) !important;
  width: calc(1.5rem - 4px) !important;
  top: 1px;
  left: 1px;
  background: $white;
  border-radius: 50%;
  transition: 0.3s left;
  box-shadow: none;
}

.custom-switch-indicator-xl {
  height: 2rem !important;
  width: 4rem !important;
}
.custom-switch-indicator-xl:before {
  content: '';
  position: absolute;
  height: calc(2rem - 4px) !important;
  width: calc(2rem - 4px) !important;
  top: 1px;
  left: 1px;
  background: $white;
  border-radius: 50%;
  transition: 0.3s left;
  box-shadow: none;
}
.custom-square {
  border-radius: 0px !important;
}
.custom-square:before {
  border-radius: 0px !important;
}

.custom-radius {
  border-radius: 4px !important;
}
.custom-radius:before {
  border-radius: 4px !important;
}
