/*---------- Header-styles ----------*/

/*Header-Color*/

.color-header {
  .main-header-center .form-control {
    color: $white;
    &::placeholder {
      color: $white !important;
      opacity: 0.3;
    }
  }
  .hor-header .header-brand1 {
    margin-top: 4px;
  }
  .country-selector .countries strong {
    color: #677ca7 !important;
  }
}

.color-header {
  .app-sidebar__toggle {
    color: $white !important;
  }
  .main-header-center .form-control::placeholder {
    color: $white !important;
    opacity: 0.3;
  }
  .app-header,
  .hor-header {
    background: $primary;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .header .form-inline .btn svg {
    fill: $white;
  }
  .app-sidebar__toggle:hover {
    color: $white;
  }
  .switch-icon.nav-link i {
    color: #e7e7f7 !important;
  }
  .animated-arrow {
    background: none;
    span {
      background: #e7e7f7;
      &:before,
      &:after {
        background: #e7e7f7;
      }
    }
  }
  &.active .animated-arrow span {
    background-color: transparent;
  }
  .header {
    .form-inline .form-control {
      border-color: $white-1 !important;
      background: transparent;
      color: $white !important;
    }
  }
  .header .form-inline {
    .btn i {
      color: $white !important;
    }
    .form-control::placeholder {
      color: $white !important;
      opacity: 0.5;
    }
  }
  .app-sidebar__toggle svg {
    fill: $white;
    a {
      color: $white;
    }
  }
  .responsive-navbar.navbar .nav-link.icon svg,
  .navbar-toggler-icon {
    fill: $white;
  }
  .profile-1 h6,
  .country-selector strong {
    color: $white !important;
  }
  .nav-link.icon svg {
    fill: $white;
  }
  .hor-header {
    .header-brand-img.light-logo {
      display: block;
    }
  }
  .logo-horizontal .header-brand-img.light-logo1 {
    display: none;
  }
}

@media (min-width: 992px) {
  .color-header {
    .hor-header.header .header-brand .header-brand-img {
      &.desktop-lgo {
        display: none;
      }
      &.dark-logo {
        display: block;
      }
    }
  }
}

@media (min-width: 991px) {
  .color-header {
    .header .nav-link.icon i,
    .header-right .nav-link.icon:hover i {
      color: $white !important;
    }
    .nav-link.icon {
      color: $white !important;
    }
    .nav-link i {
      color: #e7e7f7 !important;
      &:hover {
        color: $white;
      }
    }
  }
}

@media (max-width: 991px) {
  .color-header {
    .hor-header.header .header-brand-img {
      &.mobile-logo {
        display: none !important;
      }
      &.darkmobile-logo {
        display: block;
      }
    }
    .hor-header {
      .header-brand-img {
        &.desktop-logo {
          display: none;
        }
        &.light-logo {
          margin: 0 auto;
          margin-top: 6px;
        }
      }
    }
  }
}

@media (max-width: 1024px) and (min-width: 992px) {
  .color-header {
    .hor-header.header .header-brand-img {
      &.desktop-lgo,
      &.mobile-logo {
        display: none !important;
      }
      &.dark-logo {
        display: block !important;
      }
    }
  }
}

@media (max-width: 991px) {
  .color-header {
    .app-header .header-brand1 .header-brand-img.light-logo1 {
      display: none !important;
    }
    .app-header .header-brand1 .header-brand-img.desktop-logo {
      display: block !important;
    }
    .app-header.header .header-brand-img.desktop-logo {
      display: block;
    }
    .logo-horizontal .header-brand-img.light-logo1 {
      display: none !important;
    }
  }
}

.dark-mode.color-header {
  .app-header,
  .hor-header {
    background: $primary;
  }
  .header .form-inline .form-control,
  &:focus {
    border-color: $border-dark !important;
    color: $white;
    background: transparent !important;
  }
  .app-sidebar__toggle svg {
    fill: $white;
  }
  .responsive-navbar.navbar .navbar-collapse {
    background: none;
  }
  .responsive-navbar.navbar .nav-link.icon svg,
  .navbar-toggler-icon {
    fill: $white;
  }
  .profile-1 h6,
  .country-selector strong {
    color: $white !important;
  }
  .header .form-inline .btn svg {
    fill: $white;
  }
  .nav-link.icon svg {
    fill: $white;
  }
  &.horizontal {
    .hor-header.header {
      background: $primary;
    }
  }
  .country-selector .countries strong {
    color: $text-color !important;
  }
}

/*Header-dark*/

.dark-header {
  .app-header,
  .hor-header {
    background: $dark-theme !important;
    border-bottom: 1px solid $border-dark;
  }
  .hor-header .header-brand1 {
    margin-top: 4px;
  }
  .animated-arrow {
    background: none;
    span {
      background: $primary;
      &:before,
      &:after {
        background: $primary;
      }
    }
  }
  &.active .animated-arrow span {
    background-color: transparent;
  }
  .header.hor-header .nav-link.icon,
  .header-right-icons .nav-link.icon:hover {
    color: #e7e7f7 !important;
  }
  .app-header.header .nav-link:hover {
    color: $white;
  }
  .app-sidebar__toggle:hover {
    color: $white;
  }
  .header .main-header-center .form-control {
    border: 1px solid $white-1 !important;
    color: $white;
    background: #353554;
  }
  .header .form-inline {
    .btn i {
      color: $white;
    }
    .form-control::placeholder {
      color: $white;
      opacity: 0.5;
    }
  }
  .header.hor-header .header-brand-img {
    &.desktop-logo {
      display: block;
    }
    &.light-logo {
      display: block;
    }
  }
  &.horizontal .logo-horizontal .header-brand-img.light-logo1 {
    display: none;
  }
  .app-header .header-right-icons .nav-link.icon {
    color: #d5d7df !important;
  }
  .app-header .header-right-icons .nav-link.icon:hover {
    color: $white;
  }
  .app-sidebar__toggle,
  .profile-1 h6,
  .country-selector strong {
    color: $text-color !important;
  }
  .responsive-navbar.navbar .nav-link.icon svg,
  .app-sidebar__toggle svg,
  .nav-link.icon svg,
  .navbar-toggler-icon {
    fill: $text-color;
  }
  .app-sidebar__toggle:after {
    background: none;
  }
  .nav-link.icon i::after {
    background: none;
  }
  .country-selector .countries strong {
    color: #677ca7 !important;
  }
}

@media (max-width: 992px) {
  .dark-header {
    .app-header .header-brand1 .header-brand-img {
      &.light-logo1 {
        display: none !important;
      }
      &.desktop-logo {
        display: block;
      }
    }
    .hor-header {
      .header-brand-img {
        &.desktop-logo {
          display: none;
        }
        &.light-logo {
          margin: 0 auto;
          margin-top: 6px;
        }
      }
    }
    .app-header.header .header-brand-img.desktop-logo {
      display: block;
    }
    .logo-horizontal .header-brand-img.light-logo1 {
      display: none !important;
    }
  }
}

@media (min-width: 992px) {
  .dark-header {
    .hor-header.header .header-brand .header-brand-img {
      &.desktop-lgo {
        display: none;
      }
      &.dark-logo {
        display: block;
      }
    }
  }
}

@media (max-width: 1024px) and (min-width: 992px) {
  .dark-header {
    .hor-header.header .header-brand-img {
      &.desktop-lgo,
      &.mobile-logo {
        display: none !important;
      }
      &.dark-logo {
        display: block !important;
      }
    }
  }
}

.light-mode.dark-header .hor-header .mobile-header.hor-mobile-header {
  background: #30304d !important;
}

.dark-header {
  .header .form-inline .form-control {
    border-color: $border-dark;
    background: transparent;
    color: $text-color !important;
    &:focus {
      border-color: $border-dark !important;
    }
  }
  .header .form-inline .btn {
    fill: $text-color;
  }
}

/*Header-light*/

.dark-mode.header-light {
  .app-header,
  .hor-header.header {
    background: $white;
    border-bottom: 1px solid rgb(236, 240, 250);
  }
  .app-sidebar {
    box-shadow: none;
  }
  .animated-arrow {
    span {
      background: #5c678f;
      &:before,
      &:after {
        background: #5c678f;
      }
    }
  }
  &.active .animated-arrow span {
    background-color: transparent;
  }
  .header .form-inline {
    .form-control {
      border-color: rgba(227, 231, 241, 0.8);
      background: $white;
      color: $black !important;
      &:focus {
        border-color: rgba(227, 231, 241, 0.8) !important;
        background: $white;
        color: $black !important;
      }
      &.header-search::placeholder {
        color: #adbdd7;
        opacity: 1;
      }
    }
    .btn svg {
      fill: #8499c4;
    }
  }
  .country-selector .countries strong {
    color: $text-color !important;
  }
  .hor-header .header-brand-img.desktop-logo-1 {
    display: none;
  }
  .drop-profile a {
    color: #171a29;
  }
  .hor-header .header-brand-img.desktop-logo {
    display: block;
  }
  .header .nav-link.icon i {
    color: $default-color !important;
  }
  .app-sidebar__toggle {
    a {
      color: #555b95;
    }
  }
  .responsive-navbar.navbar .nav-link.icon svg,
  .nav-link.icon svg,
  .app-sidebar__toggle svg {
    fill: #677ca7;
  }
  .profile-1 h6,
  .country-selector strong,
  .responsive-navbar.navbar .nav-link.icon {
    color: #677ca7 !important;
  }
}

@media (max-width: 575.98px) {
  .dark-mode.header-light .header .form-inline .form-control {
    background: #191d43;
  }
}

@media (max-width: 991px) {
  .dark-mode.header-light {
    .app-header .header-brand1 .header-brand-img {
      &.desktop-logo {
        display: none;
      }
      &.light-logo1 {
        display: block !important;
      }
    }
    .mobile-header {
      background-color: #ffffff;
    }
    .mobile-header .header-brand-img.desktop-logo {
      display: none !important;
    }
    .mobile-header .header-brand-img.desktop-logo.mobile-light {
      display: block !important;
    }
    .navbar-toggler-icon {
      fill: #677ca7;
    }
    &.horizontal {
      .hor-header.header {
        background: $white;
      }
      .hor-header .header-brand-img.light-logo1 {
        display: block !important;
      }
      .hor-header .header-brand-img.desktop-logo {
        display: none;
      }
    }
  }
}

@media (max-width: 991px) and (min-width: 568px) {
  .app.dark-mode.header-light {
    .app-header .header-brand .header-brand-img {
      &.desktop-lgo {
        display: block;
      }
      &.dark-logo,
      &.mobile-logo,
      &.darkmobile-logo {
        display: none !important;
      }
    }
  }
}

@media (max-width: 1024px) and (min-width: 992px) {
  .dark-mode.header-light {
    .hor-header.header .header-brand-img {
      &.dark-logo,
      &.mobile-logo {
        display: none !important;
      }
      &.desktop-lgo {
        display: block !important;
      }
    }
  }
}

/*Header-Gradient-header*/

.gradient-header {
  .app-sidebar__toggle {
    color: $white !important;
  }
  .hor-header .header-brand1 {
    margin-top: 4px;
  }
  .app-header,
  .hor-header {
    background: linear-gradient(to right, $primary 0%, #6699cc 100%) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .main-header-center .form-control {
    color: $white;
    &::placeholder {
      color: $white !important;
      opacity: 0.3;
    }
  }
  .animated-arrow {
    background: none;
    span {
      background: #e7e7f7;
      &:before,
      &:after {
        background: #e7e7f7;
      }
    }
  }
  &.active .animated-arrow span {
    background-color: transparent;
  }
  .app-header.header .nav-link:hover {
    color: $white;
  }
  .app-sidebar__toggle:hover {
    color: $white;
  }
  .switch-icon.nav-link.icon i {
    color: #e7e7f7 !important;
  }
  .header {
    .main-header-center .form-control {
      border: 1px solid $white-1 !important;
      background: rgba(0, 0, 0, 0.08) !important;
      color: $white;
    }
    .main-header-center .form-control:placeholder {
      color: $white !important;
      opacity: 0.5;
    }
  }
  .app-sidebar__toggle svg {
    fill: $white;
    a {
      color: $white;
    }
  }
  .header .form-inline .form-control,
  &:focus {
    color: $white !important;
    border-color: $white-5 !important;
  }
  .header .form-inline .btn svg {
    fill: $white;
  }
  .nav-link.icon svg {
    fill: $white;
  }
  .responsive-navbar.navbar .nav-link.icon svg,
  .navbar-toggler-icon {
    fill: $white;
  }
  .profile-1 h6,
  .country-selector strong {
    color: $white !important;
  }
  .hor-header .header-right-icons .nav-link.icon {
    color: $white !important;
  }
  .header .form-inline .form-control {
    border-color: $white-1 !important;
    background: transparent;
  }
  .country-selector .countries strong {
    color: #677ca7 !important;
  }
}

@media (min-width: 991px) {
  .gradient-header {
    .header .nav-link.icon i,
    .header-right .nav-link.icon:hover i {
      color: $white !important;
    }
    .nav-link.icon i {
      color: $white !important;
    }
    .nav-link i {
      color: #e7e7f7 !important;
      &:hover {
        color: $white;
      }
    }
    &.horizontal .logo-horizontal .header-brand-img.light-logo1 {
      display: none;
    }
    &.horizontal .logo-horizontal .header-brand-img.desktop-logo {
      display: block;
    }
  }
}

@media (min-width: 992px) {
  .gradient-header {
    .hor-header.header .header-brand1 .header-brand-img {
      &.desktop-logo {
        display: none !important;
      }
      &.light-logo {
        display: block;
      }
    }
    .hor-header.header .header-brand2 .header-brand-img {
      &.desktop-logo {
        display: none !important;
      }
      &.light-logo {
        display: block;
      }
    }
  }
}

@media (max-width: 991px) and (min-width: 575.98px) {
  .gradient-header {
    .app-header .header-brand .header-brand-img {
      &.dark-logo {
        display: block;
      }
      &.desktop-lgo,
      &.mobile-logo,
      &.darkmobile-logo {
        display: none !important;
      }
    }
  }
}

@media (max-width: 991px) {
  .gradient-header {
    .app-header .header-brand1 .header-brand-img {
      &.desktop-logo {
        display: block;
      }
      &.light-logo1 {
        display: none !important;
      }
    }
    .navresponsive-toggler span {
      color: $white !important;
    }
    .hor-header {
      .header-brand-img {
        &.desktop-logo {
          display: block !important;
        }
        &.light-logo {
          margin: 0 auto;
          margin-top: 6px;
          display: block;
        }
      }
    }

    .app-header.header .header-brand-img.desktop-logo {
      display: block;
    }
    .logo-horizontal .header-brand-img.light-logo1 {
      display: none !important;
    }
  }
}

@media (max-width: 1024px) and (min-width: 992px) {
  .gradient-header {
    .hor-header.header .header-brand-img {
      &.desktop-lgo,
      &.mobile-logo {
        display: none !important;
      }
      &.dark-logo {
        display: block !important;
      }
    }
  }
}

.dark-mode.gradient-header {
  .app-header,
  .hor-header {
    background: linear-gradient(to right, $primary 0%, #6699cc 100%) !important;
  }
  .header .form-inline .form-control {
    border-color: $white-1 !important;
    color: $white;
  }
  .app-sidebar__toggle svg {
    fill: $white;
  }
  .responsive-navbar.navbar .navbar-collapse {
    background: transparent;
  }
  .responsive-navbar.navbar .nav-link.icon svg,
  .nav-link.icon svg,
  .navbar-toggler-icon {
    fill: $white;
  }
  .profile-1 h6,
  .country-selector strong {
    color: $white !important;
  }
  .header .form-inline .form-control {
    background: transparent;
    color: $white !important;
  }
  .header .form-inline .form-control,
  &:focus {
    border-color: $white-1 !important;
    background: transparent;
    color: $white !important;
  }
  .country-selector .countries strong {
    color: $text-color !important;
  }
}

/*---------- Leftmenu-styles ----------*/

/*Light-menu*/

.light-menu {
  .app-sidebar {
    background: $white;
    .app-sidebar__user {
      border-bottom: 1px solid $border;
    }
    .side-menu .sidemenu_icon {
      color: $white !important;
    }
    .app-sidebar__user {
      .user-pic .avatar-xxl {
        border: 3px solid #ebeff8;
      }
      .user-pic::before {
        border: 2px solid $white;
      }
      .user-info h5 {
        color: #263871;
      }
    }
    .Annoucement_card {
      background: #ebeff8;
      .title,
      .text-white {
        color: #263871 !important;
      }
    }
  }
  .slide a.active .sidemenu_icon,
  .side-menu__item:hover .sidemenu_icon {
    color: $white;
  }
  .slide-menu li {
    &.active > a,
    &:hover > a {
      color: $primary !important;
    }
  }
  .side-item.side-item-category {
    opacity: 0.7 !important;
  }
  &.dark-mode.sidebar-mini.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo {
    display: block !important;
  }
  &.dark-mode.sidebar-mini.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: none !important;
  }
  &.dark-mode.sidebar-mini.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo {
    display: block !important;
  }
  &.dark-mode.sidebar-mini.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: none !important;
  }
  &.dark-mode.hover-submenu1.sidebar-mini.sidenav-toggled .side-menu-label1 {
    border-bottom-color: rgb(225, 229, 233);
  }
  &.horizontal .horizontal-main .slide .slide-menu,
  &.horizontal .horizontal-main .slide .sub-slide-menu,
  &.horizontal .horizontal-main .slide .sub-slide-menu2 {
    border-color: $border;
  }
}

.dark-mode.light-menu {
  .app-sidebar {
    background: $white !important;
    .app-sidebar__user {
      border-bottom: 1px solid $border;
    }
    .app-sidebar__user {
      .user-pic .avatar-xxl {
        border: 3px solid #ebeff8;
      }
      .user-pic::before {
        border: 2px solid $white;
      }
    }
  }
  .slide-menu li {
    &.active > a,
    &:hover > a {
      color: $primary !important;
    }
  }
  .slide-menu a {
    &.active,
    &:hover {
      color: $primary;
      border-color: $primary;
    }
  }
}

@media (min-width: 992px) {
  .light-menu {
    .app-sidebar__logo {
      border-bottom: 1px solid $border;
      border-right: 1px solid $border;
    }
    .app-sidebar .app-sidebar__logo .header-brand-img {
      &.desktop-lgo {
        display: block;
        margin: 0 auto;
      }
      &.dark-logo {
        display: none;
      }
    }
    &.sidenav-toggled {
      .app-sidebar .app-sidebar__logo .header-brand-img {
        &.desktop-lgo {
          display: none;
        }
      }
    }
    &.sidenav-toggled.sidenav-toggled1 {
      .app-sidebar .app-sidebar__logo .header-brand-img {
        &.desktop-lgo {
          display: block;
          margin: 0 auto;
        }
        &.dark-logo {
          display: none;
        }
      }
    }
    &.light-menu.dark-mode.hover-submenu.sidebar-mini.sidenav-toggled .slide-menu {
      background: $white;
      border: 1px solid rgba(227, 231, 241, 0.8);
    }
    &.light-menu.dark-mode.hover-submenu1.sidebar-mini.sidenav-toggled .slide-menu {
      background: $white;
      border: 1px solid rgba(227, 231, 241, 0.8);
    }
    &.light-menu.dark-mode.hover-submenu1.sidebar-mini.sidenav-toggled .slide-menu {
      li .slide-item:before {
        color: $white;
        border: 1px solid #aab3c3;
      }
      a:before {
        border-color: #aab3c3;
      }
    }
  }
  .dark-mode.light-menu {
    .side-header {
      border-bottom: 1px solid $border;
      border-right: 1px solid $border;
      top: 0px;
    }
    .side-menu h3 {
      color: #000;
    }
    .side-menu__item.active:hover,
    .side-menu__item.active:focus {
      color: #ffffff;
    }
    .app-sidebar .side-header .header-brand-img {
      &.light-logo1 {
        display: block !important;
        margin: 0 auto;
      }
      &.desktop-logo {
        display: none !important;
      }
    }
    &.sidenav-toggled {
      .app-sidebar .side-header .header-brand-img {
        &.desktop-logo {
          display: none !important;
        }
      }
    }
    &.app.sidebar-mini.sidenav-toggled .app-sidebar .side-header .header-brand-img {
      &.toggle-logo {
        display: none !important;
      }
      &.light-logo {
        display: block;
      }
    }
    &.sidenav-toggled.sidenav-toggled-open {
      .app-sidebar .side-header .header-brand-img {
        &.light-logo1 {
          display: block !important;
          margin: 0 auto;
        }
        &.desktop-logo {
          display: none !important;
        }
      }
    }
    &.hover-submenu1.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu-label1 a {
      color: #606d94;
    }
  }
}

.app.sidebar-mini.dark-mode.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
  display: none !important;
}

.app.sidebar-mini.dark-mode.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: block !important;
}

.app.sidebar-mini.dark-mode.hover-submenu1.light-menu.sidenav-toggled.sidenav-toggled-open
  .app-sidebar
  .side-header
  .header-brand-img.light-logo1 {
  display: none !important;
}

.app.sidebar-mini.dark-mode.light-menu.hover-submenu.sidenav-toggled.sidenav-toggled-open
  .app-sidebar
  .side-header
  .header-brand-img.light-logo1 {
  display: none !important;
}

/*Color-menu*/

.color-menu {
  .app-sidebar .side-header {
    background: $primary;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    .side-item.side-item-category {
      color: $white;
    }
    .side-menu .side-menu__icon,
    .side-menu .side-menu__item {
      color: #e7eefb;
    }
    .slide a.active .sidemenu_icon,
    .side-menu__item:hover .sidemenu_icon {
      color: $white !important;
    }
    .side-menu .slide a.active,
    .side-menu .slide a:hover {
      color: $white !important;
    }
    .slide .side-menu__item.active,
    .slide .side-menu__item:hover {
      background: $black-1;
    }
    .side-menu__item.active:hover,
    .side-menu__item.active:focus {
      background: linear-gradient(to right, $primary 0%, #8e77fa 100%) !important;
    }
    .side-menu__item:hover .side-menu__label,
    .side-menu__item:hover .side-menu__icon,
    .side-menu__item:hover .angle {
      color: $white !important;
    }
    .app-sidebar__user {
      border-bottom: 1px solid $white-1;
      .user-info h5 {
        color: $white;
      }
      .app-sidebar__user-name.text-muted {
        color: $white !important;
      }
    }
    .app-sidebar__user .user-pic .avatar-xxl {
      border: 3px solid $white;
    }
  }
  &.horizontal .horizontal-main .slide .slide-menu,
  &.horizontal .horizontal-main .slide .sub-slide-menu,
  &.horizontal .horizontal-main .slide .sub-slide-menu2 {
    background-color: $primary;
    border-color: $border-dark;
  }
  &.horizontal .side-menu .slide .side-menu__item.active {
    color: $white !important;
  }
  .slide-menu a.active:before,
  .slide-menu a:hover:before,
  .slide-menu a:focus:before {
    color: $white;
    background: $white !important;
    border-color: $white !important;
  }

  .slide-menu li.is-expanded .sub-side-menu__item:before {
    background: $white;
    border-color: $white;
  }
  .app-sidebar {
    border-right-color: $white-1;
  }
}

@media (min-width: 992px) {
  .color-menu {
    .side-header {
      border-bottom: 1px solid $white-1;
      border-right: 1px solid $white-1;
    }
    .app-sidebar .side-header .header-brand-img {
      &.desktop-logo {
        display: block;
        margin: 0 auto;
      }
      &.light-logo1 {
        display: none;
      }
    }
    &.sidenav-toggled {
      .app-sidebar .side-header .header-brand-img {
        &.desktop-logo {
          display: none;
        }
        &.toggle-logo {
          display: block !important;
        }
        &.light-logo,
        &.light-logo1,
        &.mobile-light {
          display: none;
        }
      }
    }
    &.sidenav-toggled.sidenav-toggled-open {
      .app-sidebar .side-header .header-brand-img {
        &.desktop-logo {
          display: block !important;
          margin: 0 auto;
        }
        &.toggle-logo {
          display: none !important;
        }
        &.light-logo,
        &.light-logo1 {
          display: none !important;
        }
      }
    }
    &.hover-submenu.sidenav-toggled.sidenav-toggled-open {
      .app-sidebar .side-header .header-brand-img {
        &.desktop-logo {
          display: none !important;
        }
        &.toggle-logo {
          display: block !important;
        }
      }
    }
    &.hover-submenu.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo {
      &.light-logo {
        display: none !important;
      }
    }
    &.hover-submenu.dark-mode.sidenav-toggled.sidenav-toggled-open {
      .app-sidebar .side-header .header-brand-img {
        &.desktop-logo {
          display: none !important;
        }
        &.toggle-logo {
          display: block !important;
        }
      }
    }
    &.hover-submenu1.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open {
      .app-sidebar .side-header .header-brand-img {
        &.desktop-logo,
        &.light-logo {
          display: none !important;
        }
        &.toggle-logo {
          display: block !important;
        }
      }
      .side-menu-label1 {
        border-bottom-color: $white-2;
      }
    }
    &.hover-submenu1.dark-mode.sidenav-toggled.sidenav-toggled-open {
      .app-sidebar .side-header .header-brand-img {
        &.desktop-logo {
          display: none !important;
        }
        &.toggle-logo {
          display: block !important;
        }
      }
    }
    &.sidenav-toggled {
      .slide-menu {
        background: $primary !important;
        color: $white;
      }
      .sub-side-menu__item,
      & .sub-side-menu__item2 {
        color: $white-7;
      }
      .sub-slide-item,
      & .sub-slide-item2 {
        color: $white-7;
      }
      .slide-item {
        &:hover,
        &:focus {
          color: $white !important;
        }
      }
    }
  }
}

.dark-mode.color-menu {
  .app-sidebar {
    background: $primary;
    .side-header {
      border-bottom-color: $white-1;
    }
  }
  .app-sidebar .app-sidebar__user .user-pic .avatar-xxl {
    border: 3px solid $white;
  }
  .app-sidebar .app-sidebar__user .user-pic::before {
    border: 2px solid $white;
  }
  .side-item.side-item-category {
    color: $white;
  }
  .slide-item.active {
    color: $white;
  }
  .side-menu__item:hover,
  .side-menu__item:focus {
    background: $black-1;
  }
  .slide-menu li {
    &.active > a,
    &:hover > a {
      color: $white !important;
    }
  }
  .app-sidebar {
    .slide-menu .slide-item:hover,
    .side-menu__item.active .side-menu__icon {
      color: $white !important;
    }
  }
  .slide.is-expanded .side-menu__item {
    color: $white !important;
  }
  .side-menu .side-menu__icon {
    fill: $white-7;
  }
  .side-menu .sub-side-menu__item {
    color: $white-7;
  }
  .slide-menu li {
    &.is-expanded:before,
    &:hover:before {
      background: $primary;
      border-color: $white;
    }
  }
  .side-menu__item,
  .slide-item,
  .sub-slide-item,
  .sub-side-menu__item2,
  .sub-slide-label,
  .sub-slide-item2 {
    color: $white-7;
  }
  .slide-menu li:before {
    border-color: $white-7;
  }
}

@media (min-width: 992px) {
  .dark-mode.color-menu .app-sidebar__logo {
    border-bottom: 1px solid $white-1;
    border-right: 1px solid $white-1;
  }
}

/*dark-menu*/

.dark-menu {
  .app-sidebar {
    background: $dark-theme !important;
    border-right: 1px solid $border-dark;
    .side-item.side-item-category {
      color: $white;
      opacity: 0.3;
    }
    .side-header {
      background: $dark-theme !important;
      border-bottom: 1px solid $border-dark;
    }
    .side-menu .sub-category {
      color: $white-4;
    }
    .side-menu .side-menu__icon {
      fill: $text-color;
    }
    .side-menu .slide-item {
      color: $white-7;
      background: none;
      &.active,
      &:hover {
        color: $primary;
      }
    }
    .slide a.active .side-menu__icon,
    .side-menu__item:hover .side-menu__icon {
      fill: $text-color;
    }
    .side-menu .slide .side-menu__item.active,
    .side-menu .slide .side-menu__item:hover {
      color: $text-color !important;
      background: $white-1;
    }
    .slide-menu li:before {
      border-color: $white-2;
    }
    .side-menu__item,
    .slide-item,
    .sub-slide-item,
    .sub-side-menu__item2,
    .sub-slide-label,
    .sub-slide-item2,
    .sub-side-menu__item {
      color: $white-7;
      &.active,
      &:hover {
        color: $primary;
      }
    }
    .app-sidebar__user {
      border-bottom: 1px solid $white-1;
      .user-info h5 {
        color: $white;
      }
      .app-sidebar__user-name.text-muted {
        color: $white !important;
        opacity: 0.5;
      }
    }
  }
  .slide-menu li .slide-item:before {
    color: $white;
  }
  .side-menu-label1 {
    border-bottom-color: $border-dark !important;
  }
  &.horizontal .horizontal-main .slide .slide-menu,
  &.horizontal .horizontal-main .slide .sub-slide-menu,
  &.horizontal .horizontal-main .slide .sub-slide-menu2 {
    border-color: $border-dark;
    background: #131632;
  }
  &.sidenav-toggled.icon-overlay .app-sidebar .side-header .header-brand-img.light-logo {
    display: none;
  }
}

@media (min-width: 992px) {
  .dark-menu {
    .side-header {
      border-bottom: 1px solid $white-1;
      border-right: 1px solid $white-1;
    }
    .app-sidebar .side-header .header-brand-img {
      &.desktop-logo {
        display: block;
        margin: 0 auto;
      }
      &.light-logo1 {
        display: none;
      }
    }
    &.sidenav-toggled {
      .app-sidebar .side-header .header-brand-img {
        &.desktop-logo,
        &.light-logo1,
        &.light-logo {
          display: none !important;
        }
        &.toggle-logo {
          display: block !important;
        }
      }
    }
    &.sidenav-toggled.sidenav-toggled-open {
      .app-sidebar .side-header .header-brand-img {
        &.desktop-logo {
          display: block !important;
          margin: 0 auto;
        }
        &.light-logo1,
        &.toggle-logo {
          display: none !important;
        }
      }
    }
    &.hover-submenu.sidenav-toggled.sidenav-toggled-open {
      .app-sidebar .side-header .header-brand-img {
        &.desktop-logo {
          display: none !important;
        }
      }
    }
    &.hover-submenu.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo {
      display: none !important;
    }
    &.hover-submenu.app.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
      display: block !important;
    }
    &.hover-submenu.sidebar-mini.sidenav-toggled .slide-menu {
      background-color: #1b1e36;
    }
    &.hover-submenu1.sidenav-toggled.sidenav-toggled-open {
      .app-sidebar .side-header .header-brand-img {
        &.desktop-logo {
          display: none !important;
        }
      }
    }
    &.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
      display: block !important;
    }
    &.hover-submenu1.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo {
      display: none !important;
    }
    &.hover-submenu1.sidebar-mini.sidenav-toggled.sidenav-toggled-open {
      .slide .slide-menu {
        background: #1b1e36;
      }
      .side-menu-label1 a {
        color: $text-color;
      }
    }
    &.dark-mode.sidenav-toggled.sidenav-toggled-open {
      .app-sidebar .side-header .header-brand-img {
        &.desktop-logo {
          display: block !important;
        }
        &.toggle-logo {
          display: none !important;
        }
      }
    }
    &.dark-mode.hover-submenu.sidenav-toggled.sidenav-toggled-open {
      .app-sidebar .side-header .header-brand-img {
        &.desktop-logo {
          display: none !important;
        }
        &.light-logo {
          display: none !important;
        }
        &.toggle-logo {
          display: block !important;
        }
      }
    }
    &.dark-mode.hover-submenu1.sidenav-toggled.sidenav-toggled-open {
      .app-sidebar .side-header .header-brand-img {
        &.desktop-logo {
          display: none !important;
        }
        &.light-logo {
          display: none !important;
        }
        &.toggle-logo {
          display: block !important;
        }
      }
    }
  }
}

/*Graident-Color*/

.gradient-menu {
  .app-sidebar {
    background: linear-gradient(to bottom, $primary 0%, #6699cc 100%) !important;
    .side-item.side-item-category {
      color: $white;
      opacity: 0.3;
    }
    .side-header {
      background: $primary;
      border-bottom: 1px solid $white-1;
      border-right: 1px solid $white-1;
    }
    .side-menu__item.active::after {
      background: $white;
    }
    .side-menu__item:hover::after {
      background: $white;
    }
    .side-menu .slide a {
      color: #e7eefb;
    }
    .side-menu .sub-category {
      color: $white-4;
    }
    .side-menu .side-menu__icon {
      fill: $white-7 !important;
    }
    .side-menu__item:hover .side-menu__icon,
    .side-menu__item:hover .side-menu__label,
    .side-menu__item:focus .side-menu__icon,
    .side-menu__item:focus .side-menu__label {
      color: #ffffff !important;
    }
    .side-menu .slide a,
    .sub-side-menu__item {
      color: $white-7;
    }
    .side-menu__item {
      color: #e7eefb;
    }
    .slide-item {
      color: #e7eefb;
    }
    .slide a.active .side-menu__icon,
    .side-menu__item:hover .side-menu__icon {
      fill: $white !important;
    }
    .side-menu .slide a.active,
    .side-menu .slide a:hover {
      color: $white !important;
      opacity: inherit;
    }
    .slide-menu li.is-expanded:before,
    .slide-menu li:hover:before {
      border: 1px solid $white-7;
      background: $white-3;
    }
    .side-menu__item:hover .side-menu__label,
    .side-menu__item:hover .side-menu__icon,
    .side-menu__item:hover .angle {
      color: $white !important;
      opacity: inherit;
    }
    .app-sidebar__user {
      border-bottom: 1px solid $white-1;
      .user-info h5 {
        color: $white;
      }
      .app-sidebar__user-name.text-muted {
        color: $white !important;
      }
    }
    .app-sidebar__user .user-pic .avatar-xxl {
      border: 3px solid $white;
    }
  }
  &.hover-submenu.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo {
    display: none !important;
  }
  &.hover-submenu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
    border: 0 !important;
  }
  &.hover-submenu1 .side-menu-label1 {
    border-bottom-color: $border-dark !important;
  }
  &.hover-submenu1.sidebar-mini.sidenav-toggled .slide-menu {
    background: linear-gradient(to bottom, $primary 0%, #8e77fa 100%) !important;
  }
  &.hover-submenu1.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu-label1 a {
    color: $white;
  }
  .slide-menu a.active:before,
  .slide-menu a:hover:before {
    color: $white;
    background: $white;
    border-color: $white !important;
  }

  .slide-menu li.is-expanded .sub-side-menu__item:before {
    background: $white;
    border-color: $white;
  }
}

@media (min-width: 992px) {
  .gradient-menu {
    .app-sidebar {
      border-bottom: 1px solid $white-1;
      border-right: 1px solid $white-1;
    }
    .app-sidebar .side-header .header-brand-img {
      &.desktop-logo {
        display: block;
        margin: 0 auto;
      }
      &.light-logo1 {
        display: none;
      }
    }
    &.sidenav-toggled {
      .app-sidebar .side-header .header-brand-img {
        &.desktop-logo {
          display: none;
        }
        &.toggle-logo {
          display: block !important;
        }
        &.light-logo,
        &.light-logo1,
        &.mobile-light {
          display: none;
        }
      }
    }
    &.sidenav-toggled.sidenav-toggled-open {
      .app-sidebar .side-header .header-brand-img {
        &.desktop-logo {
          display: block;
          margin: 0 auto;
        }
        &.toggle-logo {
          display: none !important;
        }
        &.light-logo1 {
          display: none !important;
        }
      }
    }
    &.horizontal .horizontal-main .slide .slide-menu,
    &.horizontal .horizontal-main .slide .sub-slide-menu,
    &.horizontal .horizontal-main .slide .sub-slide-menu2 {
      background: linear-gradient(to bottom, $primary 0%, #6699cc 100%);
      border-color: $border-dark;
    }
    &.hover-submenu.sidebar-mini.sidenav-toggled .slide-menu {
      background: linear-gradient(to bottom, $primary 0%, #8e77fa 100%) !important;
    }
    &.horizontal .app-sidebar {
      background: linear-gradient(to right, $primary 0%, #6699cc 100%) !important;
    }
  }
}

.dark-mode.gradient-menu {
  .app-sidebar {
    .side-menu__item {
      &.active,
      &:hover,
      &:focus {
        color: $white;
        background: $black-1;
        box-shadow: none;
      }
    }
  }
  .app-sidebar .app-sidebar__user .user-pic .avatar-xxl {
    border: 3px solid $white;
  }
  .app-sidebar .app-sidebar__user .user-pic::before {
    border: 2px solid $white;
  }
  .side-item.side-item-category {
    color: $white;
  }
  .app-sidebar ul li a {
    color: $white;
  }
  .slide-menu li {
    &.active > a,
    &:hover > a {
      color: $white !important;
    }
  }
  .app-sidebar {
    .slide-menu .slide-item:hover,
    .side-menu__item.active .side-menu__icon {
      color: $white !important;
    }
  }
  .slide.is-expanded .side-menu__item {
    color: $white !important;
  }
}

@media (min-width: 992px) {
  .dark-mode.gradient-menu {
    .app-sidebar__logo {
      border-bottom: 1px solid $white-1;
      border-right: 1px solid $white-1;
    }
    &.horizontal .horizontal-main .slide .slide-menu,
    &.horizontal .horizontal-main .slide .sub-slide-menu,
    &.horizontal .horizontal-main .slide .sub-slide-menu2 {
      border-color: $white-1;
    }
  }
}

/*---------- Horizontalmenu-styles ----------*/

.dark-mode.gradient-header .mobile-header {
  border-bottom: 1px solid rgb(100, 76, 252) !important;
}

.dark-mode {
  &.color-header.gradient-menu .app-sidebar {
    box-shadow: none;
  }
  &.gradient-header.gradient-header .app-sidebar {
    box-shadow: none;
  }
  &.color-menu.color-header .app-sidebar {
    box-shadow: none;
  }
  &.light-menu.color-header .app-sidebar {
    box-shadow: none;
  }
}

/*dark-hormenu*/

.gradient-header.color-menu .app-sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: none;
}

.gradient-header.color-menu .app-sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: none;
}

.dark-header.color-menu .app-sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: none;
}

.color-header.color-menu .app-sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: none;
}

.gradient-header.gradient-menu .app-sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: none;
}

.dark-header.gradient-menu .app-sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: none;
}

.color-header.gradient-menu .app-sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: none;
}

.gradient-header.dark-menu .app-sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: none;
}

.dark-header.dark-menu .app-sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: none;
}
.dark-mode.dark-header .country-selector .countries strong {
  color: $text-color !important;
}

.rtl {
  &.dark-header.dark-menu,
  &.gradient-header.dark-menu,
  &.color-header.gradient-menu,
  &.dark-header.gradient-menu,
  &.gradient-header.gradient-menu,
  &.dark-header.color-menu,
  &.gradient-header.color-menu,
  &.gradient-header.color-menu,
  &.color-header.dark-menu {
    .app-sidebar {
      border-left: 1px solid rgba(255, 255, 255, 0.2);
      border-right: inherit;
    }
  }
  &.dark-mode.light-menu .side-header {
    border-left: 1px solid rgba(227, 231, 241, 0.8);
    border-right: inherit;
  }
}

.color-header.dark-menu .app-sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: none;
}

@media (max-width: 991px) {
  .dark-mode .mobile-header {
    background-color: #30304d;
    border: 1px solid transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.color-header .header-brand-img.desktop-logo {
  display: none;
}

.color-header.horizontal .header-brand-img.desktop-logo {
  display: block;
}

.color-header .mobile-header.hor-mobile-header {
  background: $primary;
}

@media (max-width: 991px) {
  .color-header .mobile-header .header-brand-img.desktop-logo {
    display: block;
  }
  .color-header .mobile-header .header-brand-img.mobile-light {
    display: none !important;
  }
  .dark-header .app-header .header-right-icons .responsive-navbar .nav-link.icon {
    color: $default-color !important;
  }
  .dark-header .navresponsive-toggler span {
    color: $white;
  }
  .color-header {
    .responsive-navbar .navbar-collapse {
      background: $primary;
    }
    .profile-1 h6,
    .country-selector strong {
      color: #677ca7 !important;
    }
  }
}

.color-header .main-header-center .form-control {
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.08);
}

.color-header .app-sidebar .side-header .header-brand-img.light-logo {
  display: none;
}

.color-header.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo {
  display: block;
}

.color-header.gradient-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo {
  display: none;
}

.gradient-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
  &.desktop-logo {
    display: block !important;
  }
  &.light-logo {
    display: none;
  }
  &.light-logo1 {
    display: block;
  }
}

.gradient-menu.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
  &.desktop-logo {
    display: none !important;
  }
  &.light-logo {
    display: none !important;
  }
  &.toggle-logo {
    display: block !important;
  }
}

.gradient-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img {
  &.desktop-logo {
    display: none !important;
  }
}

.gradient-menu.hover-submenu1.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
  &.desktop-logo {
    display: none !important;
  }
  &.light-logo {
    display: none !important;
  }
  &.toggle-logo {
    display: block !important;
  }
}

.header-light .hor-header .header-brand-img.light-logo {
  display: none;
}

.header-light .main-header-center .form-control {
  color: $text-color !important;
  background-color: #ffffff;
  border: 1px solid #ecf0fa !important;
}

.header-light .header .dropdown .nav-link.icon:hover {
  background: none !important;
}

.header-light.light-menu .side-header {
  border-bottom: 1px solid $border;
  background: $white;
}

.header-light.light-menu .app-sidebar {
  background: $white;
}

.header-light.light-menu .app-sidebar .side-header .header-brand-img {
  &.light-logo1 {
    display: block !important;
  }
  &.desktop-logo {
    display: none !important;
  }
}

.header-light.light-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img {
  &.light-logo1 {
    display: none !important;
  }
}

.header-light.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
  &.light-logo1 {
    display: block !important;
  }
}

.color-header.light-menu .side-header {
  border-bottom: 1px solid $border;
  background: $white;
}

.color-header.light-menu .app-sidebar {
  background: $white;
}

.color-header.light-menu .app-sidebar .side-header .header-brand-img {
  &.light-logo1 {
    display: block !important;
  }
  &.desktop-logo {
    display: none !important;
  }
}

.color-header.light-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img {
  &.light-logo1 {
    display: none !important;
  }
}

.color-header.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
  &.light-logo1 {
    display: block !important;
  }
}

.color-header .navresponsive-toggler span {
  color: $white !important;
}

.gradient-header.light-menu .side-header {
  border-bottom: 1px solid $border;
  background: $white;
}

.gradient-header.light-menu .app-sidebar {
  background: $white;
}

.gradient-header.light-menu .app-sidebar .side-header .header-brand-img {
  &.light-logo1 {
    display: block !important;
  }
  &.desktop-logo {
    display: none !important;
  }
}

.gradient-header.light-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img {
  &.light-logo1 {
    display: none !important;
  }
}

.gradient-header.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
  &.light-logo1 {
    display: block !important;
  }
}

.dark-mode .apexcharts-radialbar-track.apexcharts-track path {
  stroke: $white-1;
}

.dark-mode .apex-charts text {
  fill: $white;
}

.light-mode.color-header.color-menu .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.light-mode.color-header.color-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.light-mode.color-header.color-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo {
  display: none;
}

.light-mode.color-menu .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.app.sidebar-mini.color-menu .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.app.sidebar-mini.color-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.app.sidebar-mini.dark-menu .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.app.sidebar-mini.dark-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.app.sidebar-mini.gradient-menu .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.app.sidebar-mini.gradient-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.app.sidebar-mini.dark-mode.light-menu .side-header {
  background: #ffffff;
}

.dark-header .mobile-header.hor-mobile-header {
  background: #30304d;
}

.gradient-header .mobile-header.hor-mobile-header {
  background: linear-gradient(to right, $primary 0%, #8e77fa 100%) !important;
}

@media (max-width: 991px) {
  .dark-header .mobile-header {
    .header-brand-img {
      &.desktop-logo {
        display: block;
      }
      &.mobile-light {
        display: none !important;
      }
    }
  }
  .dark-header {
    .responsive-navbar .navbar-collapse {
      background: #131632;
    }
    .country-selector strong {
      color: #677ca7 !important;
    }
  }
}

@media (max-width: 991px) {
  .gradient-header .mobile-header {
    .header-brand-img {
      &.desktop-logo {
        display: block;
      }
      &.mobile-light {
        display: none !important;
      }
    }
  }
  .gradient-header {
    .country-selector strong {
      color: #677ca7 !important;
    }
  }
}

.dark-mode.header-light .main-header-center .form-control {
  border: 1px solid #ecf0fa !important;
}

.dark-mode.light-menu .side-menu__item {
  color: #606d94;
}

.dark-mode.horizontal.light-menu .side-menu > li > a {
  color: $default-color;
}

.dark-mode.horizontal.light-menu .side-menu .sub-side-menu__label,
.dark-mode.horizontal.light-menu .sub-slide-label,
.dark-mode.horizontal.light-menu .sub-side-menu__label2 {
  color: $default-color !important;
}

.dark-mode.horizontal.light-menu .horizontal-main .slide .slide-menu,
.dark-mode.horizontal.light-menu .horizontal-main .slide .sub-slide-menu,
.dark-mode.horizontal.light-menu .horizontal-main .slide .sub-slide-menu2 {
  background-color: $white;
}

.dark-mode.light-menu {
  .side-menu .side-menu__icon {
    fill: #677ca7;
    &.active {
      fill: #1d357e;
    }
  }
}
.dark-mode.light-menu .slide-menu {
  li:before {
    border-color: #aab3c3;
  }
  li .slide-item:before {
    border-color: #aab3c3;
  }
  a:before {
    border-color: #aab3c3;
  }
}
.dark-mode.light-menu.sidenav-toggled .side-menu__icon {
  color: $default-color !important;
}
.dark-mode.light-menu.sidenav-toggled-open .side-menu__icon {
  color: $primary !important;
}

.dark-mode.light-menu .slide {
  .slide-item,
  .sub-side-menu__item {
    color: #242e4c;
    &.active {
      color: $primary;
    }
  }
}

.dark-mode.light-menu.hover-submenu .slide-item {
  color: $default-color !important;
}

.dark-mode.light-menu.hover-submenu1 .slide-item {
  color: $default-color !important;
}

.dark-mode.light-menu .side-menu .sub-category,
.dark-mode.light-menu .sub-side-menu__item,
.dark-mode.light-menu .sub-slide-item,
.dark-mode.light-menu .sub-side-menu__item2,
.dark-mode.light-menu .sub-slide-item2 {
  color: $default-color;
}

.dark-mode.hover-submenu1.sidebar-mini.sidenav-toggled {
  .slide-menu {
    background: $dark-theme;
  }
  .side-menu-label1 {
    border-bottom-color: $border-dark;
    a {
      color: $text-color;
    }
  }
}

.dark-mode.header-light {
  &.sidebar-mini .app-header .light-logo1 {
    display: block !important;
  }
  &.sidebar-mini .app-header.header .header-brand-img.desktop-logo {
    display: none;
  }
  .responsive-navbar.navbar .navbar-collapse {
    background: $white;
  }
  &.horizontal {
    .hor-header.header {
      background: $white;
    }
    .logo-horizontal .header-brand-img.desktop-logo {
      display: none;
    }
    .logo-horizontal .header-brand-img.light-logo1 {
      display: block;
    }
  }
}
@media (max-width: 991px) {
  .dark-mode.header-light {
    .country-selector strong {
      color: $text-color !important;
    }
  }
}

/*--Boxed--*/

@media (min-width: 1400px) {
  .horizontal.layout-boxed {
    .stickyClass {
      .app-sidebar {
        max-width: 1400px;
      }
    }
  }

  .layout-boxed.horizontal .hor-header .container,
  .layout-boxed.horizontal .horizontal-main .container,
  .layout-boxed.horizontal .main-content.hor-content .container {
    max-width: 95% !important;
  }

  body.layout-boxed {
    background: #cfd1e2;
  }

  body.layout-boxed.dark-mode {
    background: $dark-body;
  }
  .layout-boxed {
    .page {
      width: 1400px;
      margin: 0 auto;
      background: #f2f3f9;
      position: relative;
      display: -ms-flexbox;
      display: -webkit-box;
      display: flex;
      -ms-flex-direction: column;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      -ms-flex-pack: center;
      -webkit-box-pack: center;
      -webkit-box-shadow:
        rgba(104, 113, 123, 0.3) 0px 5px 0px 0px,
        rgba(104, 113, 123, 0.3) 0px 5px 25px 0px;
      box-shadow:
        rgba(104, 113, 123, 0.3) 0px 5px 0px 0px,
        rgba(104, 113, 123, 0.3) 0px 5px 25px 0px;
    }
    .app-header.header {
      width: 1400px !important;
      margin: 0 auto;
    }
    .app-sidebar {
      left: auto !important;
    }
    .side-header {
      left: auto !important;
      right: auto !important;
    }
    h3.number-font {
      font-size: 1.8rem;
    }
    .sticky-pin .horizontal-main.hor-menu {
      width: 1400px !important;
    }
    .hor-content .container,
    .hor-header .container,
    .horizontal-main.hor-menu .container {
      max-width: 95%;
    }
    .main-sidemenu .slide-right {
      right: 6px;
    }
    .main-sidemenu .slide-left {
      left: 8px;
    }
    .browser-stats img {
      padding: 5px;
      width: 30px;
      height: 30px;
    }
  }
  .layout-boxed .media.media-xs.overflow-visible {
    display: inline-block;
  }
  .dark-mode.layout-boxed .page {
    background: transparent;
    -webkit-box-shadow:
      rgba(0, 0, 0, 0.3) 0px 5px 0px 0px,
      rgba(0, 0, 0, 0.3) 0px 5px 25px 0px;
    box-shadow:
      rgba(0, 0, 0, 0.3) 0px 5px 0px 0px,
      rgba(0, 0, 0, 0.3) 0px 5px 25px 0px;
  }
}

/*--- Scrollabel-header ----*/

.scrollable-layout {
  .app-header,
  .mobile-header {
    position: absolute !important;
  }
  .app-sidebar {
    position: absolute;
  }
  .side-header {
    position: absolute;
  }
  .page {
    position: relative;
  }
  .sticky.sticky-pin {
    position: absolute;
  }
  .navbar {
    position: inherit;
  }
}

@media (max-width: 991px) {
  .scrollable-layout {
    .responsive-navbar.navbar .navbar-collapse {
      position: absolute;
    }

    .app-header,
    .hor-header {
      position: absolute;
    }
  }
}

/*--- Only Demo Purpose scss Icon-Text----*/

@media (min-width: 992px) {
  .icontext-menu {
    &.sidebar-mini.sidenav-toggled .app-content {
      margin-left: 120px;
    }
    &.app.sidebar-mini.sidenav-toggled {
      .side-menu__item {
        padding: 12px 5px;
        text-align: center;
        display: block;
      }
      .side-menu__label {
        display: block !important;
        font-size: 12px;
        padding-top: 0px;
        margin-top: 0;
      }
    }
    &.sidebar-mini.sidenav-toggled {
      .app-header.header {
        padding-left: 130px;
      }
      .side-menu .side-menu__icon:after {
        top: 6px;
      }
      &.sidenav-toggled-open {
        .app-sidebar {
          width: 250px;
        }
        .side-header {
          width: 250px !important;
        }
        .side-menu__item {
          display: flex;
          padding: 8px 14px;
          text-align: inherit;
        }
        .side-menu__label {
          display: block !important;
          font-size: 15px;
        }
        .side-menu .side-menu__icon:after {
          top: 12px;
        }
      }
    }
  }
}

.layout-boxed .login-img {
  .page {
    background: rgba(0, 0, 0, 0.1);
    box-shadow:
      rgba(0, 0, 0, 0.3) 0px 5px 0px 0px,
      rgba(0, 0, 0, 0.5) 0px 5px 25px 0px;
  }
}

.rtl {
  @media (min-width: 1400px) {
    &.layout-boxed .app-sidebar {
      right: auto !important;
    }
  }
}

@media (max-width: 991px) {
  .gradient-header .responsive-navbar .navbar-collapse {
    background: linear-gradient(to right, $primary 0%, #8e77fa 100%);
  }
  .dark-mode.header-light .responsive-navbar .navbar-collapse {
    background-color: $white;
  }
  .dark-mode.header-light .app-sidebar__toggle {
    color: $primary;
  }
  .dark-mode.header-light .navresponsive-toggler span {
    color: $primary;
  }
  .dark-mode.color-header .responsive-navbar .navbar-collapse {
    background-color: $primary !important;
  }
  .dark-mode.color-header {
    &.horizontal {
      .hor-header.header {
        background: $primary;
      }
    }
  }
  .dark-mode.color-header .country-selector strong {
    color: $text-color !important;
  }
  .dark-header.horizontal {
    .responsive-navbar {
      .navbar-collapse {
        .nav-link {
          color: $default-color !important;
        }
      }
    }
  }
}

.dark-mode.light-menu {
  .side-menu .sub-side-menu__label,
  .sub-slide-label,
  .sub-side-menu__label2 {
    color: $default-color !important;
  }
}
.dark-mode.color-menu.hover-submenu.sidebar-mini.sidenav-toggled .slide-menu {
  background: transparent;
}

.dark-mode.gradient-menu.hover-submenu.sidebar-mini.sidenav-toggled .slide-menu {
  background: transparent;
}
.dark-mode.light-menu.hover-submenu.sidebar-mini.sidenav-toggled .slide-menu {
  background: transparent;
}

.dark-mode.color-menu.hover-submenu1.sidebar-mini.sidenav-toggled .slide-menu {
  background: transparent;
}
.dark-mode.gradient-menu.hover-submenu1.sidebar-mini.sidenav-toggled .slide-menu {
  background: transparent;
}
.dark-mode.light-menu.hover-submenu1.sidebar-mini.sidenav-toggled .slide-menu {
  background: transparent;
}

.dark-mode .main-container {
  .onoffswitch2-label {
    background-color: $dark-body;
    border: 1px solid $border-dark;
  }
  .onoffswitch2-label:before {
    background-color: #2a2a4a;
    border: 1px solid $border-dark;
  }
  .input-color-picker {
    background-color: $dark-theme;
  }
  .input-dark-color-picker {
    background-color: $dark-theme;
  }
  .input-transparent-color-picker {
    background-color: $dark-theme;
  }
}

.color-header.color-menu {
  &.sidenav-toggled {
    .header-brand-img.light-logo {
      display: none !important;
    }
  }
  &.rtl {
    .app-sidebar {
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      border-right: inherit;
    }
  }
}

.header-light.light-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img {
  &.light-logo1 {
    display: none !important;
  }
}

.header-light.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img {
  &.light-logo1 {
    display: block !important;
  }
}

.color-menu .sub-slide-item,
.color-menu .sub-slide-item2 {
  color: $white-7;
  &.active {
    color: $white-4;
  }
}
.dark-mode.header-light .app-sidebar__toggle {
  color: var(--primary-bg-color) !important;
}
.switch-toggle {
  .bg-img1 #bgimage1 {
    width: 50px;
    height: 50px;
  }
  .bg-img2 #bgimage2 {
    width: 50px;
    height: 50px;
  }
  .bg-img3 #bgimage3 {
    width: 50px;
    height: 50px;
  }
  .bg-img4 #bgimage4 {
    width: 50px;
    height: 50px;
  }
}
.dark-mode.light-menu .app-sidebar .side-menu .slide .side-menu__item {
  &.active,
  &:hover,
  &:focus {
    color: #1d357e;
    background: #f1f3fd;
  }
}
.switch_section p {
  margin-bottom: 0px;
}
.dark-mode.light-menu.header-light.scrollable-layout .app-sidebar {
  border-right: 1px solid #e6e6e6;
}
.dark-mode.header-light .country span:hover {
  color: $primary !important;
}
.dark-mode.header-light .app-header .header-right-icons .nav-link.icon:hover {
  color: $primary !important;
}
.dark-mode.light-menu .side-menu .slide a.active .sub-side-menu__label {
  color: $primary !important;
}
.dark-mode .side-menu .slide a.active .sub-side-menu__label {
  color: $primary;
}

@media (min-width: 992px) {
  .color-menu .main-sidemenu .slide-left,
  .color-menu .main-sidemenu .slide-right {
    color: $white;
    border: 1px solid $white-3;
    background: $primary;
  }
  .color-menu .main-sidemenu .slide-left svg,
  .color-menu .main-sidemenu .slide-right svg {
    fill: $white;
  }
  .dark-menu .main-sidemenu .slide-left,
  .dark-menu .main-sidemenu .slide-right {
    color: $white;
    border: 1px solid $white-3;
    background: $dark-theme;
  }
  .dark-menu .main-sidemenu .slide-left svg,
  .dark-menu .main-sidemenu .slide-right svg {
    fill: $white;
  }
  .gradient-menu .main-sidemenu .slide-left,
  .gradient-menu .main-sidemenu .slide-right {
    color: $white;
    border: 1px solid $white-3;
    background: $primary;
  }
  .gradient-menu .main-sidemenu .slide-left svg,
  .gradient-menu .main-sidemenu .slide-right svg {
    fill: $white;
  }
  .main-sidemenu .slide-left,
  .main-sidemenu .slide-right {
    background: $white;
    border: 1px solid $border;
  }
  .main-sidemenu .slide-left svg,
  .main-sidemenu .slide-right svg {
    fill: $default-color;
  }
  .dark-mode {
    .main-sidemenu .slide-left,
    .main-sidemenu .slide-right {
      background: $dark-theme;
      border: 1px solid $border-dark;
    }
    .main-sidemenu .slide-left svg,
    .main-sidemenu .slide-right svg {
      fill: $white;
    }
    &.color-menu .main-sidemenu .slide-left,
    &.color-menu .main-sidemenu .slide-right {
      background: $primary;
    }
    &.gradient-menu .main-sidemenu .slide-left,
    &.gradient-menu .main-sidemenu .slide-right {
      background: $primary;
    }
    &.light-menu .main-sidemenu .slide-left,
    &.light-menu .main-sidemenu .slide-right {
      background: $white;
      border: 1px solid $border;
    }
    &.light-menu .main-sidemenu .slide-left svg,
    &.light-menu .main-sidemenu .slide-right svg {
      fill: $default-color;
    }
  }
}

@media (max-width: 991px) {
  .dark-mode.color-header .header.hor-header {
    box-shadow: 0 8px 24px transparent;
  }
  .dark-mode.dark-header .header.hor-header {
    box-shadow: 0 8px 24px transparent;
  }
  .dark-mode.gradient-header .header.hor-header {
    box-shadow: 0 8px 24px transparent;
  }
  .dark-mode.gradient-header .responsive-navbar .navbar-collapse {
    background: linear-gradient(to right, var(--primary-bg-color) 0%, #8e77fa 100%) !important;
  }
}
@media (max-width: 320px) {
  .app .app-sidebar {
    top: 61px;
  }
}

@media (max-width: 992px) {
  .horizontal.dark-mode.sidenav-toggled {
    .header.hor-header {
      box-shadow: none;
    }
  }
}
.light-mode {
  &.bg-img1 .form_holder {
    background: $white !important;
  }
  &.bg-img2 .form_holder {
    background: $white !important;
  }
  &.bg-img3 .form_holder {
    background: $white !important;
  }
  &.bg-img4 .form_holder {
    background: $white !important;
  }
}
.dark-mode {
  &.bg-img1 .form_holder {
    background: $dark-theme !important;
  }
  &.bg-img2 .form_holder {
    background: $dark-theme !important;
  }
  &.bg-img3 .form_holder {
    background: $dark-theme !important;
  }
  &.bg-img4 .form_holder {
    background: $dark-theme !important;
  }
}

@media (min-width: 992px) {
  .hover-submenu.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__icon {
    color: #282f53 !important;
  }
  .hover-submenu1.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__icon {
    color: #282f53 !important;
  }
  .hover-submenu.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__item:hover .side-menu__icon,
  .hover-submenu.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__item:focus .side-menu__icon {
    color: $primary !important;
  }
  .hover-submenu1.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__item:hover .side-menu__icon,
  .hover-submenu1.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__item:focus .side-menu__icon {
    color: $primary !important;
  }

  .hover-submenu.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__item.active .side-menu__icon {
    color: $primary !important;
  }
  .hover-submenu1.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__item.active .side-menu__icon {
    color: $primary !important;
  }
  .hover-submenu1 .sub-slide-item2 {
    padding: 8px 0px 8px 8px;
  }
}
@media screen and (max-width: 992px) {
  .app.sidebar-mini.sidebar-gone.sidenav-toggled.light-menu {
    .side-menu__icon {
      color: $primary !important;
    }
  }
}

.color-menu .side-menu .side-menu__item.active {
  color: $white !important;
  background: $white-1;
  box-shadow: none;
}

@media (min-width: 992px) {
  .rtl.layout-boxed.hover-submenu.sidebar-mini.sidenav-toggled .app-sidebar {
    right: -1px !important;
  }
  .rtl.layout-boxed.hover-submenu1.sidebar-mini.sidenav-toggled .app-sidebar {
    right: -1px !important;
  }
  .rtl {
    &.gradient-menu,
    &.dark-menu,
    &.color-menu {
      .app-sidebar .side-header {
        border-left: 1px solid $white-1;
        border-right: inherit;
      }
    }
  }
}

.dark-mode.color-menu .sub-slide-item.active,
.dark-mode.color-menu .sub-slide-item:hover,
.dark-mode.color-menu .sub-slide-item:focus,
.dark-mode.color-menu .sub-slide-item2.active,
.dark-mode.color-menu .sub-slide-item2:hover,
.dark-mode.color-menu .sub-slide-item2:focus,
.dark-mode.color-menu .sub-side-menu__label.active,
.dark-mode.color-menu .sub-side-menu__label:hover,
.dark-mode.color-menu .sub-side-menu__label:focus,
.dark-mode.color-menu .sub-side-menu__label1.active,
.dark-mode.color-menu .sub-side-menu__label1:hover,
.dark-mode.color-menu .sub-side-menu__label1:focus,
.dark-mode.color-menu .sub-side-menu__label2.active,
.dark-mode.color-menu .sub-side-menu__label2:hover,
.dark-mode.color-menu .sub-side-menu__label2:focus {
  color: $white !important;
}
