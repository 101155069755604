@charset "UTF-8";

a {
  color: $primary;

  &:hover {
    color: $primary;
  }
}

.bg-primary {
  background: $primary !important;
  color: $white;
}

a.bg-primary {
  &:hover,
  &:focus {
    background-color: $primary !important;
  }
}

button.bg-primary {
  &:hover,
  &:focus {
    background-color: $primary !important;
  }
}

/*--- gradient-backgrounds --*/

.bg-primary-gradient {
  background: linear-gradient(to right, $primary 0%, $primary-06 100%) !important;
}

a.bg-primary-gradient {
  &:hover,
  &:focus {
    background-color: $primary !important;
  }
}

button.bg-primary-gradient {
  &:hover,
  &:focus {
    background-color: $primary !important;
  }
}

.border-primary {
  border-color: $primary !important;
}

.text-primary {
  color: $primary !important;
}

a.text-primary {
  &:hover,
  &:focus {
    color: $primary-01 !important;
  }
}

.table-hover .table-primary:hover {
  background-color: #b7cded;

  > {
    td,
    th {
      background-color: #b7cded;
    }
  }
}

.select2-container--default {
  &.select2-container--focus .select2-selection--multiple {
    border-color: $primary;
    box-shadow: none;
  }

  .select2-selection--multiple .select2-selection__choice {
    background-color: $primary !important;
    border: 1px solid $primary !important;
  }
}

.btn-primary {
  color: $white !important;
  background: $primary !important;
  border-color: $primary !important;
  box-shadow: none;

  &:hover {
    color: $white;
    background-color: $primary;
    border-color: $primary;
    opacity: 0.9;
  }

  &:focus,
  &.focus {
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: $white;
    background-color: $primary;
    border-color: $primary;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: $primary;
      border-color: $primary;
    }
  }
}

.show > .btn-primary.dropdown-toggle {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}

.btn-primary:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: none;
  }
}

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-primary-light {
  color: $primary !important;
  background: $primary-03 !important;
  border-color: $primary-03 !important;
  box-shadow: none;

  &:hover {
    color: $white;
    background-color: $primary !important;
    border-color: $primary;
  }

  &:focus,
  &.focus {
    box-shadow: none;
  }
}

.btn-primary-gradient {
  color: $white;
  background: linear-gradient(to right, $primary 0%, $primary-06 100%) !important;
  border-color: $primary !important;
  box-shadow: none;

  &:hover {
    color: $white;
    background-color: $primary;
    border-color: $primary;
  }

  &:focus,
  &.focus {
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: $white;
    background-color: $primary;
    border-color: $primary;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: $primary;
      border-color: $primary;
    }
  }
}

.show > .btn-primary-gradient.dropdown-toggle {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}

.btn-primary-gradient:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: none;
  }
}

.show > .btn-primary-gradient.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-primary {
  color: $primary;
  background: transparent;
  background-image: none;
  border-color: $primary;
  box-shadow: none;

  &:hover {
    color: $white;
    background: $primary;
    border-color: $primary;
  }

  &:focus,
  &.focus {
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: $primary;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: $primary;
      border-color: $primary;
    }
  }
}

.show > .btn-outline-primary.dropdown-toggle {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}

.btn-outline-primary:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: none;
  }
}

.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-link {
  color: $primary;

  &:hover {
    color: $primary;
  }
}

.drop-icon-wrap .drop-icon-item:hover {
  color: $primary;
}

.dropdown-item {
  &:hover,
  &:focus,
  &.active,
  &:active {
    color: $primary;
  }
}

timeline__item:after {
  border: 6px solid $primary;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}

.custom-checkbox .custom-control-input {
  &:checked ~ .custom-control-label::before,
  &:indeterminate ~ .custom-control-label::before,
  &:disabled:checked ~ .custom-control-label::before {
    background-color: $primary;
  }
}

.custom-radio .custom-control-input {
  &:checked ~ .custom-control-label::before {
    background-color: $primary;
    border-color: $primary;
  }

  &:disabled:checked ~ .custom-control-label::before {
    background-color: $primary;
  }
}

.custom-select:focus,
.custom-file-input:focus ~ .custom-file-label::after {
  border-color: $primary;
}

.custom-file-label::after {
  background-color: $primary;
}

.custom-range {
  &::-webkit-slider-thumb,
  &::-moz-range-thumb,
  &::-ms-thumb {
    background-color: $primary;
  }
}

.nav-pills {
  .nav-link.active,
  .show > .nav-link {
    background-color: $primary;
  }
}

.page-link:hover {
  color: $primary;
}

.page-item.active .page-link {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}

.range.range-primary {
  input[type='range'] {
    &::-webkit-slider-thumb,
    &::-moz-slider-thumb {
      background-color: $primary;
    }
  }

  output {
    background-color: $primary;
  }

  input[type='range'] {
    outline-color: $primary;
  }
}

.panel.price > .panel-heading {
  background: $primary;
}

.nav-tabs .nav-item1 .nav-link {
  &:hover:not(.disabled),
  &.active {
    background: $primary;
  }
}

.pop-primary {
  color: $primary;
}

.heading-primary {
  background-color: $primary;
  color: $white;
  padding: 5px;
}

.bg-info,
.bg-success,
.bg-secondary {
  .breadcrumb-item.active,
  .panel-default > .panel-heading {
    color: $white-7;
  }
}

.panel-title1 a {
  background: linear-gradient(to right, $primary 0%, $secondary 100%) !important;
}

.btn.dropdown-toggle.btn-primary ~ .dropdown-menu .dropdown-plus-title {
  border-color: $primary !important;
}

/*-----Badges-----*/

.badge-primary {
  background: $primary;

  &[href] {
    &:hover,
    &:focus {
      color: $white;
      text-decoration: none;
      background-color: $primary;
    }
  }
}

/*-----Badges-----*/

.badge-primary-gradient {
  color: $white;
  background-image: linear-gradient(to right, $primary 0%, $primary-06 100%);

  &[href] {
    &:hover,
    &:focus {
      color: $white;
      text-decoration: none;
      background-color: $primary;
    }
  }
}

.alert-primary {
  color: $primary;
  background-color: $primary-03;
  border-color: $primary-03;

  hr {
    border-top-color: $primary;
  }

  .alert-link {
    color: $primary;
  }
}

.list-group-item-primary {
  color: $primary;
  background-color: #cbdbf2;

  &.list-group-item-action {
    &:hover,
    &:focus {
      color: $primary;
      background-color: #b7cded;
    }

    &.active {
      color: $white;
      background-color: $primary;
      border-color: $primary;
    }
  }
}

.header-bg {
  background: $primary;

  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, $primary 0%, $primary-06 100%) !important;

  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, $primary 0%, $primary-06 100%) !important;
}

.footer a:not(.btn) {
  color: $primary;
}

.nav-tabs .nav-link {
  &:hover:not(.disabled),
  &.active {
    background: $primary;
  }
}

.primary b {
  color: $primary;
}

@media (max-width: 992px) {
  .nav-item.with-sub .sub-item {
    border-top: 2px solid $primary;
  }
}

.expanel-primary > .expanel-heading {
  color: $white !important;
  background-color: $primary !important;
  border-color: $primary !important;
}

.avatar {
  background: $primary no-repeat center/cover;
}

.spinner,
.spinner-lg,
.double-bounce1,
.double-bounce2,
.cube1,
.cube2 {
  background-color: $primary;
}

.lds-heart div {
  background: $primary;

  &:after,
  &:before {
    background: $primary;
  }
}

.lds-ring div {
  border: 6px solid $primary;
  border-color: $primary transparent transparent transparent;
}

.lds-hourglass:after {
  border: 26px solid $primary;
  border-color: $primary transparent;
}

a.chip:hover {
  background-color: $primary;
}

.tag-primary {
  background-color: $primary;
  color: $white;
}

.selectgroup-input {
  &:checked + .selectgroup-button {
    border-color: $primary;
    z-index: 1;
    color: $primary;
    background: #f4f6fd;
  }

  &:focus + .selectgroup-button {
    border-color: $primary;
    z-index: 2;
    color: $primary;
    box-shadow: 0 0 0 2px $primary-025;
  }
}

.custom-switch-input:checked ~ .custom-switch-indicator {
  background: $primary;
}

.ui-datepicker .ui-datepicker-title {
  color: $primary;
}

.timeline--horizontal .timeline-divider {
  background: $primary !important;
}

#back-to-top {
  background-image: linear-gradient(to right, $primary 0%, $primary 100%);

  &:hover {
    color: $primary !important;
    border: 2px solid $primary;
  }
}

.tabs-menu ul li .active {
  background: $primary;
  color: $white;
  box-shadow: none;
}

.tabs-menu1 ul li .active {
  border-bottom: 2px solid $primary;
}

/* .tab-content i{
	color:$primary;
} */

.tabs-menu2 ul li .active {
  color: $primary;
}

.rating-stars .rating-stars-container .rating-star {
  &.is--active .fa-heart,
  &.is--hover .fa-heart {
    color: #ec444c;
  }
}

.message-feed:not(.right) .mf-content {
  background: $primary;
  color: $white;

  &:before {
    border-right-color: $primary;
  }
}

.msb-reply button {
  background: $primary;
}

/* --------Added--------- css*/

.wizard-card .moving-tab {
  margin-top: 5px;
  background: linear-gradient(to right, $primary 0%, $primary 100%);
}

.cal1 .clndr {
  .clndr-table {
    .header-days {
      background: $primary;
    }

    tr .day {
      &.today.event,
      &.my-today.event {
        background: $primary;
      }
    }
  }

  .clndr-controls .clndr-control-button {
    .clndr-previous-button {
      color: $white;
      background: $primary;
    }

    .clndr-next-button {
      color: $white;
      background: $primary;

      &:hover {
        background: $primary;
      }
    }

    .clndr-previous-button:hover {
      background: $primary;
    }
  }
}

.fc button {
  background: $primary;
}

.fc-event {
  border: 1px solid $primary;
  background-color: $primary;
}

.fc-event-dot {
  background-color: $primary;
}

/* ----Added css----*/

div.conv-form-wrapper div.options div.option {
  color: $primary;
  border: 1px solid $primary;
}

form.convFormDynamic button.submit {
  color: $primary;
  border: 1px solid $primary !important;
}

div.conv-form-wrapper div.options div.option.selected {
  background: $primary;
  color: $white;

  &:hover {
    background: $primary;
  }
}

form.convFormDynamic button.submit:hover {
  background: $primary !important;
  color: $white;
}

div.conv-form-wrapper div#messages div.message.from {
  background: $primary;
}

.addui-slider .addui-slider-track {
  .addui-slider-handle:after,
  .addui-slider-range {
    background: $primary;
  }
}

.timeline__item:after {
  border: 6px solid $primary;
}

.accordionjs .acc_section.acc_active > .acc_head {
  background: $primary;
  color: $white !important;
}

.tab_wrapper {
  > ul li.active {
    border-color: $primary;
    background: $primary;
  }

  &.right_side > ul li.active:after {
    background: $primary;
  }
}

.cal1 .clndr .clndr-table tr .day {
  &.today,
  &.my-today,
  &.today:hover,
  &.my-today:hover {
    background: $primary;
  }
}

.weather-card .top {
  background: $primary;
}

.primary .pricing-divider {
  background: $primary !important;
}

.product-grid6 {
  .title a:hover {
    color: $primary;
  }

  .icons li .btn {
    color: $primary;
    border: 1px solid $primary;

    &:hover {
      color: $white;
      background-image: linear-gradient(to right, $primary 0%, $primary 100%);
    }

    &:after,
    &:before {
      background-image: linear-gradient(to right, $primary 0%, $primary 100%);
    }
  }
}

.card-counter.primary {
  background-image: linear-gradient(to right, $primary 0%, $primary 100%);
  color: $white;
}

#user-profile .profile-details {
  a > i,
  i {
    color: $primary;
  }
}

@media (max-width: 767.98px) and (min-width: 576px) {
  .search-show .search-element {
    background-image: linear-gradient(to right, $primary 0%, $primary 100%);
  }
}

@media (max-width: 575.98px) {
  .search-show .search-element {
    background-image: linear-gradient(to right, $primary 0%, $primary 100%);
  }
}

.text-primary-gradient {
  background: linear-gradient(to right, $primary 0%, $primary 100%);
}

.chat .msg_head,
.msb-reply button {
  background: $primary;
}

.top-footer {
  a {
    address:hover,
    &:hover {
      color: $primary;
    }
  }
}

.footer-payments a:hover {
  color: $primary;
}

.footer-social-list a {
  color: $primary;

  &:hover {
    color: $primary;
  }
}

.sw-theme-dots .nav-tabs .nav-link:hover:not(.disabled) {
  color: $primary;
}

.custom-switch-input:checked ~ .custom-switch-indicator {
  background: $primary;
}

.label-primary {
  background: $primary;
  color: $white;
}

.bg-primary-transparent {
  background-color: $primary-01 !important;
}

.box-primary-shadow {
  box-shadow: 0 5px 10px $primary-03;
}

.text-primary-shadow {
  text-shadow: 0 5px 10px $primary-03;
}

.chart-dropshadow-primary {
  -webkit-filter: drop-shadow((-6px) 12px 4px $primary-02);
  filter: drop-shadow((-6px) 12px 4px $primary-02);
}

.fc-toolbar {
  .fc-state-active,
  .ui-state-active {
    background: #434bbd;
  }
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role='row'] > {
  td:first-child:before,
  th:first-child:before {
    background-color: $primary;
  }
}

.sw-theme-default {
  ul.step-anchor {
    li {
      &.active a,
      &.done a::after {
        background: $primary !important;
      }
    }
  }
}

.sweet-alert {
  button {
    background-color: $primary !important;
  }
}

/*-- Sidemenu --*/

.side-menu__item {
  &.active,
  &:hover,
  &:focus {
    color: $color;
    opacity: 0.9;
  }

  &.active .side-menu__icon,
  &:hover .side-menu__icon,
  &:focus .side-menu__icon {
    fill: $color;
  }
}

.slide-menu {
  a {
    &.active,
    &:hover {
      color: $primary;
    }
  }

  li {
    &.is-expanded:before,
    &:hover:before {
      background: $primary;
      border: 1px solid $primary;
    }
  }

  a.active {
    color: $primary;
  }
}

.side-menu .slide.submenu .slide-item {
  &.active,
  &:hover {
    color: $primary !important;
  }
}

.slide-item {
  &.active,
  &:hover,
  &:focus {
    text-decoration: none;
    color: $primary;
  }

  &.active:before,
  &:hover:before,
  &.active:before,
  &:hover:before {
    background: $primary;
    border: 1px solid $primary !important;
  }
}

.slide.submenu.is-expanded {
  a {
    &.active,
    &:hover {
      color: #0052cc;
    }
  }

  .side-menu__item {
    &.active:before,
    &:hover:before {
      background: $primary;
      border: 1px solid $primary !important;
    }
  }
}

.dark-mode .slide.submenu.is-expanded {
  a {
    &.active,
    &:hover {
      color: #0052cc;
    }
  }

  .side-menu__item {
    &.active:before,
    &:hover:before {
      background: $primary;
      border: 1px solid $primary !important;
    }
  }
}

/*-- Horizontal-menu --*/

.hor-menu .horizontalMenu > .horizontalMenu-list > li > {
  ul.sub-menu > li > {
    a:hover,
    ul.sub-menu > li > a:hover {
      color: $primary;
    }
  }

  .horizontal-megamenu .link-list li a {
    &:hover,
    &.active {
      color: $primary;
    }
  }
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a.active {
  color: $primary;
}

.hor-menu .horizontalMenu > .horizontalMenu-list > li > {
  ul.sub-menu > li > a.active {
    color: $primary;
  }

  a {
    &.active .hor-icon,
    &:hover .hor-icon {
      fill: #1d357e;
    }
  }
}

.sub-menu li {
  &.active:before,
  &:hover:before {
    background: $primary;
    border: 1px solid $primary;
  }
}

.mega-menubg li a {
  &.active:before,
  &:hover:before {
    background: $primary;
    border: 1px solid $primary;
  }
}

.onoffswitch-checkbox:checked + .onoffswitch-label {
  background-color: $primary;
  border-color: $primary;

  &:before {
    border-color: $primary;
  }
}

.onoffswitch2-checkbox:checked + {
  .onoffswitch2-label {
    background-color: $primary !important;
  }

  .onoffswitch-label2,
  .onoffswitch2-label:before {
    border-color: $primary;
  }
}

/*Color-Header*/

.color-hor-header .header.hor-header {
  background: $primary;
  border-bottom: 1px solid $white-1;
}

.light-hor-menu .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
  &.active,
  &:hover {
    color: $white;
    background: $primary;
  }
}

.dark-hor-menu .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
  &.active,
  &:hover {
    color: $white;
    background: $primary;
  }
}

.light-menu {
  &.dark-mode .side-menu__item {
    &.active .side-menu__icon,
    &:hover .side-menu__icon,
    &:focus .side-menu__icon {
      fill: #1d357e;
    }

    &.active,
    &:hover,
    &:focus {
      color: $white;
    }
  }

  .side-menu__item {
    &.active .side-menu__icon,
    &:hover .side-menu__icon,
    &:focus .side-menu__icon {
      color: $white;
    }

    &.active,
    &:hover,
    &:focus {
      color: #1d357e;
    }
  }
}

.default-body .app-sidebar {
  background: $primary !important;
}

.white-mode.light-menu .side-menu__item {
  &.active .side-menu__icon,
  &:hover .side-menu__icon,
  &:focus .side-menu__icon {
    color: $white;
  }
}

/*Color-Header*/

.color-hor-header {
  .header.hor-header,
  .app-header {
    background: $primary;
    border-bottom: 1px solid transparent;
  }
}

@media only screen and (max-width: 991px) {
  .dark-mode .horizontalMenu > .horizontalMenu-list {
    background: #151727;
    border-right: 1px solid $white-04;
  }
}

.dark-menu .side-menu__item {
  &.active .side-menu__icon,
  &:hover .side-menu__icon,
  &:focus .side-menu__icon {
    color: $white;
  }

  &.active,
  &:hover,
  &:focus {
    color: $white;
    background: $dark-body;
  }
}

.color-menu {
  .app-sidebar {
    background: $primary !important;
  }
  .side-header {
    background: $primary;
    border-bottom: 1px solid $white-1;
  }
  .side-menu__item {
    &.active .side-menu__icon,
    &:hover .side-menu__icon,
    &:focus .side-menu__icon,
    &.active,
    &:hover,
    &:focus {
      fill: $white !important;
    }
    &.active {
      color: $white;
    }
  }
  .side-menu .side-menu__item.active::after {
    background: $white;
  }
  .side-menu .side-menu__item:hover::after {
    background: $white;
  }
  .side-menu .sub-category {
    color: $white-4 !important;
  }
  .side-menu .side-menu__icon {
    fill: $white-7;
  }
  .side-menu .side-menu__item,
  .sub-side-menu__item {
    color: $white-7;
  }
  .side-menu__item:hover,
  .side-menu__item:focus {
    color: $white !important;
    background: $white-1;
    box-shadow: none;
  }
  .side-menu__item:hover .side-menu__icon,
  .side-menu__item:hover .side-menu__label,
  .side-menu__item:focus .side-menu__icon,
  .side-menu__item:focus .side-menu__label {
    color: $white !important;
  }
  .slide-item {
    color: $white-7;
    &.active {
      color: $white;
    }
  }
  .slide-item:hover,
  .slide-item:focus,
  .slide-menu a:hover {
    color: $white !important;
  }
  .slide-menu li .slide-item:before {
    color: $white-7;
  }
  .side-menu__item,
  .sub-side-menu__item2 {
    color: $white-7;
  }
  .side-menu-label1 {
    border-bottom-color: 1px solid $border-dark !important;
    a {
      color: $white !important;
    }
  }
  .slide-menu a {
    &:before,
    &:hover:before {
      background: var(--primary-bg-color);
      border: 1px solid $white;
    }
  }
}

.gradient-menu .side-menu__item {
  &.active .side-menu__icon,
  &:hover .side-menu__icon,
  &:focus .side-menu__icon {
    color: $white;
  }

  &.active,
  &:hover,
  &:focus {
    color: $white;
    background: $white-1;
    box-shadow: 0px 1px 5px 0 rgba(7, 16, 31, 0.1);
  }
}

.color-menu.sidemenu-bgimage {
  .side-header:before,
  .app-sidebar:before {
    background: $primary-094;
  }
}

.white-mode.color-hor-menu .horizontal-main.hor-menu,
.dark-mode.color-hor-menu .horizontal-main.hor-menu,
.light-mode.color-hor-menu .horizontal-main.hor-menu,
.color-hor-menu .horizontal-main.hor-menu {
  background: $primary;
}

@media (max-width: 767px) {
  .color-hor-header .mobile-header {
    background: $primary;
  }

  .color-menu .mobile-header {
    background: $primary !important;
  }
}

@media only screen and (max-width: 991px) {
  .color-hor-menu {
    .horizontalMenu > .horizontalMenu-list {
      background: $primary;

      > li > ul.sub-menu {
        background-color: $black-3;
        border-bottom: 0;
      }
    }

    .mega-menubg {
      background: $black-3 !important;
    }

    .horizontalMenu > .horizontalMenu-list > li > {
      ul.sub-menu > li > ul.sub-menu {
        background-color: transparent;
      }

      .horizontal-megamenu .link-list li a {
        background-color: $black-3 !important;
      }
    }
  }
}

.navbar-light .navbar-nav {
  .show > .nav-link,
  .active > .nav-link {
    color: $primary;
  }

  .nav-link {
    &.show,
    &.active {
      color: $primary;
    }
  }
}

.chat .msg_cotainer_send {
  background: $primary !important;
  color: $white;

  &:before,
  &:after {
    border-left-color: $primary;
  }
}
