.text-fb-blue {
  color: #234684 !important;
}

.text-blue {
  color: #467fcf !important;
}

.text-indigo {
  color: $indigo !important;
}

.text-purple {
  color: #867efc !important;
}

.text-lightpink-red {
  color: #ff7088 !important;
}

.text-lightgreen {
  color: #26eda2 !important;
}

.text-pink {
  color: $pink !important;
}

.text-red {
  color: $danger !important;
}

.text-orange {
  color: $orange !important;
}

.text-yellow {
  color: #ecb403 !important;
}

.text-green {
  color: $success !important;
}

.text-green-1 {
  color: #0dff01 !important;
}

.text-teal {
  color: $teal !important;
}

.text-cyan {
  color: $cyan !important;
}

.text-white {
  color: $white !important;
}

.text-gray {
  color: #969696 !important;
}

.text-gray-dark {
  color: #425e8d !important;
}

.text-azure {
  color: #45aaf2 !important;
}

.text-lime {
  color: #7bd235 !important;
}

.text-light-color {
  color: #a9b1c9 !important;
}

.tx-color {
  color: #8890a1 !important;
}

/*------- Alignments & values-------*/

.text-monospace {
  font-family: Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1280px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.fw-600 {
  font-weight: 600;
}

.font-weight-semibold {
  font-weight: 500 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: $white !important;
}

.text-white-transparent {
  color: $white !important;
  opacity: 0.9;
}

.text-white-transparent-1 {
  color: $white !important;
  opacity: 0.5;
}

a.text-primary {
  &:hover,
  &:focus {
    color: $primary-09 !important;
  }
}

.text-secondary {
  color: $secondary !important;
}

a.text-secondary {
  &:hover,
  &:focus {
    color: $secondary !important;
  }
}

.text-secondary1 {
  color: #24e4ac !important;
}

a.text-secondary1 {
  &:hover,
  &:focus {
    color: #24e4ac !important;
  }
}

.text-success {
  color: $success !important;
}

a.text-success {
  &:hover,
  &:focus {
    color: #448700 !important;
  }
}

.text-info {
  color: $info !important;
}

a.text-info {
  &:hover,
  &:focus {
    color: $info !important;
  }
}

.text-warning {
  color: $warning !important;
}

a.text-warning {
  &:hover,
  &:focus {
    color: #c29d0b !important;
  }
}

.text-danger {
  color: $danger !important;
}

a.text-danger {
  &:hover,
  &:focus {
    color: #d22827 !important;
  }
}

.text-light {
  color: #ebedef !important;
}

a.text-light {
  &:hover,
  &:focus {
    color: #dae0e5 !important;
  }
}

.text-dark {
  color: $color !important;
}

a.text-dark {
  &:hover,
  &:focus {
    color: $primary !important;
  }
}

.text-body {
  color: $color !important;
}

.text-muted {
  color: #828caa !important;
}

.text-black-50 {
  color: $black-5 !important;
}

.text-white-50 {
  color: $white-5 !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-wrap {
  font-size: 14px;
  line-height: 1.66;

  > {
    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 1em;
    }
  }
}

/*----- Typography ------*/

.heading-inverse {
  background-color: #333;
  color: $white;
  padding: 5px;
}

.heading-success {
  background-color: #1643a3;
  color: $white;
  padding: 5px;
}

.heading-info {
  background-color: #45aaf2;
  color: $white;
  padding: 5px;
}

.heading-warning {
  background-color: #ecb403;
  color: $white;
  padding: 5px;
}

.heading-danger {
  background-color: $danger;
  color: $white;
  padding: 5px;
}

.text-primary-gradient {
  text-transform: uppercase;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-secondary-gradient {
  text-transform: uppercase;
  background: linear-gradient(to bottom right, $secondary 0%, #af4666 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  background: linear-gradient(to right, #cd489c 0%, #ce4ba4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-warning-gradient {
  text-transform: uppercase;
  background: linear-gradient(to right, #f66b4e 0%, #fbc434 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-info-gradient {
  text-transform: uppercase;
  background: linear-gradient(to right bottom, #1e63c3 0%, #00f2fe 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-danger-gradient {
  text-transform: uppercase;
  background: linear-gradient(to right, #ff416c 0%, #ff4b2b 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-success-gradient {
  text-transform: uppercase;
  background: linear-gradient(to right, #21a544 0%, #62fb62 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-facebook-gradient {
  text-transform: uppercase;
  background: linear-gradient(to right bottom, #1e3c72 0%, #3d6cbf 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-twitter-gradient {
  text-transform: uppercase;
  background: linear-gradient(to right bottom, #1e63c3 0%, #00f2fe 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-google-plus-gradient {
  text-transform: uppercase;
  background: linear-gradient(to right bottom, #dd4b39 0%, #ef6a5a 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-pinterset-gradient {
  text-transform: uppercase;
  background: linear-gradient(to right bottom, #bd081c 0%, #eb4553 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-facebook {
  color: #3b5998;
}

.text-google-plus {
  color: #dd4b39;
}

.text-twitter {
  color: #1da1f2;
}

.text-pinterest {
  color: #bd081c;
}

blockquote {
  color: #282f53;
  font-style: inherit;
  p {
    font-size: 16px;
  }
}

.blockquote-reverse {
  border-left: 0;
  border-right: 2px solid #e9edf4;
  text-align: right;
  padding-right: 2rem;
  padding-left: 0;
}

.lh-2 {
  line-height: 2.5 !important;
}
