@charset "UTF-8";

/*--------- Buttons--------------*/
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.84615385;
  border-radius: 5px;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover,
.btn:focus {
  text-decoration: none;
}
.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: none !important;
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
  background-image: none;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-secondary {
  color: $white;
  background: $secondary !important;
  border-color: $secondary;
  box-shadow: none;
}
.btn-secondary:hover {
  color: $white;
  background-color: #954df3;
  border-color: #954df3;
  opacity: 0.9;
}
.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: $white;
  background-color: #954df3;
  border-color: #954df3;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: $white;
  background-color: #954df3;
  border-color: #954df3;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-success {
  color: $white !important;
  background: $success !important;
  border-color: #4bdf95;
  box-shadow: none;
}
.btn-success:hover {
  color: $white;
  background-color: $success;
  border-color: $success;
  opacity: 0.9;
}
.btn-success:focus,
.btn-success.focus {
  box-shadow: none;
}
.btn-success.disabled,
.btn-success:disabled {
  color: $white;
  background-color: $success;
  border-color: $success;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: $white;
  background-color: $success;
  border-color: $success;
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-info {
  color: $white !important;
  background: $info;
  border-color: #4494e5;
  box-shadow: none;
}
.btn-info:hover {
  color: $white;
  background-color: $info;
  border-color: $info;
  opacity: 0.9;
}
.btn-info:focus,
.btn-info.focus {
  box-shadow: none;
}
.btn-info.disabled,
.btn-info:disabled {
  color: $white;
  background-color: $info !important;
  border-color: $info;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: $white;
  background-color: $info;
  border-color: $info;
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-warning {
  color: $white;
  background: $warning !important;
  border-color: #f1b852;
  box-shadow: none;
}
.btn-warning:hover {
  color: $white;
  background-color: #e9a578;
  border-color: #e9a578;
  opacity: 0.9;
}
.btn-warning:focus,
.btn-warning.focus {
  box-shadow: none;
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: $white;
  background-color: #da8c58;
  border-color: #da8c58;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: $white;
  background-color: #da8c58;
  border-color: #da8c58;
}
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-danger {
  color: $white;
  background: $danger !important;
  border-color: #ed7474;
  box-shadow: none;
}
.btn-danger:hover {
  color: $white;
  background-color: #e44862;
  border-color: #e44862;
  opacity: 0.9;
}
.btn-danger:focus,
.btn-danger.focus {
  box-shadow: none;
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: $white;
  background-color: #e44862;
  border-color: #e44862;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: $white;
  background-color: #e44862;
  border-color: #e44862;
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-default {
  color: $color;
  background: #ebedfc;
  border-color: #b9b5b5;
}
.btn-default:hover {
  color: $color;
  background-color: #dfe2fd;
  border-color: #e5e1e7;
}
.btn-default:focus,
.btn-default.focus {
  box-shadow: none;
}
.btn-default.disabled,
.btn-default:disabled {
  color: $color;
  background-color: #e5e4e6;
  border-color: #e5e1e7;
}
.btn-default:not(:disabled):not(.disabled):active,
.btn-default:not(:disabled):not(.disabled).active,
.show > .btn-default.dropdown-toggle {
  color: $color;
  background-color: #eceeef;
  border-color: #c8cacc;
}
.btn-default:not(:disabled):not(.disabled):active:focus,
.btn-default:not(:disabled):not(.disabled).active:focus,
.show > .btn-default.dropdown-toggle:focus {
  box-shadow: none;
}
/*-- light-buttons--*/
.btn-secondary-light {
  color: $secondary !important;
  background: #9c52fd33 !important;
  border-color: #9c52fd33;
  box-shadow: none;
}
.btn-secondary-light:hover {
  color: $white !important;
  background-color: $secondary !important;
  border-color: $secondary;
}
.btn-secondary-light:focus,
.btn-secondary-light.focus {
  box-shadow: none;
}
.btn-success-light {
  color: $success !important;
  background: #56d09433 !important;
  border-color: #56d09433;
  box-shadow: none;
}
.btn-success-light:hover {
  color: $white !important;
  background-color: $success !important;
  border-color: $success;
}
.btn-success-light:focus,
.btn-success-light.focus {
  box-shadow: none;
}
.btn-info-light {
  color: $info !important;
  background: #2f89f533;
  border-color: #2f89f533;
  box-shadow: none;
}
.btn-info-light:hover {
  color: $white !important;
  background-color: $info;
  border-color: $info;
}
.btn-info-light:focus,
.btn-info-light.focus {
  box-shadow: none;
}
.btn-warning-light {
  color: #ffa70b !important;
  background: #ffa70b33 !important;
  border-color: #ffa70b33;
  box-shadow: none;
}
.btn-warning-light:hover {
  color: $white !important;
  background-color: $warning !important;
  border-color: $warning;
}
.btn-warning-light:focus,
.btn-warning-light.focus {
  box-shadow: none;
}
.btn-danger-light {
  color: $danger !important;
  background: #fb545433 !important;
  border-color: #fb545433;
  box-shadow: none;
}
.btn-danger-light:hover {
  color: $white !important;
  background-color: $danger !important;
  border-color: $danger;
}
.btn-danger-light:focus,
.btn-danger-light.focus {
  box-shadow: none;
}
.btn-default-light {
  color: $color;
  background: #ebedfc;
  border-color: #ebedfc;
  box-shadow: none;
}
.btn-default-light:hover {
  color: $color;
  background-color: #e7e9f9 !important;
  border-color: #e7e9f9;
  opacity: 0.9;
}
.btn-default-light:focus,
.btn-default-light.focus {
  box-shadow: none;
}

.btn-default {
  color: $color;
  background: #ebedfc;
  border-color: #ebedfc;
  box-shadow: none;
}
.btn-default:focus,
.btn-default.focus {
  box-shadow: none;
}
.btn-default.disabled,
.btn-default:disabled {
  color: $color;
  background-color: #e7e9f9;
  border-color: #e7e9f9;
}
.btn-default:not(:disabled):not(.disabled):active,
.btn-default:not(:disabled):not(.disabled).active,
.show > .btn-default.dropdown-toggle {
  color: $color;
  background-color: #e9e5f1;
  border-color: #e7e9f9;
}
.btn-default:not(:disabled):not(.disabled):active:focus,
.btn-default:not(:disabled):not(.disabled).active:focus,
.show > .btn-default.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-light {
  color: $color;
  background-color: #ebedfc;
  border-color: #e7e9f9;
  box-shadow: none;
}
.btn-light:hover {
  color: $color;
  background-color: #eeebf3;
  border-color: #eeebf3;
}
.btn-light:focus,
.btn-light.focus {
  box-shadow: none;
}
.btn-light.disabled,
.btn-light:disabled {
  color: $color;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: $color;
  background-color: #e3e8f5;
  border-color: #e7e9f9;
}
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-dark {
  color: $white;
  background-color: $dark;
  border-color: $dark;
  box-shadow: none;
}
.btn-dark:hover {
  color: $white;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus,
.btn-dark.focus {
  box-shadow: none;
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: $white;
  background-color: $dark;
  border-color: $dark;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: $white;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-outline-default {
  color: $color;
  background: transparent;
  background-image: none;
  border-color: #e7e9f9;
  box-shadow: none;
}
.btn-outline-default:hover {
  color: $color;
  background: #ebedfc;
  border-color: #e7e9f9;
  box-shadow: none !important;
}
.btn-outline-default:focus,
.btn-outline-default.focus {
  box-shadow: none;
}
.btn-outline-default.disabled,
.btn-outline-default:disabled {
  color: $color;
  background-color: transparent;
}
.btn-outline-default:not(:disabled):not(.disabled):active,
.btn-outline-default:not(:disabled):not(.disabled).active,
.show > .btn-outline-default.dropdown-toggle {
  color: $color;
  background-color: #ebedfc;
  border-color: #e7e9f9;
}
.btn-outline-secondary {
  color: $secondary !important;
  background-color: transparent;
  background-image: none;
  border-color: #954df3;
  box-shadow: none;
}
.btn-outline-secondary:hover {
  color: $white !important;
  background-color: $secondary;
  border-color: #954df3;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: none;
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #954df3;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: $white;
  background-color: $secondary;
  border-color: #954df3;
}
.btn-outline-success {
  color: $success;
  background-color: transparent;
  background-image: none;
  border-color: $success;
  box-shadow: none;
}
.btn-outline-success:hover {
  color: $white !important;
  background-color: $success;
  border-color: $success;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: none;
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: $success;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: $white;
  background-color: $success;
  border-color: $success;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-outline-info {
  color: #45aaf2;
  background-color: transparent;
  background-image: none;
  border-color: #45aaf2;
  box-shadow: none;
}
.btn-outline-info:hover {
  color: $white;
  background-color: #45aaf2;
  border-color: #45aaf2;
}
.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: none;
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #45aaf2;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: $white;
  background-color: #45aaf2;
  border-color: #45aaf2;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-outline-warning {
  color: #ecb403 !important;
  background-color: transparent;
  background-image: none;
  border-color: #ecb403;
  box-shadow: none;
}
.btn-outline-warning:hover {
  color: $white !important;
  background-color: #ecb403;
  border-color: #ecb403;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: none;
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ecb403;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: $white;
  background-color: #ecb403;
  border-color: #ecb403;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-outline-danger {
  color: $danger;
  background-color: transparent;
  background-image: none;
  border-color: $danger;
  box-shadow: none;
}
.btn-outline-danger:hover {
  color: $white;
  background-color: $danger;
  border-color: $danger;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: none;
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: $danger;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: $white;
  background-color: $danger;
  border-color: $danger;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-link {
  font-weight: 400;
  background-color: transparent;
  box-shadow: none;
}
.btn-link:hover {
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}
.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}
.btn-link:disabled,
.btn-link.disabled {
  color: $gray;
  pointer-events: none;
}
.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.625;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 0.35rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 3px;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}
.btn-facebook {
  background: linear-gradient(to right bottom, #3b5998 0%, #3b5998 100%);
}
input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block {
  width: 100%;
}
/**Btn-group**/
.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}
.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn:first-child {
  margin-left: 0;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/*----- Btn styles------*/
.btn {
  cursor: pointer;
  font-weight: 400;
  letter-spacing: 0.03em;
  font-size: 0.8125rem;
  min-width: 2.375rem;
}
.btn-icon {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  text-align: center;
}
/*.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px #1643A3;
}*/
.btn-pill {
  border-radius: 10rem;
  padding-left: 1.5em;
  padding-right: 1.5em;
}
.btn-radius {
  border-radius: 3px;
}
.btn-facebook {
  color: $white;
  background-color: #3b5998;
  border-color: #3b5998;
}
.btn-facebook:hover {
  color: $white;
  background-color: #30497c;
  border-color: #2d4373;
}
.btn-facebook:focus,
.btn-facebook.focus {
  box-shadow: none;
}
.btn-facebook.disabled,
.btn-facebook:disabled {
  color: $white;
  background-color: #3b5998;
  border-color: #3b5998;
}
.btn-facebook:not(:disabled):not(.disabled):active,
.btn-facebook:not(:disabled):not(.disabled).active,
.show > .btn-facebook.dropdown-toggle {
  color: $white;
  background-color: #2d4373;
  border-color: #293e6a;
}
.btn-facebook:not(:disabled):not(.disabled):active:focus,
.btn-facebook:not(:disabled):not(.disabled).active:focus,
.show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-twitter {
  color: $white;
  background-color: #1da1f2;
  border-color: #1da1f2;
}
.btn-twitter:hover {
  color: $white;
  background-color: #0d8ddc;
  border-color: #0c85d0;
}
.btn-twitter:focus,
.btn-twitter.focus {
  box-shadow: none;
}
.btn-twitter.disabled,
.btn-twitter:disabled {
  color: $white;
  background-color: #1da1f2;
  border-color: #1da1f2;
}
.btn-twitter:not(:disabled):not(.disabled):active,
.btn-twitter:not(:disabled):not(.disabled).active,
.show > .btn-twitter.dropdown-toggle {
  color: $white;
  background-color: #0c85d0;
  border-color: #0b7ec4;
}
.btn-twitter:not(:disabled):not(.disabled):active:focus,
.btn-twitter:not(:disabled):not(.disabled).active:focus,
.show > .btn-twitter.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-google {
  color: $white;
  background-color: #dc4e41;
  border-color: #dc4e41;
}
.btn-google:hover {
  color: $white;
  background-color: #d03526;
  border-color: #c63224;
}
.btn-google:focus,
.btn-google.focus {
  box-shadow: none;
}
.btn-google.disabled,
.btn-google:disabled {
  color: $white;
  background-color: #dc4e41;
  border-color: #dc4e41;
}
.btn-google:not(:disabled):not(.disabled):active,
.btn-google:not(:disabled):not(.disabled).active,
.show > .btn-google.dropdown-toggle {
  color: $white;
  background-color: #c63224;
  border-color: #bb2f22;
}
.btn-google:not(:disabled):not(.disabled):active:focus,
.btn-google:not(:disabled):not(.disabled).active:focus,
.show > .btn-google.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-youtube {
  color: $white;
  background-color: #f00;
  border-color: #f00;
}
.btn-youtube:hover {
  color: $white;
  background-color: #d90000;
  border-color: #cc0000;
}
.btn-youtube:focus,
.btn-youtube.focus {
  box-shadow: none;
}
.btn-youtube.disabled,
.btn-youtube:disabled {
  color: $white;
  background-color: #f00;
  border-color: #f00;
}
.btn-youtube:not(:disabled):not(.disabled):active,
.btn-youtube:not(:disabled):not(.disabled).active,
.show > .btn-youtube.dropdown-toggle {
  color: $white;
  background-color: #cc0000;
  border-color: #bf0000;
}
.btn-youtube:not(:disabled):not(.disabled):active:focus,
.btn-youtube:not(:disabled):not(.disabled).active:focus,
.show > .btn-youtube.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-vimeo {
  color: $white;
  background-color: #1ab7ea;
  border-color: #1ab7ea;
}
.btn-vimeo:hover {
  color: $white;
  background-color: #139ecb;
  border-color: #1295bf;
}
.btn-vimeo:focus,
.btn-vimeo.focus {
  box-shadow: none;
}
.btn-vimeo.disabled,
.btn-vimeo:disabled {
  color: $white;
  background-color: #1ab7ea;
  border-color: #1ab7ea;
}
.btn-vimeo:not(:disabled):not(.disabled):active,
.btn-vimeo:not(:disabled):not(.disabled).active,
.show > .btn-vimeo.dropdown-toggle {
  color: $white;
  background-color: #1295bf;
  border-color: #108cb4;
}
.btn-vimeo:not(:disabled):not(.disabled):active:focus,
.btn-vimeo:not(:disabled):not(.disabled).active:focus,
.show > .btn-vimeo.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-dribbble {
  color: $white;
  background-color: #ea4c89;
  border-color: #ea4c89;
}
.btn-dribbble:hover {
  color: $white;
  background-color: #e62a72;
  border-color: #e51e6b;
}
.btn-dribbble:focus,
.btn-dribbble.focus {
  box-shadow: none;
}
.btn-dribbble.disabled,
.btn-dribbble:disabled {
  color: $white;
  background-color: #ea4c89;
  border-color: #ea4c89;
}
.btn-dribbble:not(:disabled):not(.disabled):active,
.btn-dribbble:not(:disabled):not(.disabled).active,
.show > .btn-dribbble.dropdown-toggle {
  color: $white;
  background-color: #e51e6b;
  border-color: #dc1a65;
}
.btn-dribbble:not(:disabled):not(.disabled):active:focus,
.btn-dribbble:not(:disabled):not(.disabled).active:focus,
.show > .btn-dribbble.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-github {
  color: $white;
  background-color: #181717;
  border-color: #181717;
}
.btn-github:hover {
  color: $white;
  background-color: #040404;
  border-color: black;
}
.btn-github:focus,
.btn-github.focus {
  box-shadow: none;
}
.btn-github.disabled,
.btn-github:disabled {
  color: $white;
  background-color: #181717;
  border-color: #181717;
}
.btn-github:not(:disabled):not(.disabled):active,
.btn-github:not(:disabled):not(.disabled).active,
.show > .btn-github.dropdown-toggle {
  color: $white;
  background-color: black;
  border-color: black;
}
.btn-github:not(:disabled):not(.disabled):active:focus,
.btn-github:not(:disabled):not(.disabled).active:focus,
.show > .btn-github.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-instagram {
  color: $white;
  background-color: #e4405f;
  border-color: #e4405f;
}
.btn-instagram:hover {
  color: $white;
  background-color: #de1f44;
  border-color: #d31e40;
}
.btn-instagram:focus,
.btn-instagram.focus {
  box-shadow: none;
}
.btn-instagram.disabled,
.btn-instagram:disabled {
  color: $white;
  background-color: #e4405f;
  border-color: #e4405f;
}
.btn-instagram:not(:disabled):not(.disabled):active,
.btn-instagram:not(:disabled):not(.disabled).active,
.show > .btn-instagram.dropdown-toggle {
  color: $white;
  background-color: #d31e40;
  border-color: #c81c3d;
}
.btn-instagram:not(:disabled):not(.disabled):active:focus,
.btn-instagram:not(:disabled):not(.disabled).active:focus,
.show > .btn-instagram.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-pinterest {
  color: $white;
  background-color: #bd081c;
  border-color: #bd081c;
}
.btn-pinterest:hover {
  color: $white;
  background-color: #980617;
  border-color: #8c0615;
}
.btn-pinterest:focus,
.btn-pinterest.focus {
  box-shadow: none;
}
.btn-pinterest.disabled,
.btn-pinterest:disabled {
  color: $white;
  background-color: #bd081c;
  border-color: #bd081c;
}
.btn-pinterest:not(:disabled):not(.disabled):active,
.btn-pinterest:not(:disabled):not(.disabled).active,
.show > .btn-pinterest.dropdown-toggle {
  color: $white;
  background-color: #8c0615;
  border-color: #800513;
}
.btn-pinterest:not(:disabled):not(.disabled):active:focus,
.btn-pinterest:not(:disabled):not(.disabled).active:focus,
.show > .btn-pinterest.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-vk {
  color: $white;
  background-color: #6383a8;
  border-color: #6383a8;
}
.btn-vk:hover {
  color: $white;
  background-color: #527093;
  border-color: #4d6a8b;
}
.btn-vk:focus,
.btn-vk.focus {
  box-shadow: none;
}
.btn-vk.disabled,
.btn-vk:disabled {
  color: $white;
  background-color: #6383a8;
  border-color: #6383a8;
}
.btn-vk:not(:disabled):not(.disabled):active,
.btn-vk:not(:disabled):not(.disabled).active,
.show > .btn-vk.dropdown-toggle {
  color: $white;
  background-color: #4d6a8b;
  border-color: #496482;
}
.btn-vk:not(:disabled):not(.disabled):active:focus,
.btn-vk:not(:disabled):not(.disabled).active:focus,
.show > .btn-vk.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-rss {
  color: $white;
  background-color: #ffa500;
  border-color: #ffa500;
}
.btn-rss:hover {
  color: $white;
  background-color: #d98c00;
  border-color: #cc8400;
}
.btn-rss:focus,
.btn-rss.focus {
  box-shadow: none;
}
.btn-rss.disabled,
.btn-rss:disabled {
  color: $white;
  background-color: #ffa500;
  border-color: #ffa500;
}
.btn-rss:not(:disabled):not(.disabled):active,
.btn-rss:not(:disabled):not(.disabled).active,
.show > .btn-rss.dropdown-toggle {
  color: $white;
  background-color: #cc8400;
  border-color: #bf7c00;
}
.btn-rss:not(:disabled):not(.disabled):active:focus,
.btn-rss:not(:disabled):not(.disabled).active:focus,
.show > .btn-rss.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-flickr {
  color: $white;
  background-color: #0063dc;
  border-color: #0063dc;
}
.btn-flickr:hover {
  color: $white;
  background-color: #0052b6;
  border-color: #004ca9;
}
.btn-flickr:focus,
.btn-flickr.focus {
  box-shadow: none;
}
.btn-flickr.disabled,
.btn-flickr:disabled {
  color: $white;
  background-color: #0063dc;
  border-color: #0063dc;
}
.btn-flickr:not(:disabled):not(.disabled):active,
.btn-flickr:not(:disabled):not(.disabled).active,
.show > .btn-flickr.dropdown-toggle {
  color: $white;
  background-color: #004ca9;
  border-color: #00469c;
}
.btn-flickr:not(:disabled):not(.disabled):active:focus,
.btn-flickr:not(:disabled):not(.disabled).active:focus,
.show > .btn-flickr.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-blue {
  color: $white;
  background-color: #467fcf;
  border-color: #467fcf;
  box-shadow: none;
}
.btn-blue:hover {
  color: $white;
  background-color: #316cbe;
  border-color: #1f5bb1;
}
.btn-blue:focus,
.btn-blue.focus {
  box-shadow: none;
}
.btn-blue.disabled,
.btn-blue:disabled {
  color: $white;
  background-color: #467fcf;
  border-color: #467fcf;
}
.btn-blue:not(:disabled):not(.disabled):active,
.btn-blue:not(:disabled):not(.disabled).active,
.show > .btn-blue.dropdown-toggle {
  color: $white;
  background-color: #1f5bb1;
  border-color: #2c60a9;
}
.btn-blue:not(:disabled):not(.disabled):active:focus,
.btn-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-indigo {
  color: $white;
  background-color: $indigo;
  border-color: $indigo;
  box-shadow: none;
}
.btn-indigo:hover {
  color: $white;
  background-color: #485ac4;
  border-color: #3f51c1;
}
.btn-indigo:focus,
.btn-indigo.focus {
  box-shadow: none;
}
.btn-indigo.disabled,
.btn-indigo:disabled {
  color: $white;
  background-color: $indigo;
  border-color: $indigo;
}
.btn-indigo:not(:disabled):not(.disabled):active,
.btn-indigo:not(:disabled):not(.disabled).active,
.show > .btn-indigo.dropdown-toggle {
  color: $white;
  background-color: #3f51c1;
  border-color: #3b4db7;
}
.btn-indigo:not(:disabled):not(.disabled):active:focus,
.btn-indigo:not(:disabled):not(.disabled).active:focus,
.show > .btn-indigo.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-purple {
  color: $white;
  background: $purple !important;
  border-color: $purple;
  box-shadow: none;
}
.btn-purple:hover {
  color: $white;
  background-color: #923ce6;
  border-color: #8c31e4;
}
.btn-purple:focus,
.btn-purple.focus {
  box-shadow: none;
}
.btn-purple.disabled,
.btn-purple:disabled {
  color: $white;
  background-color: #867efc;
  border-color: #867efc;
}
.btn-purple:not(:disabled):not(.disabled):active,
.btn-purple:not(:disabled):not(.disabled).active,
.show > .btn-purple.dropdown-toggle {
  color: $white;
  background-color: #8c31e4;
  border-color: #8526e3;
}
.btn-purple:not(:disabled):not(.disabled):active:focus,
.btn-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-purple.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-pink {
  color: $white;
  background-color: $pink;
  border-color: $pink;
  box-shadow: none;
}
.btn-pink:hover {
  color: $white;
  background-color: #f44982;
  border-color: #f33d7a;
}
.btn-pink:focus,
.btn-pink.focus {
  box-shadow: none;
}
.btn-pink.disabled,
.btn-pink:disabled {
  color: $white;
  background-color: $pink;
  border-color: $pink;
}
.btn-pink:not(:disabled):not(.disabled):active,
.btn-pink:not(:disabled):not(.disabled).active,
.show > .btn-pink.dropdown-toggle {
  color: $white;
  background-color: #f33d7a;
  border-color: #f23172;
}
.btn-pink:not(:disabled):not(.disabled):active:focus,
.btn-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-pink.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-red {
  color: $white;
  background-color: $danger;
  border-color: $danger;
  box-shadow: none;
}
.btn-red:hover {
  color: $white;
  background-color: #ac1b1a;
  border-color: #a11918;
}
.btn-red:focus,
.btn-red.focus {
  box-shadow: none;
}
.btn-red.disabled,
.btn-red:disabled {
  color: $white;
  background-color: $danger;
  border-color: $danger;
}
.btn-red:not(:disabled):not(.disabled):active,
.btn-red:not(:disabled):not(.disabled).active,
.show > .btn-red.dropdown-toggle {
  color: $white;
  background-color: #a11918;
  border-color: #961717;
}
.btn-red:not(:disabled):not(.disabled):active:focus,
.btn-red:not(:disabled):not(.disabled).active:focus,
.show > .btn-red.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-orange {
  color: $white;
  background-color: $orange;
  border-color: $orange;
  box-shadow: none;
}
.btn-orange:hover {
  color: $white;
  background-color: #fd811e;
  border-color: #fc7a12;
}
.btn-orange:focus,
.btn-orange.focus {
  box-shadow: none;
}
.btn-orange.disabled,
.btn-orange:disabled {
  color: $white;
  background-color: $orange;
  border-color: $orange;
}
.btn-orange:not(:disabled):not(.disabled):active,
.btn-orange:not(:disabled):not(.disabled).active,
.show > .btn-orange.dropdown-toggle {
  color: $white;
  background-color: #fc7a12;
  border-color: #fc7305;
}
.btn-orange:not(:disabled):not(.disabled):active:focus,
.btn-orange:not(:disabled):not(.disabled).active:focus,
.show > .btn-orange.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-yellow {
  color: $white;
  background-color: #ecb403;
  border-color: #ecb403;
  box-shadow: none;
}
.btn-yellow:hover {
  color: $white;
  background-color: #cea70c;
  border-color: #c29d0b;
}
.btn-yellow:focus,
.btn-yellow.focus {
  box-shadow: none;
}
.btn-yellow.disabled,
.btn-yellow:disabled {
  color: $white;
  background-color: #ecb403;
  border-color: #ecb403;
}
.btn-yellow:not(:disabled):not(.disabled):active,
.btn-yellow:not(:disabled):not(.disabled).active,
.show > .btn-yellow.dropdown-toggle {
  color: $white;
  background-color: #c29d0b;
  border-color: #b6940b;
}
.btn-yellow:not(:disabled):not(.disabled):active:focus,
.btn-yellow:not(:disabled):not(.disabled).active:focus,
.show > .btn-yellow.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-green {
  color: $white;
  background-color: #4ecc48;
  border-color: #4ecc48;
  box-shadow: none;
}
.btn-green:hover {
  color: $white;
  background-color: #4b9400;
  border-color: #448700;
}
.btn-green:focus,
.btn-green.focus {
  box-shadow: none;
}
.btn-green.disabled,
.btn-green:disabled {
  color: $white;
  background-color: #4ecc48;
  border-color: #4ecc48;
}
.btn-green:not(:disabled):not(.disabled):active,
.btn-green:not(:disabled):not(.disabled).active,
.show > .btn-green.dropdown-toggle {
  color: $white;
  background-color: #448700;
  border-color: #3e7a00;
}
.btn-green:not(:disabled):not(.disabled):active:focus,
.btn-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-green.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-teal {
  color: $white;
  background-color: $teal;
  border-color: $teal;
  box-shadow: none;
}
.btn-teal:hover {
  color: $white;
  background-color: #24ab9d;
  border-color: #22a193;
}
.btn-teal:focus,
.btn-teal.focus {
  box-shadow: none;
}
.btn-teal.disabled,
.btn-teal:disabled {
  color: $white;
  background-color: $teal;
  border-color: $teal;
}
.btn-teal:not(:disabled):not(.disabled):active,
.btn-teal:not(:disabled):not(.disabled).active,
.show > .btn-teal.dropdown-toggle {
  color: $white;
  background-color: #22a193;
  border-color: #20968a;
}
.btn-teal:not(:disabled):not(.disabled):active:focus,
.btn-teal:not(:disabled):not(.disabled).active:focus,
.show > .btn-teal.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-cyan {
  color: $white;
  background-color: $cyan;
  border-color: $cyan;
  box-shadow: none;
}
.btn-cyan:hover {
  color: $white;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-cyan:focus,
.btn-cyan.focus {
  box-shadow: none;
}
.btn-cyan.disabled,
.btn-cyan:disabled {
  color: $white;
  background-color: $cyan;
  border-color: $cyan;
}
.btn-cyan:not(:disabled):not(.disabled):active,
.btn-cyan:not(:disabled):not(.disabled).active,
.show > .btn-cyan.dropdown-toggle {
  color: $white;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-cyan:not(:disabled):not(.disabled):active:focus,
.btn-cyan:not(:disabled):not(.disabled).active:focus,
.show > .btn-cyan.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-white {
  color: $color;
  background-color: $white;
  border-color: $white;
  box-shadow: none;
}
.btn-white:hover {
  color: $primary;
  background-color: $white-9;
  border-color: $white;
}
.btn-white:focus,
.btn-white.focus {
  box-shadow: none;
}
.btn-white.disabled,
.btn-white:disabled {
  color: $color;
  background-color: $white;
  border-color: $white;
}
.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active,
.show > .btn-white.dropdown-toggle {
  color: $color;
  background-color: $white;
  border-color: $white;
}
.btn-white:not(:disabled):not(.disabled):active:focus,
.btn-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-white.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-gray {
  color: $white;
  background-color: $gray;
  border-color: $gray;
  box-shadow: none;
}
.btn-gray:hover {
  color: $white;
  background-color: #727b84;
  border-color: #6c757d;
}
.btn-gray:focus,
.btn-gray.focus {
  box-shadow: none;
}
.btn-gray.disabled,
.btn-gray:disabled {
  color: $white;
  background-color: $gray;
  border-color: $gray;
}
.btn-gray:not(:disabled):not(.disabled):active,
.btn-gray:not(:disabled):not(.disabled).active,
.show > .btn-gray.dropdown-toggle {
  color: $white;
  background-color: #6c757d;
  border-color: #666e76;
}
.btn-gray:not(:disabled):not(.disabled):active:focus,
.btn-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-gray-dark {
  color: $white;
  background-color: $dark;
  border-color: $dark;
  box-shadow: none;
}
.btn-gray-dark:hover {
  color: $white;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-gray-dark:focus,
.btn-gray-dark.focus {
  box-shadow: none;
}
.btn-gray-dark.disabled,
.btn-gray-dark:disabled {
  color: $white;
  background-color: $dark;
  border-color: $dark;
}
.btn-gray-dark:not(:disabled):not(.disabled):active,
.btn-gray-dark:not(:disabled):not(.disabled).active,
.show > .btn-gray-dark.dropdown-toggle {
  color: $white;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-gray-dark:not(:disabled):not(.disabled):active:focus,
.btn-gray-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-dark.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-azure {
  color: $white;
  background-color: #45aaf2;
  border-color: #45aaf2;
  box-shadow: none;
}
.btn-azure:hover {
  color: $white;
  background-color: #219af0;
  border-color: #1594ef;
}
.btn-azure:focus,
.btn-azure.focus {
  box-shadow: none;
}
.btn-azure.disabled,
.btn-azure:disabled {
  color: $white;
  background-color: #45aaf2;
  border-color: #45aaf2;
}
.btn-azure:not(:disabled):not(.disabled):active,
.btn-azure:not(:disabled):not(.disabled).active,
.show > .btn-azure.dropdown-toggle {
  color: $white;
  background-color: #1594ef;
  border-color: #108ee7;
}
.btn-azure:not(:disabled):not(.disabled):active:focus,
.btn-azure:not(:disabled):not(.disabled).active:focus,
.show > .btn-azure.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-lime {
  color: $white;
  background-color: #7bd235;
  border-color: #7bd235;
  box-shadow: none;
}
.btn-lime:hover {
  color: $white;
  background-color: #69b829;
  border-color: #63ad27;
}
.btn-lime:focus,
.btn-lime.focus {
  box-shadow: none;
}
.btn-lime.disabled,
.btn-lime:disabled {
  color: $white;
  background-color: #7bd235;
  border-color: #7bd235;
}
.btn-lime:not(:disabled):not(.disabled):active,
.btn-lime:not(:disabled):not(.disabled).active,
.show > .btn-lime.dropdown-toggle {
  color: $white;
  background-color: #63ad27;
  border-color: #5da324;
}
.btn-lime:not(:disabled):not(.disabled):active:focus,
.btn-lime:not(:disabled):not(.disabled).active:focus,
.show > .btn-lime.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-sm,
.btn-group-sm > .btn {
  min-width: 1.625rem;
}
.btn-lg,
.btn-group-lg > .btn {
  font-size: 1rem;
  min-width: 2.75rem;
  font-weight: 400;
}
.btn-list {
  margin-bottom: -0.5rem;
  font-size: 0;
}
.btn-list > .btn,
.btn-list > .dropdown {
  margin-bottom: 0.5rem;
}
.btn-list > .btn:not(:last-child),
.btn-list > .dropdown:not(:last-child) {
  margin-right: 0.5rem;
}
.btn-loading {
  color: transparent !important;
  pointer-events: none;
  position: relative;
}
.btn-loading:after {
  content: '';
  -webkit-animation: loader 500ms infinite linear;
  animation: loader 500ms infinite linear;
  border: 2px solid $white;
  border-radius: 50%;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  display: block;
  height: 1.4em;
  width: 1.4em;
  position: absolute;
  left: calc(50% - (1.4em / 2));
  top: calc(50% - (1.4em / 2));
  -webkit-transform-origin: center;
  transform-origin: center;
  position: absolute !important;
}
.btn-loading.btn-sm:after,
.btn-group-sm > .btn-loading.btn:after {
  height: 1em;
  width: 1em;
  left: calc(50% - (1em / 2));
  top: calc(50% - (1em / 2));
}
.btn-loading.btn-secondary:after {
  border-color: $color;
}
.btn-social.btn-lg {
  padding-left: 61px;
}
.btn-social.btn-lg > :first-child {
  line-height: 45px;
  width: 45px;
  font-size: 1.8em;
}
.btn-social.btn-sm {
  padding-left: 38px;
}
.btn-social.btn-sm > :first-child {
  line-height: 28px;
  width: 28px;
  font-size: 1.4em;
}
.btn-social.btn-xs {
  padding-left: 30px;
}
.btn-social.btn-xs > :first-child {
  line-height: 20px;
  width: 20px;
  font-size: 1.2em;
}
.btn-success:focus,
.btn-success.focus {
  color: $white;
  background-color: $success;
  border-color: $success !important;
}
.btn-info:focus,
.btn-info.focus {
  color: $white;
  background-color: $info;
  border-color: $info !important;
}
.btn-warning:focus,
.btn-warning.focus {
  color: $white;
}
.btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: none !important;
  color: $white;
  background-color: $primary;
  border-color: $primary;
}
.btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: none !important;
  color: $white;
  background-color: $success;
  border-color: $success;
}
.btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: none !important;
  color: $white;
  background-color: $info;
  border-color: $info;
}
.btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: none !important;
  color: $white;
  background-color: $warning;
  border-color: $warning;
}
.btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: none !important;
  color: $white;
  background-color: $danger;
  border-color: $danger;
}
.btn-list .dropdown-menu {
  right: 0px;
}
.btn-close:focus {
  box-shadow: none;
}
.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}
.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: none !important;
}
.btn-check:focus + .btn-warning,
.btn-warning:focus {
  border-color: #d5aa29;
}
.btn-check:focus + .btn-danger,
.btn-danger:focus {
  border-color: #f14757;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  border-color: #9c5eed;
}

.btn-check:focus + .btn-light,
.btn-light:focus {
  background-color: #e3e8f5;
}

.btn-animation .btn-loaders {
  color: #ffffff !important;
  pointer-events: none;
  position: relative;
  padding-right: 46px;
}

.btn-animation .btn-loaders:after {
  content: '';
  animation: loader 500ms infinite linear;
  border: 2px solid #ffffff;
  border-radius: 50%;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  display: block;
  height: 1.4em;
  width: 1.4em;
  position: absolute;
  right: 13px !important;
  top: calc(50% - 0.7em);
  transform-origin: center;
  position: absolute !important;
}

.btn-check:active + .btn-outline-info,
.btn-check:checked + .btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
  color: $white;
  background-color: $info;
  border-color: $info;
}

.btn-outline-info:hover {
  color: $white;
  background-color: $info;
  border-color: $info;
}

.btn-outline-info {
  color: $info;
  background-color: transparent;
  background-image: none;
  border-color: $info;
  box-shadow: none;
}

.btn-outline-warning {
  color: $warning;
  background-color: transparent;
  background-image: none;
  border-color: $warning;
  box-shadow: none;
}

.btn-check:active + .btn-outline-warning,
.btn-check:checked + .btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
  color: $white;
  background-color: $warning;
  border-color: $warning;
}

.btn-outline-warning:hover {
  color: $white;
  background-color: $warning;
  border-color: $warning;
}

.btn-check:active + .btn-outline-danger,
.btn-check:checked + .btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
  color: $white;
  background-color: $danger;
  border-color: $danger;
}

.btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: none !important;
  color: $white;
  background-color: $danger;
  border-color: $danger;
}

.btn-close.btn-sm {
  font-size: 0.875rem;
}
