/*------ Breadcrumb ------*/

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  list-style: none;
  border-radius: 3px;
  position: relative;
  right: 0;
  padding: 0;
  color: $white;
  background: none;
}

.breadcrumb-item {
  a {
    color: #6c757d;
  }

  &.active {
    color: $primary;
  }

  + .breadcrumb-item {
    &::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #d9d9d9;
      content: '/';
    }

    &:hover::before {
      text-decoration: underline;
      text-decoration: none;
    }
  }
}

.breadcrumb1 {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #f4f6fd;
  border-radius: 0.25rem;
}

.breadcrumb-item1 {
  a {
    color: #6c757d;
  }
  + .breadcrumb-item1::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    content: '/';
    color: #dbe4f5;
  }
}

.breadcrumb1.bg-secondary .breadcrumb-item + .breadcrumb-item::before {
  color: $white-2;
}

.breadcrumb1.bg-success .breadcrumb-item + .breadcrumb-item::before {
  color: $white-2;
}

.breadcrumb-item1 {
  + .breadcrumb-item1:hover::before {
    text-decoration: underline;
    text-decoration: none;
  }

  &.active {
    color: $primary;
    a {
      color: $primary;
    }
  }
}

.breadcrumb-3 {
  ol {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
  }

  li {
    display: contents;
    text-align: center;
  }
}

.breadcrumb-4 li,
.breadcrumb-5 li {
  display: contents;
  text-align: center;
}

.breadcrumb-4 ol,
.breadcrumb-5 ol {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
}

.breadcrumb-1 li,
.breadcrumb-2 li,
.breadcrumb-6 li {
  text-align: right;
  display: contents;
}

.breadcrumb-1 ol,
.breadcrumb-2 ol,
.breadcrumb-6 ol {
  position: relative;
  display: block;
  width: 100%;
  text-align: right;
}
